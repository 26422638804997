import { storeToRefs } from 'pinia'
import { extend, configure } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import moment from 'moment'
import { i18n } from '@/plugins/i18n'
import numbers from '@/services/helpers/numbers'
import { useAppStore } from '@/stores/app'
import { api } from '@/services/api'
import compareRule from '@/validators/compare-rule'

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

// rule btw two numeric values
extend('rule_between_two_values', {
  message: () => i18n.t(`validation_between_two_values`),

  params: ['min', 'max'],
  validate: (value, { min, max }) => {
    // we should do type casting, the values come as string
    const typeCastMin = Number(min)
    const typeCastMax = Number(max)

    const number = formatNumber(value)

    const typeCastNumber = Number(number)
    return typeCastNumber > typeCastMin && typeCastNumber < typeCastMax
  }
})

extend('equalorbiggerthan', {
  message: (values, field) =>
    `${i18n.t('equalorbiggerthan')} ${field._target_}`,

  params: ['target'],

  validate: (value, { target }) => {
    const valueFormatted = formatNumber(value)
    const targetFormatted = formatNumber(target)

    const typeCastNumber = Number(valueFormatted)
    const typeCastTarget = Number(targetFormatted)

    return typeCastNumber >= typeCastTarget
  }
})

extend('equalorlessthan', {
  message: (values, field) => `${i18n.t('equalorlessthan')} ${field._target_}`,

  params: ['target'],

  validate: (value, { target }) => {
    const valueFormatted = formatNumber(value)
    const targetFormatted = formatNumber(target)

    const typeCastNumber = Number(valueFormatted)
    const typeCastTarget = Number(targetFormatted)

    return typeCastNumber <= typeCastTarget
  }
})

extend('dateBeforeThan', {
  message: (values, field) =>
    `${i18n.t('dateBeforeThan')} ${formatDate(field.target)}`,

  params: ['target'],

  validate: (value, { target }) => {
    if (
      !value ||
      !target ||
      !(isValidDate(target) || isValidDateTime(target))
    ) {
      return true
    }
    const dateFormat = momentDateFormat(target)

    const selectedDate = moment(value, dateFormat).toDate()
    const targetDate = moment(target, dateFormat).toDate()

    return selectedDate <= targetDate
  }
})

extend('dateAfterThan', {
  message: (values, field) =>
    `${i18n.t('dateAfterThan')} ${formatDate(field.target)}`,
  params: ['target'],

  validate: (value, { target }) => {
    if (
      !value ||
      !target ||
      !(isValidDate(target) || isValidDateTime(target))
    ) {
      return true
    }

    const dateFormat = momentDateFormat(target)

    const selectedDate = moment(value, dateFormat).toDate()
    const targetDate = moment(target, dateFormat).toDate()

    return selectedDate >= targetDate
  }
})

extend('dateEqual', {
  message: (values, field) => {
    return `${i18n.t('dateEqual')} ${formatDate(field.target)}`
  },

  params: ['target'],

  validate: (value, { target }) => {
    if (
      !value ||
      !target ||
      !(isValidDate(target) || isValidDateTime(target))
    ) {
      return true
    }

    const dateFormat = momentDateFormat(target)

    const selectedDate = moment(value, dateFormat).toDate()
    const targetDate = moment(target, dateFormat).toDate()

    return selectedDate.getTime() === targetDate.getTime()
  }
})

extend('hourEqual', {
  message: (values, field) => `${i18n.t('hour_equal')} ${field.target}`,

  params: ['target'],

  validate: (value, { target }) => {
    if (!value || !target || !isValidTime(target) || !isValidDateTime(value)) {
      return true
    }
    return target === formatHourFromDatetime(value)
  }
})

extend('hourBeforeThan', {
  message: (values, field) => `${i18n.t('hourBeforeThan')} ${field.target}`,

  params: ['target'],

  validate: (value, { target }) => {
    if (!value || !target || !isValidTime(target) || !isValidDateTime(value)) {
      return true
    }

    const selectedHour = moment(formatHourFromDatetime(value), 'HH:mm:ss')
    const targetHour = moment(target, 'HH:mm:ss')

    return selectedHour.isBefore(targetHour) || selectedHour.isSame(targetHour)
  }
})

extend('hourAfterThan', {
  message: (values, field) => `${i18n.t('hourAfterThan')} ${field.target}`,

  params: ['target'],

  validate: (value, { target }) => {
    if (!value || !target || !isValidTime(target) || !isValidDateTime(value)) {
      return true
    }

    const selectedHour = moment(formatHourFromDatetime(value), 'HH:mm:ss')
    const targetHour = moment(target, 'HH:mm:ss')

    return selectedHour.isAfter(targetHour) || selectedHour.isSame(targetHour)
  }
})

extend('after', {
  validate: () => {
    return true
  }
})
extend('after_or_equal', {
  validate: () => {
    return true
  }
})
extend('before', {
  validate: () => {
    return true
  }
})
extend('before_or_equal', {
  validate: () => {
    return true
  }
})

extend('fill_before', {
  message: (values, field) =>
    i18n.t('fill-before-validation', { target: field.target }),

  params: ['target'],

  validate: (value, { target }) => {
    return target !== null && target !== '' && target !== undefined
  }
})

extend('c8_unique', {
  message: (values, field) =>
    field.target && field.target4
      ? `${i18n.t('unique-message')} ${field.target2} ${field.target4}`
      : `${i18n.t('unique-message')}`,

  params: ['target', 'target2', 'target3', 'target4', 'target5'],

  validate: async (value, targets) => {
    const parameters = {
      [targets.target2]: targets.target
    }

    if (targets.target5) {
      parameters[targets.target4] = targets.target3
      parameters.id = targets.target5
    } else if (targets.target3 && targets.target4) {
      parameters[targets.target4] = targets.target3
    } else if (targets.target3 && !targets.target4) {
      parameters.id = targets.target3
    }

    const param = {
      parameters
    }

    const apiResponse = await api.post('validate-c8unique', param)

    return apiResponse.data
  }
})

extend('mimes', {
  message: (values, field) => `${i18n.t('mime')}`,

  params: ['target'],

  validate: async (value, field) => {
    const mime = await verifyFileMimeType(value, field)
    return !!mime
  }
})

extend('inflate_required_if', {
  params: ['targets', 'matrix', 'currentHierarchy'],
  computesRequired: true,
  message: (values, field) =>
    i18n.t('validation.required', { _field_: field._field_ }),
  validate: (value, { currentHierarchy, targets, matrix }) => {
    const targetsArray = targets.split(',')

    const isHierarchyChildOfTarget = item => {
      const parent = Object.keys(matrix).find(
        key => parseInt(key) === parseInt(item)
      )

      if (targetsArray.includes(String(item))) {
        return true
      }

      if (
        targetsArray.includes(matrix[parent] ? matrix[parent].toString() : null)
      ) {
        return true
      }

      if (!matrix[parent]) {
        return false
      }

      return isHierarchyChildOfTarget(matrix[parent])
    }

    const result = isHierarchyChildOfTarget(currentHierarchy)

    return !result || (result && !!value)
  }
})

extend('required_without', {
  message: (values, field) =>
    i18n.t('validation.required', { _field_: field._field_ }),

  params: ['target'],
  computesRequired: true,

  validate: (value, { target }) => {
    return !!target
  }
})

extend('required_if_visible', {
  message: (values, field) =>
    i18n.t('validation.required', { _field_: field._field_ }),

  params: ['isVisible'],
  computesRequired: true,

  validate: (value, { isVisible }) => {
    return !(!value && isVisible)
  }
})

extend('inflate_sum_compare', {
  message: () => '', // this rule has backoffice configured messages

  params: ['operator', 'targetValues', 'destinationValues'],

  validate: async (value, { operator, targetValues, destinationValues }) => {
    const targetTotal = targetValues.reduce((a, b) => a + b, 0)
    const destinationTotal = destinationValues.reduce((a, b) => a + b, 0)

    const result = numbers.compareValues(
      targetTotal,
      operator,
      destinationTotal
    )

    return result
  }
})

extend('inflate_sum_max', {
  message: () => '', // this rule has backoffice configured messages

  params: ['maxValue', 'total', 'isOn'],

  validate: async (value, params) => {
    if (Number(params.total) >= Number(params.maxValue) && params.isOn === 1) {
      return false
    }

    return true
  }
})

extend('table_waste_sum_max', {
  message: (values, field) =>
    i18n.t('table-waste-sum-max-validation', { _field_: field._field_ }),

  params: ['maxValue', 'total'],

  validate: async (value, { maxValue, total }) => {
    if (Number(total) > Number(maxValue)) {
      return false
    }

    return true
  }
})

extend('required_if_docmodal', {
  message: (values, field) =>
    i18n.t('validation.required', { _field_: field._field_ }),

  params: ['target'],

  computesRequired: true,

  validate: (value, { target }) => {
    return !testEmpty(target) || value !== ''
  }
})

extend('c8_password', {
  message: (values, field) => i18n.t('password-validation-error-message'),
  validate: (value, { score, passwordScore }) => {
    const containsRequiredChars =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?\W).{8,}$/.test(value)

    return containsRequiredChars
  }
})

extend('differentThan', {
  message: (values, field) => `${i18n.t('differentThan')}`,

  params: ['target'],

  validate: (value, { target }) => {
    return value !== target
  }
})

extend('equalThan', {
  message: (values, field) => `${i18n.t('equalThan')}`,

  params: ['target'],

  validate: (value, { target }) => {
    return value === target
  }
})

extend('compare_rule', {
  message: () => '', // this rule has backoffice configured messages

  params: ['target', 'valueCap', 'diffCap'],

  validate: compareRule
})

extend('required_if', {
  params: [
    {
      name: 'target',
      isTarget: true
    },
    {
      name: 'values'
    }
  ],

  validate: (value, _a) => {
    const target = _a.target
    let values = _a.values
    let required
    if (values && values.length) {
      if (!Array.isArray(values) && typeof values === 'string') {
        values = [values]
      }
      if (Array.isArray(target)) {
        required = target.some(obj => {
          return values.includes(String(obj.value))
        })
      } else {
        required = values.some(val => {
          return val === String(target).trim()
        })
      }
    } else {
      required = !testEmpty(target)
    }
    if (!required) {
      return {
        valid: true,
        required
      }
    }
    return {
      valid: !testEmpty(value),
      required
    }
  }
})

extend('checkbox_number_of_minimum_choices', {
  message: () => i18n.t(`validation_checkbox_number_of_minimum_choices`),
  params: ['minValue'],
  computesRequired: true,
  validate: (value, { minValue }) => {
    return value.length >= Number(minValue)
  }
})

extend('checkbox_number_of_maximum_choices', {
  message: () => i18n.t(`validation_checkbox_number_of_maximum_choices`),
  params: ['maxValue'],
  validate: (value, { maxValue }) => {
    return value.length <= Number(maxValue)
  }
})

extend('checkbox_number_of_choices', {
  message: () => i18n.t(`validation_checkbox_number_of_choices`),
  computesRequired: true,
  params: ['equalValue'],
  validate: (value, { equalValue }) => {
    return value.length === Number(equalValue)
  }
})

configure({
  defaultMessage: (field, values) => {
    // The line bellow is for replacing the field name in the validation message. The var "field" is the field flabel indicated in <ValidationProvider :name="schema.flabel">
    values._field_ = field

    // This overrides the default vee-validate messages with the i18n ones (i18n.js)
    return i18n.t(`validation.${values._rule_}`, values)
  }
})

function formatNumber(number) {
  const appStore = useAppStore()
  const { numberFormat } = storeToRefs(appStore)
  let leadingDelimiter = ''
  const numberFormatFormula = numberFormat.value.split('||')
  leadingDelimiter = numberFormatFormula[0]

  let numberFormatter = ''

  number = number.toString()

  // if thousands delimiter is . elseif thousands delimiter is ,
  if (leadingDelimiter === '.') {
    number = number.replace('.', '')
    numberFormatter = number.replace(/,/g, '.')
  } else if (leadingDelimiter === ',') {
    numberFormatter = number.replace(/,/g, '')
  } else if (leadingDelimiter === ' ') {
    numberFormatter = number.replace(/ /g, '').replace(/,/g, '.')
  } else {
    numberFormatter = number.replace(/,/g, '.')
  }

  return numberFormatter
}

function formatDate(input) {
  const appStore = useAppStore()

  const dateFormat = isValidDateTime(input)
    ? appStore.dateConfig.datetimeFormatJs
    : appStore.dateConfig.dateFormatJs

  const formatInput = moment(input).format(dateFormat)

  return formatInput
}

function verifyFileMimeType(file, field) {
  return new Promise((resolve, reject) => {
    const usedMimes = []

    const mimes = [
      {
        mime: 'image/jpeg',
        pattern: [0xff, 0xd8, 0xff],
        mask: [0xff, 0xff, 0xff],
        extention: 'jpeg, jpg'
      },
      {
        mime: 'image/png',
        pattern: [0x89, 0x50, 0x4e, 0x47],
        mask: [0xff, 0xff, 0xff, 0xff],
        extention: 'png'
      },
      {
        mime: 'application/pdf',
        pattern: [0x25, 0x50, 0x44, 0x46],
        mask: [0xff, 0xff, 0xff, 0xff],
        extention: 'pdf'
      },
      {
        mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        pattern: [0x50, 0x4b, 0x3, 0x4],
        mask: [0xff, 0xff, 0xff, 0xff],
        extention: 'xlsx'
      },
      {
        mime: 'application/xml',
        pattern: [0x3c, 0x3f, 0x78, 0x6d],
        mask: [0xff, 0xff, 0xff, 0xff],
        extention: 'xml'
      },
      {
        mime: 'application/xml',
        pattern: [0x3c, 0x6e, 0x66, 0x65],
        mask: [0xff, 0xff, 0xff, 0xff],
        extention: 'xml'
      },
      {
        mime: 'text/xml',
        pattern: [0x3c, 0x3f, 0x78, 0x6d],
        mask: [0xff, 0xff, 0xff, 0xff],
        extention: 'xml'
      },
      {
        mime: 'text/xml',
        pattern: [0x3c, 0x6e, 0x66, 0x65],
        mask: [0xff, 0xff, 0xff, 0xff],
        extention: 'xml'
      },
      {
        mime: 'application/vnd.ms-excel.sheet.macroEnabled.12',
        pattern: [0x50, 0x4b, 0x3, 0x4],
        mask: [0xff, 0xff, 0xff, 0xff],
        extention: 'xlsm'
      },
      {
        mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        pattern: [0x50, 0x4b, 0x3, 0x4],
        mask: [0xff, 0xff, 0xff, 0xff],
        extention: 'docx'
      }
    ]

    function check(bytes, mime) {
      for (let i = 0, l = mime.mask.length; i < l; ++i) {
        if (bytes[i] - mime.pattern[i] !== 0) {
          return false
        }
      }
      return true
    }

    if (file.length) {
      const arrayValid = []
      for (let s = 0; s < file.length; s++) {
        const blob = file[s].slice(0, 4) // read the first 4 bytes of the file
        const reader = new FileReader()
        reader.onloadend = e => {
          if (e.target.readyState === FileReader.DONE) {
            const bytes = new Uint8Array(e.target.result)

            for (let i = 0, l = mimes.length; i < l; ++i) {
              if (
                check(bytes, mimes[i]) &&
                file[s].type === mimes[i].mime &&
                field.target.includes(file[s].type)
              ) {
                arrayValid.push('valid')
              }

              usedMimes[i] = mimes[i].extention
            }

            if (s + 1 === file.length) {
              if (arrayValid.length !== file.length) {
                resolve(false)
              }
              resolve(true)
            }
          }
        }
        reader.readAsArrayBuffer(blob)
      }
    } else {
      const blob = file.slice(0, 4) // read the first 4 bytes of the file
      const reader = new FileReader()
      reader.onloadend = e => {
        if (e.target.readyState === FileReader.DONE) {
          const bytes = new Uint8Array(e.target.result)
          for (let i = 0, l = mimes.length; i < l; ++i) {
            if (check(bytes, mimes[i]) && file.type === mimes[i].mime) {
              return resolve(true)
            }
            usedMimes[i] = mimes[i].extention
          }
          return resolve(false)
        }
      }
      reader.readAsArrayBuffer(blob)
    }
  })
}

function isEmptyArray(arr) {
  return Array.isArray(arr) && arr.length === 0
}

function includes(collection, item) {
  return collection.indexOf(item) !== -1
}

function testEmpty(value) {
  return (
    isEmptyArray(value) ||
    includes([false, null, undefined], value) ||
    !String(value).trim().length
  )
}

function isValidDateTime(target) {
  return moment(target, 'YYYY-MM-DD HH:mm:ss', true).isValid()
}

function isValidTime(target) {
  return (
    moment(target, 'HH:mm:ss', true).isValid() ||
    moment(target, 'HH:mm', true).isValid()
  )
}

function isValidDate(target) {
  return moment(target, 'YYYY-MM-DD', true).isValid()
}

function formatHourFromDatetime(date) {
  const selectedDate = moment(date).toDate()
  const hour = selectedDate.getHours().toString().padStart(2, '0')
  const minute = selectedDate.getMinutes().toString().padStart(2, '0')
  const second = selectedDate.getSeconds().toString().padStart(2, '0')
  return `${hour}:${minute}:${second}`
}

function momentDateFormat(target) {
  return isValidDate(target) ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ss'
}

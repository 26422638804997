var render = function render(){var _vm=this,_c=_vm._self._c;return _c('validation-provider',{attrs:{"name":_vm.schema.flabel,"rules":_vm.schema.validatorCommon,"vid":_vm.schema.model},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-field',{staticClass:"custom-field",attrs:{"type":{ 'is-danger': errors[0] },"message":errors[0],"data-cy":_vm.schema.hasOwnProperty('properties') &&
      _vm.schema.properties.hasOwnProperty('data-cy')
        ? _vm.schema.properties['data-cy']
        : ''}},[_c('template',{slot:"label"},[_c('span',[_vm._v(" "+_vm._s(_vm.labelHandler)+" ")]),(_vm.checkRequired)?_c('span',{staticClass:"has-text-danger"},[_vm._v("*")]):_vm._e()]),_c('b-taginput',{attrs:{"data":_vm.filteredTags,"autocomplete":"","allow-new":false,"open-on-focus":true,"field":_vm.fieldHandlet,"disabled":_vm.disabled,"closable":!_vm.disabled,"icon":"label","placeholder":_vm.schema.placeholder,"data-cy":_vm.schema.hasOwnProperty('properties') &&
        _vm.schema.properties.hasOwnProperty('data-cy')
          ? _vm.schema.properties['data-cy']
          : ''},on:{"typing":_vm.getFilteredTags},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],2)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }
// TODO: migrate all number format functions to this helper

const mask = (value, mask, forceNumeralDecimalScale = null) => {
  if (!mask) return value

  const localeString = mask.numeralDecimalMark === '.' ? 'en-US' : 'de-DE'
  const decimalScale = forceNumeralDecimalScale ?? mask.numeralDecimalScale

  const numberFormatOptions = {}

  if (decimalScale) {
    numberFormatOptions.minimumFractionDigits = decimalScale
  }

  const formattedValue = Intl.NumberFormat(
    localeString,
    numberFormatOptions
  ).format(value)

  // console.log('value:', value)
  // console.log('numberFormatOptions:', numberFormatOptions)
  // console.log('localeString:', localeString)
  // console.log('formattedValue:', formattedValue)

  // console.log('mask:', {
  //   value,
  //   formattedValue,
  //   mask,
  //   forceNumeralDecimalScale
  // }) // debug

  return formattedValue
}

const unmask = (
  { delimiter, numeralDecimalMark, numeralDecimalScale },
  value
) => {
  if (value === undefined) return

  if (!numeralDecimalScale) {
    return parseInt(value.replaceAll(delimiter, ''))
  }

  const unmaskedValue = parseFloat(
    value
      .replace(new RegExp(`\\${delimiter}`, 'g'), '')
      .replace(new RegExp(`\\${numeralDecimalMark}`, 'g'), '.')
  ).toFixed(numeralDecimalScale)

  // console.log('unmask:', value, unmaskedValue, {
  //   delimiter,
  //   numeralDecimalMark,
  //   numeralDecimalScale
  // }) // debug

  return unmaskedValue
}

const compareValues = (value1, operator, value2) => {
  switch (operator) {
    case '==':
    case '===':
      return Number(value1) === Number(value2)
    case '<':
      return Number(value1) < Number(value2)
    case '>':
      return Number(value1) > Number(value2)
    case '<=':
      return Number(value1) <= Number(value2)
    case '>=':
      return Number(value1) >= Number(value2)
    default:
      return false
  }
}

export default { mask, unmask, compareValues }

<template>
  <div>
    <validation-provider
      v-if="!schema.hidden"
      :name="schema.flabel"
      :rules="schema.validatorCommon"
      v-slot="{ errors }"
      :vid="schema.model"
      ref="provider"
    >
      <b-field :type="{ 'is-danger': errors[0] }" :message="errors[0]">
        <template slot="label">
          <span
            :style="
              schema.hasOwnProperty('properties') &&
              schema.properties.hasOwnProperty('css')
                ? schema.properties.css
                : ''
            "
          >
            <b-icon
              v-if="
                schema.hasOwnProperty('properties') &&
                schema.properties.hasOwnProperty('icon') &&
                schema.properties['icon'] !== ''
              "
              :icon="schema.properties['icon']"
              :size="
                schema.hasOwnProperty('properties') &&
                schema.properties.hasOwnProperty('icon_size')
                  ? 'is-' + schema.properties['icon_size']
                  : 'is-small'
              "
              :style="
                schema.hasOwnProperty('properties') &&
                schema.properties.hasOwnProperty('icon_color')
                  ? 'color:' + schema.properties['icon_color']
                  : ''
              "
            >
            </b-icon>
            {{ labelHandler }}
          </span>
          <span class="has-text-danger" v-if="checkRequired">*</span>
        </template>
        <b-upload
          v-model="value"
          :multiple="schema.properties && schema.properties.multiple === 'true'"
          drag-drop
          ref="uploadElement"
          :data-cy="
            schema.hasOwnProperty('properties') &&
            schema.properties.hasOwnProperty('data-cy')
              ? schema.properties['data-cy']
              : ''
          "
          @input="handleUpload()"
          :accept="getAllowedFileTypes(schema)"
        >
          <section class="section">
            <div class="content has-text-centered">
              <p>
                <b-icon icon="upload" size="is-large"></b-icon>
              </p>
              <p>{{ schema.placeholder || schema.flabel }}</p>
            </div>
          </section>
        </b-upload>
      </b-field>
    </validation-provider>
    <div class="tags" v-if="Array.isArray(value)">
      <span v-for="(file, index) in value" :key="index" class="tag is-light">
        {{ file.name }}
        <button
          class="delete is-small"
          type="button"
          @click="deleteDropFile(index, file.index)"
        ></button>
      </span>
    </div>
    <div class="tags" v-else-if="value">
      <span class="tag is-light">
        {{ value.name }}
        <button
          class="delete is-small"
          type="button"
          @click="deleteDropFileSingle"
        ></button>
      </span>
    </div>
    <template v-if="isChunkUpload">
      <hr />
      <button
        :disabled="!value || value.length == 0"
        @click="startUploads()"
        type="button"
        class="button is-primary materials-modal-footer-buttons"
      >
        {{ $t('button-submit') }}
      </button>
    </template>
  </div>
</template>
<script>
import { abstractField } from 'vue-form-generator'
import { ValidationProvider } from 'vee-validate'
import { getValue, setValue } from '@/services/form-builder'
import { deleteFile } from '@/services/order'
import { verifyFileMimeType } from '@/services/utilities'

export default {
  mixins: [abstractField],
  components: {
    ValidationProvider
  },
  data: () => ({
    append: null,
    show: [],
    filesShowPath: 'files.show',
    filesAppendPath: 'files.append'
  }),
  created() {
    this.append = new FormData()
    if (this.schema.validatorCommon === undefined) {
      this.schema.validatorCommon = ''
    }
  },
  methods: {
    deleteDropFile(index, fileId = 0) {
      if (fileId > 0) {
        deleteFile(fileId)
      }

      getValue(this.model, String(this.schema.name)).splice(index, 1)

      getValue(this.model, String(this.filesShowPath)).splice(index, 1)

      this.$refs.provider.applyResult({
        errors: '',
        valid: false,
        failedRules: {}
      })
    },
    deleteDropFileSingle() {
      if (this.model.files) {
        this.model.files = null
      }
      this.value = null
      this.append = null
      this.show = []
    },
    handleUpload() {
      const src = URL.createObjectURL(event.target.files[0])
      this.$emit('fileuploaded', src)
    },
    startUploads() {
      const data = {
        files: this.value,
        config: this.model
      }
      window.Event.$emit('TriggerChunkLoader', data)
      this.$refs.uploadElement.clearInput()
      this.value = null
    },
    getAllowedFileTypes(schema) {
      const validatorArray = schema.validatorCommon.split('|')
      const mimesElement = validatorArray.find(element =>
        element.includes('mimes:')
      )

      const mimesValue = mimesElement ? mimesElement.split(':')[1] : null

      return mimesValue
    }
  },
  computed: {
    isChunkUpload() {
      return this.schema.properties?.chunk === 'true'
    },
    labelHandler() {
      return this.schema.showLabel ? this.schema.flabel : ''
    },
    checkRequired() {
      const validator = this.schema.validatorCommon
      if (validator) {
        const validatorSplit = validator.split('|')
        if (validatorSplit.includes('required')) {
          // contains required
          return true
        }
      }
      return false
    }
  },
  watch: {
    value(files) {
      if (!files || files.length === 0) {
        this.append.delete(`files[0]`) // toFix
        return false
      }

      this.append = new FormData()

      if (!Array.isArray(files)) {
        this.append.delete(`files[0]`)
        this.append.append('files[0]', files)
        this.show[0] = files.name
      }

      if (files.length) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i]
          this.append.append(`files[${i}]`, file)
          this.show[i] = files[i].name
        }
      }

      setValue(this.model, this.filesAppendPath.split('.'), this.append)
      setValue(this.model, this.filesShowPath.split('.'), this.show)
    }
  }
}
</script>

import setProperties from '@/services/form-builder/setProperties'

/**
 * @param {*} form
 */
export default function handleLandingPage(form) {
  const fieldObj = []
  const fieldArr = []
  const finalObj = {}

  for (const field in form) {
    fieldObj[field] = {}
    switch (form[field].field_type) {
      case 'group':
        fieldObj[field].legend = ''
        fieldObj[field].styleClasses = ['field', 'is-grouped', 'custom-group']
        fieldObj[field].fields = []
        if (form[field].childs && form[field].childs.fields) {
          for (const child in form[field].childs.fields) {
            fieldObj[field].fields.push(
              setProperties(form[field].childs.fields[child])
            )
          }
        }
        break
      case 'card':
        fieldObj[field] = {
          legend: '',
          styleClasses: ['group-card-form-builder'],
          fields: [setProperties(form[field])]
        }
        break
      case 'accordion':
        form[field].field_default_value = []
        fieldObj[field] = {
          legend: '',
          fields: [
            {
              type: 'array',
              label: '',
              model: form[field].field_name,
              itemContainerComponent: 'Accordion',
              newElementButtonLabelClasses: 'display-none',
              showRemoveButton: false,
              fieldClasses: '',
              items: {
                type: 'object',
                default: {},
                name: form[field].field_label,
                isClosed: form[field].disabled,
                schema: {
                  fields: []
                }
              }
            }
          ]
        }
        for (const child2 in form[field].childs.fields) {
          fieldObj[field].fields[0].items.schema.fields.push(
            setProperties(form[field].childs.fields[child2])
          )
        }
        fieldArr.push(fieldObj[field])
        continue
      default:
        fieldObj[field] = { legend: '', fields: [setProperties(form[field])] }
        break
    }

    fieldArr.push(fieldObj[field])
  }

  finalObj.model = window.formModel
  finalObj.groups = fieldArr

  return finalObj
}

import get from 'lodash/get'
import router from '../../router'

export default function replaceHtmlVars(html, vars, dataset) {
  Object.entries(vars || {}).forEach(([param, variable]) => {
    do {
      const toReplace = html.match(/{{([^}]+)}}/)
        ? html.match(/{{([^}]+)}}/)[0]
        : ''
      const toReplaceName = html.match(/{{([^}]+)}}/)
        ? html.match(/{{([^}]+)}}/)[1]
        : ''
      const routeParamValue =
        router.currentRoute.params[param].toLowerCase() || null
      let variableValue =
        variable[routeParamValue] && variable[routeParamValue][toReplaceName]
          ? variable[routeParamValue][toReplaceName]
          : 'NO VAR CONFIGURED'
      if (variableValue.includes('db:')) {
        variableValue = variableValue.split(':')[1]
        variableValue = get(dataset, variableValue)
      }
      if (toReplace !== '') {
        html = html.replace(toReplace, variableValue)
      }
    } while (html.match(/{{([^}]+)}}/))
  })
  return html
}

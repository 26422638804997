import { ToastProgrammatic as Toast } from 'buefy'
import { api } from '@/services/api'
import { uploadDocument } from '@/services/document'
import { openDialog } from '@/services/utilities'

export { callTransition }

async function transitionUploadDocument(transition) {
  let toReturn = {}
  if (transition.params.files) {
    const itemKey = `${transition.params.model
      .replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
      .substring(1)}_id`
    toReturn = await uploadDocument(
      transition.params.files,
      transition.params.model,
      transition.params[itemKey],
      transition.params.Document.type_id,
      transition.params.form_slug
    ).then(response => {
      return response
    })
  }
  return toReturn
}

async function callTransition(transition) {
  await Promise.all([
    api.post(transition.endpoint, transition.params).then(response => {
      let routeResponse = response.data
      if (response.code === 200) {
        const flow = Object.keys(response.data.actions)[0]
        routeResponse = response.data.actions[flow].route_response

        if (routeResponse.content) {
          if (routeResponse.http_code !== 200) {
            openDialog(
              'is-danger',
              `Error code: ${routeResponse.http_code}`,
              `Message: ${routeResponse.content}`,
              false
            )
          }
        }
      } else {
        openDialog(
          'is-danger',
          `Unexpected Error`,
          'Please contact support',
          false
        )
      }
      return routeResponse
    }),
    transitionUploadDocument(transition)
  ]).then(response => {
    Toast.open({
      type: 'is-success',
      message: response[0].content
    })
    return response
  })
}

import moment from 'moment'
import { api } from '@/services/api'
import { openDialog } from '@/services/utilities'

export default {
  init(view) {},
  handles(view) {
    return {
      onSubmit: _this => {
        _this.isLoading = true

        const payload = {
          OrderRecurring: {
            frequency: _this.frequency,
            start_at: moment(_this.startAt).format('YYYY-MM-DD'),
            interval: _this.interval,
            order_id: _this.$route.params.id,
            advance_creation_days: _this.advanceCreationDays,
            advance_creation_field: this.handles(view).advanceCreationField()
          }
        }

        if (_this.endAt) {
          payload.OrderRecurring.end_at = moment(_this.endAt).format(
            'YYYY-MM-DD'
          )
        }

        if (_this.frequency === 'weekly') {
          payload.OrderRecurring.weekdays = _this.weekdays
        } else if (
          _this.frequency === 'monthly' &&
          _this.monthlyRecurrenceType === 'day'
        ) {
          payload.OrderRecurring.month_day = _this.monthlyDay
        } else if (
          _this.frequency === 'monthly' &&
          _this.monthlyRecurrenceType === 'weekday'
        ) {
          payload.OrderRecurring.weekdays = [
            _this.monthlyWeekIndex + _this.monthlyWeekday
          ]
        }

        api.post(_this.module.submitFlow, payload).then(res => {
          _this.isLoading = false
          res = res.data.actions
            ? res.data.actions[Object.keys(res.data.actions)[0]].route_response
            : res.data

          if (res.http_code !== 200) {
            openDialog(
              'is-danger',
              `Unexpected Error`,
              res.message || 'Please contact support',
              false
            )
            return
          }

          if (view.modules[this.name].props.redirectAfterSubmit === '') {
            return
          }

          view.$router.push(
            view.modules[this.name].props.redirectAfterSubmit
              .split('/')
              .map(path =>
                path.includes(':') ? view.$route.params[path.slice(1)] : path
              )
              .join('/')
          )
        })
      },
      onCreate: _this => {
        api
          .post(_this.module.flow, { order_id: _this.$route.params.id })
          .then(res => {
            _this.isLoading = false

            res = res.data.actions
              ? res.data.actions[Object.keys(res.data.actions)[0]]
                  .route_response
              : res.data

            if (res.http_code !== 200) {
              openDialog(
                'is-danger',
                `Unexpected Error`,
                res.message || 'Please contact support',
                false
              )
            }

            const content = res.content
            if (!content) {
              return
            }

            _this.isEdit = true
            _this.frequency = content.frequency
            _this.interval = content.interval
            _this.advanceCreationDays = content.advance_creation_days

            if (content.start_at) {
              _this.startAt = moment(content.start_at).toDate()
            }

            if (content.end_at) {
              _this.endAt = moment(content.end_at).toDate()
            }

            _this.weekdays = content.weekdays || []

            if (content.frequency === 'monthly') {
              _this.monthlyRecurrenceType = 'day'
              if (content.month_day) {
                _this.monthlyDay = content.month_day
              }

              if (
                content.weekdays?.length > 0 &&
                content.weekdays[0].length > 2
              ) {
                _this.monthlyRecurrenceType = 'weekday'
                _this.monthlyWeekIndex = content.weekdays[0].substring(0, 2)
                _this.monthlyWeekday = content.weekdays[0].substring(2, 4)
              }
            }
          })
      },

      onDeleteRecurring: _this => {
        _this.$buefy.dialog.confirm({
          title: view.$t(
            'recurrence_form_confirm_title_delete_recurring_order'
          ),
          message: view.$t('recurrence_form_confirm_message_delete_recurring'),
          confirmText: 'Delete',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            if (!view.modules[this.name].props.endPoints.delete) {
              return
            }

            _this.isLoading = true
            api
              .post(view.modules[this.name].props.endPoints.delete, {
                id: _this.$route.params.id
              })
              .then(res => {
                _this.isLoading = false

                res = res.data.actions
                  ? res.data.actions[Object.keys(res.data.actions)[0]]
                      .route_response
                  : res.data

                if (res.http_code !== 200) {
                  openDialog(
                    'is-danger',
                    `Unexpected Error`,
                    res.message || 'Please contact support',
                    false
                  )
                }

                if (
                  !res.content ||
                  view.modules[this.name].props.redirectAfterSubmit === ''
                ) {
                  return
                }

                view.$router.push(
                  view.modules[this.name].props.redirectAfterSubmit
                    .split('/')
                    .map(path =>
                      path.includes(':')
                        ? view.$route.params[path.slice(1)]
                        : path
                    )
                    .join('/')
                )
              })
          }
        })
      },

      advanceCreationField: () => {
        if (view.modules[this.name].props.advanceCreationField) {
          return view.modules[this.name].props.advanceCreationField
        }
      }
    }
  }
}

<template>
  <div>
    <h1
      class="top-registration-title text-left"
      :style="
        schema.hasOwnProperty('properties') &&
        schema.properties.hasOwnProperty('css')
          ? schema.properties.css
          : ''
      "
    >
      <b-icon
        v-if="
          schema.hasOwnProperty('properties') &&
          schema.properties.hasOwnProperty('icon') &&
          schema.properties['icon'] !== ''
        "
        :icon="schema.properties['icon']"
        :size="
          schema.hasOwnProperty('properties') &&
          schema.properties.hasOwnProperty('icon_size')
            ? 'is-' + schema.properties['icon_size']
            : 'is-small'
        "
        :style="
          schema.hasOwnProperty('properties') &&
          schema.properties.hasOwnProperty('icon_color')
            ? 'color:' + schema.properties['icon_color']
            : ''
        "
      >
      </b-icon>
      {{ schema.flabel }}
    </h1>
  </div>
</template>
<script>
import { abstractField } from 'vue-form-generator'

export default {
  mixins: [abstractField]
}
</script>

import Vue from 'vue'

/**
 * @param {*} type
 * @helper : Get component by field type
 */

export default function getComponentByType(type) {
  const formType = window.formType
  let component = 'abstract'

  if (formType === 'OVERVIEW_FORM' || formType === 'LANDING_PAGE') {
    component = 'view'
  }

  switch (type) {
    case 'image':
      component = 'imagenize'
      break
    case 'datetime':
      component = 'time'
      break
    case 'section':
      component = 'section'
      break
    case 'text':
      component = 'abstract'
      break
    case 'checkbox':
    case 'checkboxlist':
      component = 'check'
      break
    case 'select':
      component =
        formType === 'OVERVIEW_FORM' || formType === 'LANDING_PAGE'
          ? 'view'
          : 'awesome'
      break
    case 'open_html':
      component = 'openhtml'
      break
    default:
      component = checkIfComponentFieldExists(type) ? type : component
      break
  }

  return component
}

/**
 * Helper Functions
 */

/**
 * @param {*} component
 */

function checkIfComponentFieldExists(component) {
  function capitalize(s) {
    return s[0].toUpperCase() + s.slice(1)
  }

  return `field${capitalize(component)}` in Vue.options.components
}

import { defineStore } from 'pinia'

export const useAppStore = defineStore('app', {
  state: () => {
    return {
      logo: '',
      name: '',
      clientKey: null,
      chunkModelUpload: null,
      languages: null,
      translations: null,
      blind_returns: null,
      public_registration: null,
      features: {},
      dateConfig: {
        dateFormat: '',
        datetimeFormat: '',
        timezone: '',
        dateFormatJs: '',
        datetimeFormatJs: ''
      },
      numberFormat: null,
      selectedLanguage: null
    }
  },
  actions: {
    clearSelectedLanguage() {
      this.selectedLanguage = null
    }
  },
  persist: true
})

import parseValueByType from '@/services/form-builder/parseValueByType'
import setValue from '@/services/form-builder/setValue'
import getDefaultValueByType from '@/services/form-builder/getDefaultValueByType'
import router from '../../router'

/**
 * @param {*} prop
 */

export default function setFormModel(props) {
  let defaultValue = null

  defaultValue = props.field_default_value

  if (window.formDataset !== null && props.hasOwnProperty('raw_field_name')) {
    defaultValue = parseValueByType(
      props.datatype,
      window.formDataset[props.raw_field_name]
    )
  } else if (
    typeof defaultValue === 'string' &&
    defaultValue.search('url_params') >= 0
  ) {
    defaultValue =
      router.currentRoute.params[
        defaultValue.replace('url_params:', '')
      ].toUpperCase() || null
  } else if (
    typeof defaultValue === 'string' &&
    defaultValue.search('store_params') >= 0
  ) {
    const scope = defaultValue.replace('store_params:', '')
    // toFix
    const values = scope.split('.')
  } else if (defaultValue === null || defaultValue === undefined) {
    defaultValue = getDefaultValueByType(props.datatype)
  }

  Object.assign(
    window.formModel,
    setValue(window.formModel, props.field_name.split('.'), defaultValue)
  )
}

import { i18n, loadLanguageAsync } from '@/plugins/i18n'
import { useAppStore } from '@/stores/app'
import { usePageStore } from '../stores/pages'
import { useUserStore } from '@/stores/user'
import { useLayoutStore } from '../stores/layout'
import { generic, getClientLogo } from '@/services/utilities'
import { resetRouter } from '@/router'

const loadPage = (userStore, appStore, pageDetails) => {
  const params = {
    client_key: appStore.clientKey,
    form:
      pageDetails.forms.length > 0
        ? pageDetails.forms[0].slug
        : 'password-reset',
    id: userStore.id,
    locale: userStore.language
  }
  return generic('manage-user-vue', params)
}

export default async function passwordReset(to, from, next) {
  const userStore = useUserStore()
  const appStore = useAppStore()
  const pageStore = usePageStore()
  const layoutStore = useLayoutStore()

  await resetRouter

  appStore.$patch({
    clientKey: from.params.key
  })

  const language = to.params.language || 'en'

  appStore.$patch({
    selectedLanguage: language
  })

  userStore.$patch({
    language
  })

  i18n.locale = language

  loadLanguageAsync(language, true)
  const pageDetails = pageStore.pages['/password-reset/:id']

  const page = await loadPage(userStore, appStore, pageDetails)

  const forms = [
    {
      name: page.content.form.form_name,
      slug: page.content.form.form_slug,
      type: page.content.form.form_type
    }
  ]

  layoutStore.$patch({
    isNavBarVisible: true,
    isFooterBarVisible: true,
    isChunkModelUploaderVisible: false,
    isAsideVisible: false,
    asideMobileStateToggle: false,
    isAsideMobileExpanded: false
  })

  document.querySelector('html').classList.remove('has-aside-left')

  pageStore.$patch({
    pages: [],
    appNotifications: [],
    page: {
      label: page.content.form.form_name,
      hideHerobar: true,
      classes: {
        container: 'blind-returns-backgroud',
        sectionContainer: 'blind-returns-container'
      },
      forms,
      flow: '/manage-user-vue',
      to: '/password-reset/:id',
      title: page.content.form.form_name,
      menu: {},
      modules: [
        {
          type: 'form',
          flow: '/manage-user-vue',
          submit_flow: '/update-user-password',
          props: '{"model": {},"title": null,"endPoints": {"list": "/"}}',
          forms
        }
      ],
      tabs: [],
      buttons: [],
      features: [],
      notifications: []
    }
  })

  next()
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.schema.hidden)?_c('validation-provider',{ref:"provider",attrs:{"name":_vm.schema.flabel,"rules":_vm.schema.validatorCommon,"vid":_vm.schema.model},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0] },"message":errors[0]}},[_c('template',{slot:"label"},[_c('span',{style:(_vm.schema.hasOwnProperty('properties') &&
            _vm.schema.properties.hasOwnProperty('css')
              ? _vm.schema.properties.css
              : '')},[(
              _vm.schema.hasOwnProperty('properties') &&
              _vm.schema.properties.hasOwnProperty('icon') &&
              _vm.schema.properties['icon'] !== ''
            )?_c('b-icon',{style:(_vm.schema.hasOwnProperty('properties') &&
              _vm.schema.properties.hasOwnProperty('icon_color')
                ? 'color:' + _vm.schema.properties['icon_color']
                : ''),attrs:{"icon":_vm.schema.properties['icon'],"size":_vm.schema.hasOwnProperty('properties') &&
              _vm.schema.properties.hasOwnProperty('icon_size')
                ? 'is-' + _vm.schema.properties['icon_size']
                : 'is-small'}}):_vm._e(),_vm._v(" "+_vm._s(_vm.labelHandler)+" ")],1),(_vm.checkRequired)?_c('span',{staticClass:"has-text-danger"},[_vm._v("*")]):_vm._e()]),_c('b-upload',{ref:"uploadElement",attrs:{"multiple":_vm.schema.properties && _vm.schema.properties.multiple === 'true',"drag-drop":"","data-cy":_vm.schema.hasOwnProperty('properties') &&
          _vm.schema.properties.hasOwnProperty('data-cy')
            ? _vm.schema.properties['data-cy']
            : '',"accept":_vm.getAllowedFileTypes(_vm.schema)},on:{"input":function($event){return _vm.handleUpload()}},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('section',{staticClass:"section"},[_c('div',{staticClass:"content has-text-centered"},[_c('p',[_c('b-icon',{attrs:{"icon":"upload","size":"is-large"}})],1),_c('p',[_vm._v(_vm._s(_vm.schema.placeholder || _vm.schema.flabel))])])])])],2)]}}],null,false,3817045458)}):_vm._e(),(Array.isArray(_vm.value))?_c('div',{staticClass:"tags"},_vm._l((_vm.value),function(file,index){return _c('span',{key:index,staticClass:"tag is-light"},[_vm._v(" "+_vm._s(file.name)+" "),_c('button',{staticClass:"delete is-small",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteDropFile(index, file.index)}}})])}),0):(_vm.value)?_c('div',{staticClass:"tags"},[_c('span',{staticClass:"tag is-light"},[_vm._v(" "+_vm._s(_vm.value.name)+" "),_c('button',{staticClass:"delete is-small",attrs:{"type":"button"},on:{"click":_vm.deleteDropFileSingle}})])]):_vm._e(),(_vm.isChunkUpload)?[_c('hr'),_c('button',{staticClass:"button is-primary materials-modal-footer-buttons",attrs:{"disabled":!_vm.value || _vm.value.length == 0,"type":"button"},on:{"click":function($event){return _vm.startUploads()}}},[_vm._v(" "+_vm._s(_vm.$t('button-submit'))+" ")])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
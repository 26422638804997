import axios from 'axios'
import _ from 'lodash'
import { storeToRefs } from 'pinia'
import { i18n, loadLanguageAsync } from '@/plugins/i18n'
import { useUserStore } from '../stores/user'
import { useAppStore } from '../stores/app'
import { usePageStore } from '@/stores/pages'
import router from '@/router'
import { config } from '@/helpers/config'
import { errorPromptHandler } from '@/services/utilities'
import { cancelRequest, updateDetails } from './api-interceptors'

const client = axios.create({
  baseURL: `${config.ApiUrl}`,
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
  json: true,
  timeout: 2700000
})

function validBody(body) {
  Object.keys(body).map(prop => {
    if (prop.indexOf('.') === -1) return

    const array = prop.split('.')

    if (!body[array[0]]) body[array[0]] = {}

    body[array[0]][array[1]] = body[prop]
    delete body[prop]

    return null
  })

  return body
}

async function execute(
  method,
  resource,
  data = {},
  customHeaders = {},
  cancelToken = null
) {
  const userStore = useUserStore()
  const appStore = useAppStore()
  const pageStore = usePageStore()
  const { language, token, getAccessToken, isImpersonating, impersonateUser } =
    storeToRefs(userStore)
  const { clientKey } = storeToRefs(appStore)

  if (i18n.locale && !data.locale) {
    data.locale = i18n.locale
  }

  if (typeof data.form === 'object') {
    data.form = data.form.slug
  }

  if (clientKey.value) {
    data.client_key = clientKey.value
  }

  if (userStore.isImpersonating) {
    data.impersonator_id = userStore.impersonateUser.id
  }

  const defaultHeaders = {
    Authorization: token.value ? `Bearer ${getAccessToken.value}` : ''
  }

  resource =
    window.location.href.indexOf('localhost') > -1
      ? ((localStorage.getItem('circul8@api') || '') + resource).replace(
          '/api//',
          '/api/'
        )
      : resource

  return client({
    method,
    headers: {
      ...defaultHeaders,
      ...customHeaders
    },
    cancelToken,
    url: resource,
    data
  })
    .then(response => {
      if (response.data.actions) {
        Object.values(response.data.actions).forEach(action => {
          if (action.route_response.error) {
            const error = {
              response: {
                data: action.route_response.error,
                status: action.route_response.http_code
              }
            }
            throw error
          }

          if (
            action.route_response.http_code === 403 &&
            action.route_response.content
          ) {
            const error = {
              response: {
                data: { message: action.route_response.content },
                status: action.route_response.http_code
              }
            }

            throw error
          }

          if (
            action.route_response.content &&
            action.route_response.content.form
          ) {
            pageStore.$patch({
              currentFormId:
                action.route_response.content.form.form_definition_id
            })
          }
        })
      }
      if (
        router.history.current.name === 'review' &&
        response.data.app_date_format
      ) {
        appStore.$patch({
          dateConfig: {
            dateFormat: response.data.app_date_format,
            datetimeFormat: response.data.app_datetime_format,
            dateFormatJs: response.data.app_date_format_javascript,
            datetimeFormatJs: response.data.app_datetime_format_javascript
          }
        })
        appStore.$patch({
          numberFormat: response.data.number_format
        })
      }

      if (response.data.reload_translations) {
        let currentLanguage = 'en'

        if (language.value && language.value !== {}) {
          currentLanguage = language.value
        }

        loadLanguageAsync(currentLanguage, true)
      }

      updateDetails(response.data.details_version)

      return {
        message: 'Response Valid',
        code: response.status,
        data: response.data
      }
    })
    .catch(async error => {
      // debug
      if (process.env.NODE_ENV === 'development') console.error('error', error)
      const toThrow = {
        ...(error.response ? error.response.data : error),
        ...{ status: error.response ? error.response.status : 400 }
      }

      errorPromptHandler.handle(toThrow)

      throw toThrow
    })
}

export const api = {
  get(route) {
    return execute('get', `/${route}`)
  },

  getOne(route, id) {
    return execute('get', `/${route}/${id}`)
  },

  post(route, data, customHeaders = {}) {
    return execute('post', `/${route}`, data, customHeaders).catch(error => {
      return error
    })
  },

  put(route, id, data) {
    return execute('put', `/${route}/${id}`, data)
  },

  delete(route, id) {
    return execute('delete', `/${route}/${id}`)
  },

  async call(params) {
    if (!params.endPoint) return console.log('api:', 'endPoint not found')

    let cancelSource = cancelRequest.create()

    if (params.view && params.module && !params.noLoading)
      params.view.isLoading[params.module.name] = true

    if (params.isLoading) params.isLoading = true

    params.body = validBody(params.body)

    cancelRequest.check(cancelSource, params.module ? params.module.name : null)

    const res = await execute(
      params.method || 'get',
      params.endPoint,
      params.body,
      {},
      cancelSource.token
    )
      .then(res => {
        cancelRequest.clear(params.module ? params.module.name : null)
        cancelSource = {}
        return res
      })
      .catch(error => {
        if (params.errorCallback) params.errorCallback(error)

        return error
      })

    if (params.isLoading) params.isLoading = false
    if (params.callback && res.data) params.callback(res.data)

    if (params.view && params.module && !params.noLoading)
      params.view.isLoading[params.module.name] = false

    return null
  }
}

export default {
  operators: [
    {
      code: '=',
      translationKey: 'view-manager-operator-equal'
    },
    {
      code: '!=',
      translationKey: 'view-manager-operator-different'
    },
    {
      code: '>',
      translationKey: 'view-manager-operator-greater-than'
    },
    {
      code: '<',
      translationKey: 'view-manager-operator-lower-than'
    },
    {
      code: 'LIKE',
      translationKey: 'view-manager-operator-contains'
    },
    {
      code: 'NOT LIKE',
      translationKey: 'view-manager-operator-not-contains'
    },
    {
      code: 'IN',
      translationKey: 'view-manager-operator-in-multiple'
    },
    {
      code: 'NOT_IN',
      translationKey: 'view-manager-operator-not-in-multiple'
    },
    {
      code: 'IS_EMPTY',
      translationKey: 'view-manager-operator-is-empty'
    },
    {
      code: 'IS_NOT_EMPTY',
      translationKey: 'view-manager-operator-is-not-empty'
    },
    {
      code: 'STARTS_WITH',
      translationKey: 'view-manager-operator-starts-with'
    }
  ],
  fieldTypes: {
    input: {
      tag: 'b-input',
      placeholder: '',
      possibleOperators: [
        '=',
        '!=',
        '>',
        '<',
        'LIKE',
        'NOT LIKE',
        'IS_EMPTY',
        'IS_NOT_EMPTY',
        'STARTS_WITH',
        'IN',
        'NOT_IN'
      ]
    },
    select: {
      tag: 'multiselect',
      placeholder: 'view-manager-select-option',
      possibleOperators: [
        '=',
        '!=',
        'LIKE',
        'NOT LIKE',
        'IN',
        'NOT_IN',
        'IS_EMPTY',
        'IS_NOT_EMPTY'
      ]
    },
    datetime: {
      tag: 'b-datetimepicker',
      placeholder: 'view-manager-select-date',
      icon: 'calendar-today',
      possibleOperators: ['=', '>', '<', 'IS_EMPTY', 'IS_NOT_EMPTY']
    },
    date: {
      tag: 'b-datepicker',
      placeholder: 'view-manager-select-date',
      icon: 'calendar-today',
      possibleOperators: ['=', '>', '<', 'IS_EMPTY', 'IS_NOT_EMPTY']
    },
    calendar_datetime: {
      tag: 'b-datetimepicker',
      placeholder: 'view-manager-select-date',
      icon: 'calendar-today',
      possibleOperators: ['=', '>', '<', 'IS_EMPTY', 'IS_NOT_EMPTY']
    },
    boolean: {
      tag: 'multiselect',
      placeholder: 'view-manager-select-option',
      possibleOperators: ['=']
    }
  }
}

import _ from 'lodash'
import { DialogProgrammatic as Dialog } from 'buefy'
import { storeToRefs } from 'pinia'
import { api } from '@/services/api'
import { i18n } from '@/plugins/i18n'
import __init from '@/services/form-builder/init'
import getValue from '@/services/form-builder/getValue'
import setValue from '@/services/form-builder/setValue'
import getForm from '@/services/form-builder/getForm'
import handleListForm from '@/services/form-builder/handleListForm'
import { useUserStore } from '../../stores/user'
import { useUtilitiesStore } from '@/stores/utilities'

export {
  getForm,
  getValue,
  setValue,
  handleListForm,
  setCompleteFormModel,
  getValueFormModel,
  getDefaultValue,
  callRunDependency,
  clearTraceFields
}

/**
 * Set Global Properties
 */

window.formDataset = null
window.formType = null
window.formModel = {}
window.completeFormModel = {}
window.traceFields = []

function clearTraceFields() {
  window.traceFields = []
}

function setCompleteFormModel(model) {
  window.completeFormModel = model
}

function getValueFormModel(path, model) {
  if (model[path.split('.')[0]]) {
    return path.split('.').reduce((res, prop) => res[prop], model)
  }
}

function getDefaultValue(defaultValue, value) {
  if (!value && defaultValue && !defaultValue.includes('url_params:')) {
    return defaultValue
  }
}

/**
 * @param {*} options
 */
async function runDependency(options = {}) {
  const userStore = useUserStore()
  const { token } = storeToRefs(userStore)

  const isLogged = token.value
  let runDependencySlug = ''
  if (isLogged !== null) {
    runDependencySlug = 'run-dependency'
  } else {
    runDependencySlug = 'blind-returns-run-dependency'
  }
  const APIResponse = await api.post(runDependencySlug, options)

  if (APIResponse.code === 200) {
    return APIResponse.data
  }
}

function callRunDependency(props, model, value, edit = false) {
  let formSlug = model.form_slug

  handleClearWaste(props, model)

  const utilitiesStore = useUtilitiesStore()
  const { currentForm } = storeToRefs(utilitiesStore)

  if (
    Object.keys(window.completeFormModel).length > 0 &&
    window.completeFormModel.form_slug &&
    typeof formSlug === 'undefined'
  ) {
    formSlug = window.completeFormModel.form_slug
  }

  if (!props.run_dependency || !props.id || !formSlug) {
    return
  }

  if (!value || typeof value === 'undefined') {
    props.conditions.forEach(condition => {
      if (
        window.traceFields[currentForm.value][condition].hierarchySlug !== 1 &&
        !window.traceFields[currentForm.value][condition]
      ) {
        window.traceFields[currentForm.value][condition].values = []
      }
    })

    return
  }

  /* Format the request for the dependency */
  const params = {
    form: formSlug,
    field_id: props.id,
    field_value: value,
    locale: i18n.locale
  }

  if (props.company_dependency) {
    params.company_dependency_id = _.get(
      window.completeFormModel,
      props.company_dependency
    )
  }

  const dependencyResponse = runDependency(params).then(response => {
    for (const data in response) {
      if (Array.isArray(response[data])) {
        window.traceFields[currentForm.value][data].values = response[data]
      } else {
        setValue(model, data.split('.'), response[data])
      }
    }
  })

  return dependencyResponse
}

function handleClearWaste(props, model) {
  if (
    props.properties &&
    props.properties.clear_waste &&
    model.Order &&
    model.Order.items &&
    model.Order.items.length
  ) {
    Dialog.confirm({
      type: 'is-danger',
      message: i18n.t('clear-waste-warning'),
      onConfirm: () => {
        model.Order.items = []
      }
    })
  }
}

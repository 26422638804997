/**
 * @param {*} base
 * @param {*} names
 * @param {*} value
 * @helper : Set value in the base variable
 */

export default function setValue(base, names, value) {
  const lastName = arguments.length === 3 ? names.pop() : false
  for (let i = 0; i < names.length; i++) {
    base = base[names[i]] = base[names[i]] || {}
  }

  if (lastName) base = base[lastName] = value
}
/**
 * @helper : Clear Trace Fields
 */

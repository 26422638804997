import { storeToRefs } from 'pinia'
import Vue from 'vue'
import Router from 'vue-router'
import multiguard from 'vue-router-multiguard'
import Meta from 'vue-meta'
import auth from '@/middlewares/auth.js'
import register from '@/middlewares/register.js'
import setPage from '@/middlewares/setPage.js'
import blindReturns from '@/middlewares/blindReturns.js'
import passwordReset from '@/middlewares/passwordReset.js'
import { loadLanguageAsync } from '@/plugins/i18n'
import pinia from './pinia'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { usePageStore } from '@/stores/pages'
import { config } from '@/helpers/config'

Vue.use(Router)

Vue.use(Meta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
})

const createRouter = () => {
  const pageStore = usePageStore(pinia)
  const { pages } = storeToRefs(pageStore)

  const dynamicRoutes = Object.values(pages.value || {}).map(page => {
    if (page.to === '') return

    return {
      path: page.to.replace(/^(?!\/)/, '/'), // adds a leading "/" if it's missing
      name: page.to.replace('/', '.'),
      component: () => import('./views/Dynamic/View.vue'),
      beforeEnter: multiguard([auth, setPage]),
      meta: {
        layout: 'app',
        dynamic: true
      }
    }
  })

  return new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
      {
        path: '/login/:key/:language?',
        name: 'login',
        component: () => import('./views/Login.vue'),
        props: true,
        meta: {
          layout: 'simple'
        },
        beforeEnter: (to, from, next) => {
          const appStore = useAppStore()
          const userStore = useUserStore()
          const { language } = storeToRefs(userStore)

          appStore.$patch({
            clientKey: to.params.key
          })

          if (config.appsToRedirectVue3.includes(to.params.key)) {
            window.location.href = `${config.vue3URL}login/${to.params.key}`
          }

          next()
        }
      },
      {
        path: '/password-reset/:id',
        name: 'passwordreset',
        component: () => import('./views/Dynamic/View.vue'),
        meta: {
          layout: 'noMenu',
          public: true
        },
        props: true,
        beforeEnter: multiguard([passwordReset])
      },
      {
        path: '/register/:key/:language?',
        name: 'register',
        component: () => import('./views/Dynamic/View.vue'),
        meta: {
          layout: 'simple',
          public: true
        },
        props: true,
        beforeEnter: multiguard([register])
      },
      {
        path: '/recover/:key',
        name: 'recover',
        component: () => import('./views/Recover.vue'),
        meta: {
          layout: 'simple',
          public: true
        },
        props: true,
        beforeEnter: (to, from, next) => {
          if (config.appsToRedirectVue3.includes(to.params.key)) {
            window.location.href = `${config.vue3URL}recover/${to.params.key}`
          }

          next()
        }
      },
      {
        path: '/recovery/:key/:token/:language?',
        name: 'recovery',
        component: () => import('./views/Recovery.vue'),
        meta: {
          layout: 'simple',
          public: true
        },
        props: true,
        beforeEnter: (to, from, next) => {
          const appStore = useAppStore()
          const userStore = useUserStore()
          const { language } = storeToRefs(userStore)

          appStore.$patch({
            clientKey: to.params.key
          })

          if (config.appsToRedirectVue3.includes(to.params.key)) {
            window.location.href = `${config.vue3URL}recovery/${
              to.params.key
            }/${to.params.token}/${to.params.language ?? 'en'}`
          }

          next()
        }
      },
      {
        path: '/account',
        name: 'account',
        component: () => import('./views/Users/Account.vue'),
        meta: {
          layout: 'app'
        },
        beforeEnter: multiguard([auth, setPage])
      },
      // {
      //   path: '/users/new',
      //   name: 'users.new',
      //   component: () => import('./views/Users/New.vue'),
      //   beforeEnter: multiguard([auth, setPage])
      // },
      {
        path: '/companies/:id',
        name: 'companies.view',
        component: () => import('./views/Company/Manager/View.vue'),
        meta: {
          layout: 'app'
        },
        beforeEnter: multiguard([auth, setPage])
      },
      {
        path: '/company/price/matrix',
        name: 'company.price.matrix.list',
        component: () => import('./views/Company/PriceMatrixList.vue'),
        meta: {
          layout: 'app'
        },
        beforeEnter: multiguard([auth, setPage])
      },
      {
        path: '/company/price/matrix/new',
        name: 'company.price.matrix.new',
        component: () => import('./views/Company/PriceMatrixNew.vue'),
        meta: {
          layout: 'app'
        },
        beforeEnter: multiguard([auth, setPage])
      },
      {
        path: '/price-movements/export',
        name: 'price-movements.export',
        component: () => import('./views/PriceMatrix/PriceMovementExport.vue'),
        meta: {
          layout: 'app'
        },
        beforeEnter: multiguard([auth, setPage])
      },
      {
        path: '/invoicing',
        name: 'invoicing',
        component: () => import('./views/Invoicing/AutomaticInvoicing.vue'),
        meta: {
          layout: 'app'
        },
        beforeEnter: multiguard([auth, setPage])
      },
      {
        path: '/invoicing-company',
        name: 'invoicing-company',
        component: () => import('./views/Invoicing/AutomaticInvoicing.vue'),
        meta: {
          layout: 'app'
        },
        beforeEnter: multiguard([auth, setPage])
      },
      {
        path: '/upload-template/:slug', // slug just needs to be unique, not used in any logic
        name: 'upload.template',
        component: () => import('./views/UploadTemplate.vue'),
        meta: {
          layout: 'app'
        },
        beforeEnter: multiguard([auth, setPage])
      },
      {
        path: '/protocols/view/:id',
        name: 'protocols.show',
        component: () => import('./views/Protocols/Show.vue'),
        meta: {
          layout: 'app'
        },
        beforeEnter: multiguard([auth, setPage])
      },
      {
        path: '/returns/:key/:country/:language?',
        name: 'returns',
        // component: () => import('./views/BlindReturns/Return.vue'),
        component: () => import('./views/Dynamic/View.vue'),
        props: true,
        meta: {
          layout: 'simple',
          public: true
        },
        beforeEnter: multiguard([blindReturns])
      },
      {
        path: '/landing/:key/:country',
        name: 'landing',
        component: () => import('./views/Landing/View.vue'),
        props: true,
        meta: {
          layout: 'simple',
          public: true
        }
      },
      {
        path: '/review/:hash',
        name: 'review',
        component: () => import('./views/External/GuestMain.vue'),
        props: true,
        meta: {
          layout: 'simple',
          public: true
        },
        beforeEnter: (to, from, next) => {
          if (!to.query.ckey || !to.query.locale) {
            this.$router.push({ name: 'not-found' })
          }

          const appStore = useAppStore()
          const { clientKey } = storeToRefs(appStore)
          clientKey.value = to.query.ckey

          loadLanguageAsync(to.query.locale, true)
          next()
        }
      },
      {
        path: '*',
        name: 'not-found',
        component: () => import('./views/NotFound.vue'),
        meta: {
          layout: 'simple'
        },
        beforeEnter: multiguard([auth])
      }
    ].concat(dynamicRoutes)
  })
}

const router = createRouter()

export const resetRouter = new Promise((resolve, reject) => {
  const newRouter = createRouter()

  router.matcher = newRouter.matcher

  resolve()
})

export default router

<template>
  <div id="app">
    <component :is="layout" :menus="menus" :page="page">
      <router-view :key="uuid" />
    </component>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'
import { v4 as uuidv4 } from 'uuid'
import tippy from 'tippy.js'
import { userPrivateChannel } from '@/services/websockets'
import { i18n } from '@/plugins/i18n'
import { useMenuStore } from '@/stores/menus'
import { useUserStore } from '@/stores/user'
import { usePageStore } from '@/stores/pages'

export default {
  name: 'app',
  data: () => ({
    uuid: uuidv4(),
    layoutName: ''
  }),
  metaInfo: {
    title: 'Home of Circular Economy Efficiency',
    titleTemplate: '%s - circul8®'
  },
  created() {
    Object.assign(window, { _showCys: this.showDataCys })

    userPrivateChannel('FrontendNotifier')

    if (this.language != null) {
      i18n.locale = this.language
    }

    this.currentLocale = i18n.locale
  },
  setup() {
    const menusStore = useMenuStore()
    const userStore = useUserStore()
    const pageStore = usePageStore()
    const { page } = storeToRefs(pageStore)

    const { menus } = storeToRefs(menusStore)
    const { language } = storeToRefs(userStore)
    return { menus, language, page }
  },
  methods: {
    showDataCys() {
      const dataCys = {
        main: {},
        menu: {},
        nav: {}
      }

      document.querySelectorAll('[data-cy]').forEach(el => {
        const dataCy = el.getAttribute('data-cy')

        el.style['box-shadow'] = '0px 0px 4px 3px #296adf'
        tippy(el, {
          content: `data-cy="${dataCy}"`,
          interactive: true
        })

        if (el.closest('section.is-main-section')) {
          dataCys.main[dataCy] = el
        }

        if (el.closest('aside.aside')) {
          dataCys.menu[dataCy] = el
        }

        if (el.closest('#navbar-main')) {
          dataCys.nav[dataCy] = el
        }
      })

      console.log('Happy testing! 🤞')
      return dataCys
    }
  },
  computed: {
    layout() {
      const layout = this.layoutName
      return () =>
        import(
          `@/layouts/${
            layout && layout[0].toUpperCase() + layout.slice(1)
          }Layout.vue`
        )
    }
  },
  watch: {
    // using a watch for this because if we put this.$route.meta.layout in the computed propery layout
    // the entire app rerenders when anything changes in the route
    '$route.meta.layout': {
      handler(newValue) {
        this.layoutName = newValue || 'simple'
      },
      immediate: true
    }
  }
}
</script>
<style></style>

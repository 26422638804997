<template>
  <div>
    <a @click="isCardModalActive = true">
      {{ schema.placeholder }}
      <span v-if="Array.isArray(value)"> ({{ value.length }}) </span>
    </a>
    <b-modal :active.sync="isCardModalActive" has-modal-card>
      <div
        class="modal-card"
        :style="
          schema.hasOwnProperty('properties') &&
          schema.properties.hasOwnProperty('width')
            ? `width: ${schema.properties['width']}px;`
            : 'width: 300px;'
        "
      >
        <header class="modal-card-head">
          <p class="modal-card-title top-header-primary">{{ schema.flabel }}</p>
        </header>
        <div class="modal-card-body" v-if="Array.isArray(value)">
          <div class="columns" v-for="file in value" v-bind:key="file.id">
            <div class="column is-half">{{ file.name }}</div>
            <div class="column">
              <span v-if="file.created_at" class="tag is-secondary">
                {{ file.created_at }}
              </span>
            </div>
            <div class="column">
              <a @click="downloadFile(file)"
                ><b-icon class="button-download-file" icon="download"> </b-icon
              ></a>
            </div>
          </div>
        </div>
        <footer class="modal-card-foot"></footer>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { abstractField } from 'vue-form-generator'
import { getFileInfo, downloadFile } from '@/services/order'
import {
  downloadFilePopup,
  getDocument,
  downloadFile as documentDownloadFile
} from '@/services/document'

export default {
  mixins: [abstractField],
  data() {
    return {
      isCardModalActive: false
    }
  },
  methods: {
    downloadFile(object) {
      if (
        this.schema.hasOwnProperty('properties') &&
        this.schema.properties.hasOwnProperty('model') &&
        this.schema.properties.hasOwnProperty('parameter')
      ) {
        const data = { document_id: object.id }
        getDocument(data).then(response => {
          const aux =
            response.data.actions.get_document_binary.route_response.content
          const mime =
            response.data.actions.get_document_binary.route_response.content
              .mimetype
          documentDownloadFile(aux.location).then(response => {
            const blobAux = new Blob([response.data], { type: mime })
            downloadFilePopup(
              window.URL.createObjectURL(blobAux),
              aux.real_name
            )
          })
        })
      } else {
        alert('Error :: Missing Configuration!')
      }
    }
  }
}
</script>

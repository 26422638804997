<template>
  <b-field
    :horizontal="schema.showLabel > 0"
    :label="labelHandler"
    class="custom-field review-fields"
    :class="{ 'field-word-break': schema.disabled }"
    :style="
      schema.hasOwnProperty('properties') &&
      schema.properties.hasOwnProperty('css')
        ? schema.properties.css
        : ''
    "
    :hidden="schema.hidden"
  >
    <span
      v-if="
        schema.hasOwnProperty('properties') &&
        schema.properties.hasOwnProperty('before')
      "
      v-html="handleBeforeAfter(schema.properties.before)"
    ></span>
    {{ mask }}
    <span
      style="margin-left: 3px"
      v-if="
        schema.hasOwnProperty('properties') &&
        schema.properties.hasOwnProperty('after')
      "
      v-html="handleBeforeAfter(schema.properties.after)"
    ></span>
  </b-field>
</template>
<script>
import { abstractField } from 'vue-form-generator'
import { maskValue, handleBeforeAfter } from '@/services/helpers/form-builder'

export default {
  mixins: [abstractField],
  data: () => ({
    mask: null
  }),
  created() {
    this.mask = maskValue(
      this.value,
      this.schema.dataType,
      this.schema.values,
      this.schema.object_property
    )
  },
  computed: {
    labelHandler() {
      return this.schema.showLabel ? this.schema.flabel : ''
    }
  },
  methods: {
    handleBeforeAfter(value) {
      return handleBeforeAfter(value, this.model)
    }
  }
}
</script>

import { i18n, loadLanguageAsync } from '@/plugins/i18n'
import { useAppStore } from '@/stores/app'
import { usePageStore } from '../stores/pages'
import { useUserStore } from '@/stores/user'
import { useMenuStore } from '@/stores/menus'
import { generic, getClientLogo } from '@/services/utilities'
import { resetRouter } from '@/router'

const loadSteps = route => {
  const params = {
    portal_route: '/register'
  }
  return generic('register-form-list', params)
}

export default async function register(to, from, next) {
  const userStore = useUserStore()
  const appStore = useAppStore()
  const pageStore = usePageStore()
  const menusStore = useMenuStore()

  userStore.logout()
  appStore.$reset()
  menusStore.$reset()
  pageStore.$reset()

  await resetRouter

  appStore.$patch({
    clientKey: to.params.key
  })

  const clientLogoRes = await getClientLogo(to.params.key)

  appStore.$patch({
    languages: clientLogoRes.data.languages
  })

  const language = to.params.language || 'en'

  appStore.$patch({
    selectedLanguage: language
  })

  userStore.$patch({
    language
  })

  i18n.locale = language

  loadLanguageAsync(language, true)

  const steps = await loadSteps(to)

  const forms = Object.values(steps.content.forms).map(step => ({
    name: step.name,
    slug: step.slug,
    submit: parseInt(step.sequence) === 0,
    type: step.type
  }))

  pageStore.$patch({
    pages: [],
    appNotifications: [],
    page: {
      label: steps.content.label,
      classes: {
        container: 'blind-returns-backgroud',
        sectionContainer: 'blind-returns-container'
      },
      to: '/register/:key/:language?',
      title: steps.content.title,
      menu: {},
      modules: [
        {
          type: 'form',
          flow: steps.content.modules[0].flow,
          submit_flow: steps.content.modules[0].submit_flow,
          props: steps.content.modules[0].props,
          forms
        }
      ],
      tabs: [],
      buttons: [],
      features: [],
      notifications: []
    }
  })

  next()
}

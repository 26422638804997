import multiguard from 'vue-router-multiguard'
import { storeToRefs } from 'pinia'
import { usePageStore } from '@/stores/pages'
import auth from '@/middlewares/auth.js'
import setPage from '@/middlewares/setPage.js'
import router from '@/router'

const addDynamicRoutes = () => {
  const pageStore = usePageStore()
  const { pages } = storeToRefs(pageStore)

  const routes = router.getRoutes().map(route => route.path)

  const missingRoutes = Object.values(pages.value).filter(
    page => !routes.includes(page.to)
  )

  // add routes from pages array
  missingRoutes.forEach(page => {
    if (page.to === '') return

    router.addRoute({
      path: page.to.replace(/^(?!\/)/, '/'), // adds a leading "/" if it's missing
      name: page.to.replace('/', '.'),
      component: () => import('../../views/Dynamic/View.vue'),
      beforeEnter: multiguard([auth, setPage]),
      meta: {
        layout: 'app',
        dynamic: true
      }
    })
  })

  if (routes.includes('*')) return

  // after adding all the routes from pages array, add a 404 (this needs to be the last route)
  router.addRoute({
    path: '*',
    name: 'not-found',
    component: () => import('../../views/NotFound.vue'),
    meta: {
      layout: 'simple'
    },
    beforeEnter: multiguard([auth])
  })
}

export { addDynamicRoutes }

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('validation-provider',{attrs:{"name":_vm.schema.flabel,"rules":_vm.schema.validatorCommon,"vid":_vm.schema.model},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0] },"message":errors[0]}},[_c('template',{slot:"label"},[_c('span',{style:(_vm.schema.hasOwnProperty('properties') &&
            _vm.schema.properties.hasOwnProperty('css')
              ? _vm.schema.properties.css
              : '')},[(
              _vm.schema.hasOwnProperty('properties') &&
              _vm.schema.properties.hasOwnProperty('icon')
            )?_c('b-icon',{style:(_vm.schema.hasOwnProperty('properties') &&
              _vm.schema.properties.hasOwnProperty('icon_color')
                ? 'color:' + _vm.schema.properties['icon_color']
                : ''),attrs:{"icon":_vm.schema.properties['icon'],"size":_vm.schema.hasOwnProperty('properties') &&
              _vm.schema.properties.hasOwnProperty('icon_size')
                ? 'is-' + _vm.schema.properties['icon_size']
                : 'is-small'}}):_vm._e(),_vm._v(" "+_vm._s(_vm.labelHandler)+" ")],1),(_vm.checkRequired)?_c('span',{staticClass:"has-text-danger"},[_vm._v("*")]):_vm._e()]),_c('b-field',[(_vm.schema.showPreviousValue)?_c('p',{staticClass:"control previous-value-control"},[_c('b-button',{attrs:{"label":String(_vm.oldValue),"disabled":"","expanded":""}})],1):_vm._e(),_c('b-datepicker',{ref:`datepicker-${_vm.schema.id}`,attrs:{"expanded":"","size":"is-small","nearby-selectable-month-days":true,"disabled":_vm.disabled,"date-formatter":_vm.formatterFunction,"placeholder":_vm.schema.placeholder,"icon":"calendar-today","trap-focus":"","editable":_vm.editable,"data-cy":_vm.schema.hasOwnProperty('properties') &&
            _vm.schema.properties.hasOwnProperty('data-cy')
              ? _vm.schema.properties['data-cy']
              : ''},model:{value:(_vm.dateValue),callback:function ($$v) {_vm.dateValue=$$v},expression:"dateValue"}},[_c('b-button',{attrs:{"type":"is-danger","icon-left":"close","outlined":""},on:{"click":function($event){_vm.dateValue = null}}},[_vm._v(" "+_vm._s(_vm.$t('clear'))+" ")])],1)],1)],2)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"order-initial-setup"},[_c('div',{staticClass:"order-header-information"},[_c('div',{staticClass:"top-header-information"},[_c('div',{staticClass:"columns"},_vm._l((_vm.schema.childs),function(child){return _c('div',{key:child.id,class:{
            column:
              child.hasOwnProperty('properties') &&
              child.properties.hasOwnProperty('position') &&
              child.properties.position === 'top'
          }},[_c('field-header-property',{attrs:{"prop":child,"model":_vm.model,"position":"top"}})],1)}),0)]),_c('div',{staticClass:"bottom-header-information"},[_c('div',{staticClass:"columns"},_vm._l((_vm.schema.childs),function(child){return _c('div',{key:child.id,class:{
            column:
              child.hasOwnProperty('properties') &&
              child.properties.hasOwnProperty('position') &&
              child.properties.position === 'bottom'
          }},[_c('field-header-property',{attrs:{"prop":child,"model":_vm.model,"position":"bottom"}})],1)}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
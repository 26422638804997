<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ transition.name }}</p>
      <span v-if="checkIfDocumentExists()">
        <button
          class="ui button toggle is-hidden-mobile"
          type="button"
          @click="isActive = !isActive"
          data-cy="file-compare-button"
        >
          {{ $t('compare') }}
        </button>
      </span>
    </header>
    <validation-observer ref="form" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)">
        <section class="modal-card-body">
          <vue-form-generator
            :schema="schema"
            :model="model"
          ></vue-form-generator>
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="$emit('close')">
            {{ $t('button-close') }}
          </button>
          <b-button type="is-primary" native-type="submit" :loading="loading">
            {{ $t('button-submit') }}
          </b-button>
        </footer>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'

export default {
  props: ['schema', 'model', 'transition'],
  components: { ValidationObserver },
  data() {
    return {
      loading: false,
      isActive: false
    }
  },
  methods: {
    submit() {
      this.loading = true
      this.$emit('transitionFormSubmit', this.model)
    },
    checkIfDocumentExists() {
      if (this.model.hasOwnProperty('Order')) {
        if (this.model.Order.hasOwnProperty('documents')) {
          if (this.model.Order.documents.length > 0) {
            return true
          }
        }
      }

      if (this.model.hasOwnProperty('OrderItem')) {
        if (this.model.OrderItem.hasOwnProperty('item_documents')) {
          if (this.model.OrderItem.item_documents.length > 0) {
            return true
          }
        }
      }

      return false
    }
  },
  watch: {
    isActive(newValue) {
      this.$emit('TogglePdf', newValue)
      this.$emit('fileType', '')
    }
  }
}
</script>

<style scoped>
.modal-card-body {
  padding-bottom: 110px !important;
}
</style>

<template>
  <validation-provider
    :name="schema.flabel"
    :rules="schema.validatorCommon"
    v-slot="{ errors }"
    :vid="schema.model"
  >
    <b-field
      class="custom-field"
      :type="{ 'is-danger': errors[0] }"
      :message="errors[0]"
    >
      <template slot="label">
        <span>
          {{ labelHandler }}
        </span>
        <span class="has-text-danger" v-if="checkRequired">*</span>
      </template>

      <b-input
        :placeholder="schema.placeholder"
        v-model="value"
        :maxlength="fieldLength"
        :disabled="disabled"
        type="textarea"
        :data-cy="
          schema.hasOwnProperty('properties') &&
          schema.properties.hasOwnProperty('data-cy')
            ? schema.properties['data-cy']
            : ''
        "
      >
      </b-input>
    </b-field>
  </validation-provider>
</template>
<script>
import { abstractField } from 'vue-form-generator'
import { ValidationProvider } from 'vee-validate'

export default {
  mixins: [abstractField],
  components: {
    ValidationProvider
  },
  computed: {
    labelHandler() {
      return this.schema.showLabel ? this.schema.flabel : ''
    },
    checkRequired() {
      const validator = this.schema.validatorCommon
      if (validator) {
        const validatorSplit = validator.split('|')
        if (validatorSplit.includes('required')) {
          // contains required
          return true
        }
      }
      return false
    },
    fieldLength() {
      const validator = this.schema.validatorCommon
      if (validator) {
        const validatorSplit = validator.split('|')
        const max = validatorSplit.filter(s => s.includes('max:'))
        if (Object.keys(max).length > 0) {
          const maxChar = max[0].split(':')
          return maxChar[1]
        }
      }
      return 100
    }
  }
}
</script>

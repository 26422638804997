export default {
  moduleLink: 'list',
  init(view) {
    view.api({
      module: this,
      method: 'post',
      endPoint: '/get-open-exception-header',
      body: {},
      callback: res => {
        view.elements.OpenException[0].options = Object.keys(res.Reasons).map(
          k => ({
            value: k,
            label: res.Reasons[k]
          })
        )
        view.elements.OpenException[1].options = Object.keys(res.Periods).map(
          k => ({
            value: k,
            label: res.Periods[k]
          })
        )
      }
    })
  },
  handles(view) {
    return {
      onSubmit: _this => {
        const bodyParams = {
          period_id: view.model[this.name].open_exception_period.value,
          extra_days: view.model[this.name].open_exception_days,
          declaration_open_exception_reason_id:
            view.model[this.name].open_exception_reason.value,
          companies: view.params[this.moduleLink].checkedRows
        }
        view.api({
          module: this,
          method: 'post',
          endPoint: '/save-declaration-open-exception',
          body: { ...bodyParams },
          callback: res => {
            if (res.status === 'success') {
              view.toast({
                type: 'is-success',
                message: view.$i18n.t('open-exception-success-message'),
                duration: 3000
              })
            } else {
              view.toast({
                type: 'is-danger',
                message: view.$i18n.t('open-exception-error-message'),
                duration: 3000
              })
            }

            view.$router.push('/declaration-open-exception')
          }
        })
      },
      formValidation: () => {
        if (
          !view.model[this.name].open_exception_period ||
          !view.model[this.name].open_exception_days ||
          !view.model[this.name].open_exception_reason ||
          !view.params[this.moduleLink].checkedRows.length ||
          view.model[this.name].open_exception_days < 0
        ) {
          return true
        }
        return false
      }
    }
  },
  elements(view) {
    return [
      {
        element: 'select',
        name: 'open_exception_reason',
        component: 'multiselect',
        'track-by': 'value',
        label: 'label',
        description: view.$i18n.t('open-exception-reason'),
        placeholder: view.$i18n.t('open-exception-reason-placeholder'),
        searchable: true,
        class: 'mb-3',
        showLabels: false,
        options: []
      },
      {
        element: 'select',
        name: 'open_exception_period',
        component: 'multiselect',
        'track-by': 'value',
        label: 'label',
        searchable: true,
        class: 'mb-3',
        showLabels: false,
        description: view.$i18n.t('open-exception-period'),
        placeholder: view.$i18n.t('open-exception-period-placeholder'),
        options: [],
        onChange: option => {
          view.api({
            module: this,
            method: 'post',
            endPoint: '/get-open-exception-header',
            body: { period_id: option.value },
            callback: res => {
              view.params[this.moduleLink].data = res.Companies
            }
          })
        }
      },
      {
        name: 'open_exception_days',
        element: 'input',
        component: 'b-input',
        type: 'number',
        placeholder: view.$i18n.t('open-exception-extra-days-placeholder'),
        description: view.$i18n.t('open-exception-extra-days')
      }
    ]
  }
}

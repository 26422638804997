<template>
  <div>
    <img
      class="logo-login"
      :src="`data:image/png;base64,${schema.default}`"
      alt=""
    />
  </div>
</template>
<script>
import { abstractField } from 'vue-form-generator'

export default {
  mixins: [abstractField]
}
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('validation-provider',{attrs:{"name":_vm.schema.flabel,"rules":_vm.schema.validatorCommon,"vid":_vm.schema.model},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{attrs:{"type":"hidden"},domProps:{"value":_vm.value}}),_c('b-field',{staticClass:"custom-field",attrs:{"type":{ 'is-danger': errors[0] },"message":errors[0]}},[_c('template',{slot:"label"},[_c('span',{style:(_vm.schema.hasOwnProperty('properties') &&
          _vm.schema.properties.hasOwnProperty('css')
            ? _vm.schema.properties.css
            : '')},[(
            _vm.schema.hasOwnProperty('properties') &&
            _vm.schema.properties.hasOwnProperty('icon') &&
            _vm.schema.properties['icon'] !== ''
          )?_c('b-icon',{style:(_vm.schema.hasOwnProperty('properties') &&
            _vm.schema.properties.hasOwnProperty('icon_color')
              ? 'color:' + _vm.schema.properties['icon_color']
              : ''),attrs:{"icon":_vm.schema.properties['icon'],"size":_vm.schema.hasOwnProperty('properties') &&
            _vm.schema.properties.hasOwnProperty('icon_size')
              ? 'is-' + _vm.schema.properties['icon_size']
              : 'is-small'}}):_vm._e(),_vm._v(" "+_vm._s(_vm.labelHandler)+" ")],1),(_vm.checkRequired)?_c('span',{staticClass:"has-text-danger"},[_vm._v("*")]):_vm._e()]),_c('b-field',{attrs:{"selected":(_vm.selected = _vm.schema.values.find(v => v.value == _vm.value) || {})}},[(_vm.schema.showPreviousValue)?_c('p',{staticClass:"control previous-value-control"},[_c('b-tooltip',{attrs:{"label":String(_vm.oldValue),"type":"is-dark","multilined":true}},[_c('b-button',{attrs:{"label":String(_vm.oldValue),"disabled":"","expanded":""}})],1)],1):_vm._e(),_c('div',{staticClass:"input-select",attrs:{"text":_vm.selected.text || '',"value":_vm.value,"data-cy":_vm.schema.hasOwnProperty('properties') &&
          _vm.schema.properties.hasOwnProperty('data-cy')
            ? _vm.schema.properties['data-cy']
            : ''}},[_c('multiselect',{attrs:{"value":_vm.schema.values.find(v => v.value == _vm.value),"options":_vm.options,"placeholder":_vm.value ? _vm.selected.text : _vm.schema.placeholder,"disabled":_vm.disabledHandler,"searchable":true,"showLabels":false,"internal-search":false,"showNoResults":true,"maxHeight":120,"label":"text","track-by":"value"},on:{"input":_vm.onInput,"search-change":_vm.search}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v(_vm._s(_vm.$t('no-results')))])])],1)])],2)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }
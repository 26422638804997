import inRange from 'lodash/inRange'
import { storeToRefs } from 'pinia'
import { useAppStore } from '@/stores/app'

const validOperators = ['>', '<', '>=', '<=', '~']

const formatNumber = number => {
  const appStore = useAppStore()
  const { numberFormat } = storeToRefs(appStore)
  let leadingDelimiter = ''
  const numberFormatFormula = numberFormat.value.split('||')
  leadingDelimiter = numberFormatFormula[0]

  let numberFormatter = ''

  number = number.toString()

  if (leadingDelimiter === '.') {
    number = number.replace('.', '')
    numberFormatter = number.replace(/,/g, '.')
  } else if (leadingDelimiter === ',') {
    numberFormatter = number.replace(/,/g, '')
  } else {
    numberFormatter = number.replace(/,/g, '.')
  }

  numberFormatter = parseFloat(numberFormatter)

  return numberFormatter
}

const compare = (post, operator, value) => {
  switch (operator) {
    case '>':
      return post > value
    case '<':
      return post < value
    case '>=':
      return post >= value
    case '<=':
      return post <= value
    case '==':
      return post === value
    case '!=':
      return post !== value
    case '~':
      return Math.abs(post) >= value
  }
}

const diffHandler = (diffCap, value, target) => {
  const [diffMatches, diffCapOperator, diffCapValue, diffUnit] =
    diffCap.match(/^([^\d]+)(\d+)(.*)$/)

  const diffCapIsPercentage = diffUnit === '%' // every other possibility is considered units

  if (!validOperators.includes(diffCapOperator))
    throw new Error('compare_rule: Invalid operator')

  const valueTargetDiff = formatNumber(value) - formatNumber(target)

  const compareValue = diffCapIsPercentage
    ? Math.round((valueTargetDiff * 100) / formatNumber(target))
    : valueTargetDiff

  const result = compare(
    compareValue,
    diffCapOperator,
    formatNumber(diffCapValue)
  )

  return result
}

export default (value, { target, valueCap, diffCap }) => {
  const valueCapIsRange = /^\d+-\d+$/.test(valueCap) // matches <number>-<number>

  if (valueCapIsRange) {
    const [valueCapMinRange, valueCapMaxRange] = valueCap.split('-')

    const valueInRange = inRange(
      formatNumber(value),
      parseFloat(valueCapMinRange),
      parseFloat(valueCapMaxRange)
    )

    if (!valueInRange) {
      return true
    }

    const diffHandlerRes = diffHandler(diffCap, value, target || 0)

    if (diffHandlerRes === true) return false

    return true
  }

  const [valueMatches, valueCapOperator, valueCapValue, valueUnit] =
    valueCap.match(/^([^\d]+)(\d+)(.*)$/)

  if (!validOperators.includes(valueCapOperator))
    throw new Error('compare_rule: Invalid operator')

  const valueCapResult = compare(
    formatNumber(value),
    valueCapOperator,
    formatNumber(valueCapValue)
  )

  if (valueCapResult === false) return true

  const diffHandlerRes = diffHandler(diffCap, value, target)

  if (diffHandlerRes === true) return false

  return true
}

import { storeToRefs } from 'pinia'
import { ToastProgrammatic as Toast } from 'buefy'
import { _localStorage } from '@/services'
import { usePageStore } from '../stores/pages'
import { api } from '@/services/api'
import { i18n } from '@/plugins/i18n'

export default async function setPage(to, from, next) {
  const pageStore = usePageStore()
  const { pages, page, stack } = storeToRefs(pageStore)

  page.value =
    pages.value && (pages.value[to.path] || pages.value[to.matched[0].path])

  const isTransitionPage = page.value && page.value.is_transition_page

  if (isTransitionPage && page.value.transition_model !== '') {
    const possibleTransitionsResponse = await api.post(
      `${page.value.transition_model}/transitions`,
      to.params
    )

    if (!possibleTransitionsResponse.data) return next(from.fullPath)

    const possibleTransitions =
      possibleTransitionsResponse.data.transitions.map(
        transition => transition.params.transition
      )

    if (!possibleTransitions.includes(to.params.transition)) {
      Toast.open({
        type: 'is-danger',
        duration: 4000,
        message: i18n.t('transition-not-allowed', {
          transition: to.params.transition
        })
      })

      return next(from.fullPath)
    }
  }

  stack.value =
    page.value && page.value.menu
      ? [page.value.menu.parent_label, page.value.label]
      : []

  next()
}

import { storeToRefs } from 'pinia'
import { usePageStore } from '@/stores/pages'
/**
 * @param {*} response
 */

export default function handleListForm(
  fields,
  dataset,
  totalResults,
  formName
) {
  const list = {}
  let columns = []

  list.dataset = dataset
  list.total_results = totalResults
  list.form_name = formName

  const pageStore = usePageStore()
  const { viewManager, currentFormId } = storeToRefs(pageStore)

  if (
    viewManager.value &&
    viewManager.value[currentFormId.value] &&
    viewManager.value[currentFormId.value].selected
  ) {
    const selected = viewManager.value[currentFormId.value].selected
    columns = viewManager.value[currentFormId.value].saved[selected].columns

    list.columns = columns

    return list
  }

  for (const column in fields) {
    if (fields[column].hasOwnProperty('id')) {
      if (fields[column].field_name === 'actions') {
        columns.push({
          field: fields[column].field_name,
          title: fields[column].field_label,
          label: fields[column].field_label,
          renderHtml: true,
          visible: true
        })
      } else {
        if (fields[column].field_type === 'hidden') {
          continue
        }
        columns.push({
          id: fields[column].id,
          field: fields[column].field_name,
          type: fields[column].field_type,
          default_value: fields[column].field_default_value,
          title: fields[column].field_label,
          label: fields[column].field_label,
          placeholder: fields[column].field_placeholder,
          sortable: fields[column].is_sortable > 0,
          searchable: fields[column].is_searchable > 0,
          centered: fields[column].field_type === 'boolean',
          visible: true
        })
      }
    }
  }

  list.columns = columns

  // console.log('list', list)

  return list
}

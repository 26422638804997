import { defineStore } from 'pinia'

export const useLayoutStore = defineStore('layout', {
  state: () => {
    return {
      isNavBarVisible: false,
      isFooterBarVisible: false,
      isChunkModelUploaderVisible: true,
      isAsideVisible: false,
      asideMobileStateToggle: false,
      isAsideMobileExpanded: false,
      isMenuNavBarActive: false,
      selectedRow: { id: null, form: null }
    }
  },
  actions: {
    addSelectedRow(id, form) {
      this.selectedRow = { id, form }
    },
    resetSelectedRow(form, paramsId) {
      // no paramsId means this route is not from a list action
      if (!paramsId && this.selectedRow.form !== form) {
        this.selectedRow = { id: null, form: null }
      }
    }
  },
  getters: {
    getSelectedRow: state => state.selectedRow
  },
  persist: true
})

/*
  Middlewares for Auth
*/

import { storeToRefs } from 'pinia'
import { _localStorage } from '@/services'
import { useUserStore } from '../stores/user'
import { useAppStore } from '../stores/app'

export default function isAuthenticated(to, from, next) {
  const userStore = useUserStore()
  const appStore = useAppStore()
  const { token } = storeToRefs(userStore)
  const { clientKey } = storeToRefs(appStore)

  const hasToken = token.value && token.value !== null
  const hasClientKey =
    clientKey.value !== null && typeof clientKey.value === 'string'

  if (!hasToken) {
    const location = hasClientKey
      ? `/login/${clientKey.value}`
      : (window.location.href = '/about.html')

    next(location)
  }

  next()
}

<template>
  <div>
    <div class="list-actions">
      <div v-if="actions" class="list-actions-links">
        <router-link
          class="button is-small is-primary rounded-icons"
          v-for="(link, index) in actions.links"
          :key="index"
          :to="link"
          :data-cy="
            link.includes('edit')
              ? 'edit_' + currentFormId + '_' + id
              : 'view_' + currentFormId + '_' + id
          "
        >
          <span
            class="icon is-small"
            :title="link.includes('edit') ? $t('edit') : $t('view')"
            ><i
              :class="link.includes('edit') ? 'mdi mdi-pencil' : 'mdi mdi-eye'"
            ></i>
          </span>
        </router-link>
        <a
          v-if="hasBusinessActivity && !hasAbstractRelation"
          class="button is-small is-primary rounded-icons"
          @click.prevent="changeBusinessActivityTab()"
          :data-cy="'edit_' + currentFormId + '_' + id"
        >
          <b-icon
            size="is-small"
            title="Edit Business Activity"
            icon="pencil"
          />
        </a>
        <a
          v-if="hasBusinessActivity && hasAbstractRelation"
          class="button is-small is-primary rounded-icons"
          @click.prevent="changeBusinessActivityTab()"
          :data-cy="'edit_' + currentFormId + '_' + id"
        >
          <b-icon size="is-small" title="Edit" icon="pencil" />
        </a>
        <a
          v-if="hasBusinessActivity && !hasAbstractRelation"
          class="button is-small is-danger rounded-icons"
          @click.prevent="isCardModalConfirmBusinessActive = true"
          :data-cy="'delete_' + currentFormId + '_' + id"
        >
          <b-icon
            size="is-small"
            title="Delete Business Activity"
            icon="delete"
          />
        </a>
        <a
          v-if="hasBusinessActivity && hasAbstractRelation"
          class="button is-small is-danger rounded-icons"
          @click.prevent="isCardModalConfirmAbstractActive = true"
          :data-cy="'delete_' + currentFormId + '_' + id"
        >
          <b-icon size="is-small" title="Delete" icon="delete" />
        </a>
        <a
          v-if="hasAbstractPageRelation"
          class="button is-small is-danger rounded-icons"
          @click.prevent="isCardModalConfirmAbstractRelationActive = true"
          :data-cy="'delete_' + currentFormId + '_' + id"
        >
          <b-icon size="is-small" title="Delete" icon="delete" />
        </a>
        <a
          v-if="hasDetailsList"
          class="button is-small is-primary rounded-icons"
          @click.prevent="
            callListForm(
              'list-form-order-item-details',
              'list-form-order-item-details'
            )
          "
          :data-cy="'detail_' + currentFormId + '_' + id"
        >
          <b-icon
            size="is-small"
            title="Order Item Details List"
            icon="playlist-plus"
          />
        </a>
        <!-- TODO: replace all flags (hasDetailsList, hasRouteModal, ...) with an array of links in the parent component and iterate the <a> element -->
        <a
          v-if="hasRouteModal && $checkFeature('uses-complaints-list', 'v1')"
          class="button is-small is-primary rounded-icons"
          @click.prevent="
            callListForm(
              'list-order-items-complaints',
              'list-order-items-complaints'
            )
          "
          :data-cy="'complaints_list_' + currentFormId + '_' + id"
        >
          <b-icon
            size="is-small"
            title="Order Item Complaints List"
            icon="comment-remove"
          />
        </a>
      </div>
      <div
        v-if="!$checkFeature('uses-state-machine', 'v1')"
        class="list-actions-links"
      >
        <a
          v-if="status !== 12 && hasRouteModal"
          class="button is-small is-primary rounded-icons"
          @click.prevent="assignRouteModal(row, true)"
          :data-cy="'assign_route_' + currentFormId + '_' + id"
        >
          <b-icon
            size="is-small"
            :title="$t('order-title-assign_route')"
            icon="truck-fast"
          />
        </a>
        <a
          v-if="hasRouteModal"
          class="button is-small is-light rounded-icons"
          @click.prevent="assignRouteModal(row, true, true)"
          :data-cy="'view_' + currentFormId + '_' + id"
        >
          <b-icon size="is-small" title="View" icon="eye" />
        </a>
      </div>
      <div v-if="hasRoutingMatrix || hasPriceMatrix" class="list-actions-links">
        <a
          class="button is-small is-primary rounded-icons"
          @click="
            goTo(
              hasRoutingMatrix
                ? 'routing.matrix.duplicate'
                : 'price.matrix.duplicate',
              { id: id }
            )
          "
          :data-cy="'duplicate_' + currentFormId + '_' + id"
        >
          <b-icon
            size="is-small"
            :title="$t('duplicate')"
            icon="arrange-bring-forward"
          />
        </a>
        <a
          v-if="row.deleted_at == null"
          class="button is-small is-danger rounded-icons"
          @click.prevent="isCardModalConfirmActive = true"
          :data-cy="'delete_' + currentFormId + '_' + id"
        >
          <b-icon size="is-small" :title="$t('delete')" icon="delete" />
        </a>
      </div>
      <div v-if="hasSmFlowMatrix" class="list-actions-links">
        <a
          class="button is-small is-primary rounded-icons"
          @click="goTo('sm-flow.matrix.duplicate', { id: id })"
          :data-cy="'duplicate_' + currentFormId + '_' + id"
        >
          <b-icon
            size="is-small"
            :title="$t('duplicate')"
            icon="arrange-bring-forward"
          />
        </a>
        <a
          v-if="row.deleted_at == null"
          class="button is-small is-danger rounded-icons"
          @click.prevent="isCardModalConfirmSMActive = true"
          :data-cy="'delete_' + currentFormId + '_' + id"
        >
          <b-icon size="is-small" :title="$t('delete')" icon="delete" />
        </a>
      </div>
      <div v-if="hasContractMatrix" class="list-actions-links">
        <a
          class="button is-small is-primary rounded-icons"
          @click="goTo('contract.matrix.duplicate', { id: id })"
          :data-cy="'duplicate_' + currentFormId + '_' + id"
        >
          <b-icon
            size="is-small"
            :title="$t('duplicate')"
            icon="arrange-bring-forward"
          />
        </a>
        <a
          v-if="row.deleted_at == null"
          class="button is-small is-danger rounded-icons"
          @click.prevent="isCardModalConfirmCMActive = true"
          :data-cy="'delete_' + currentFormId + '_' + id"
        >
          <b-icon size="is-small" :title="$t('delete')" icon="delete" />
        </a>
      </div>
      <div
        v-if="hasStateMachine && $checkFeature('uses-state-machine', 'v1')"
        @mouseenter="loadTransitions"
      >
        <b-tooltip
          type="is-dark"
          position="is-top"
          class="control"
          :label="$t('bulk-transitions-single-transition-helper')"
          :active="checkable"
        >
          <b-dropdown
            position="is-bottom-left"
            append-to-body
            aria-role="list"
            :disabled="checkable"
          >
            <button
              class="button is-primary is-small rounded-icons"
              slot="trigger"
            >
              <b-icon icon="dots-horizontal"></b-icon>
            </button>
            <div v-if="!isLoadingTransitions">
              <b-dropdown-item
                v-for="(transition, index) in transitions"
                :key="index"
                aria-role="listitem"
                @click="openTransitionConfirm(transition)"
              >
                {{ transition.name }}
              </b-dropdown-item>
              <b-dropdown-item v-if="!transitions.length">
                No transitions available.
              </b-dropdown-item>
            </div>
            <b-dropdown-item
              v-if="isLoadingTransitions"
              class="expanded-dropdown-item"
            >
              <b-loading
                :active.sync="isLoadingTransitions"
                :is-full-page="false"
              ></b-loading>
            </b-dropdown-item>
            <div
              class="border-top"
              v-if="currentModel && $checkFeature('list-invoice-popup', 'v1')"
            >
              <b-dropdown-item
                @click="
                  callOrderInvoiceListForm(
                    'get-order-invoices',
                    'order-invoices-list',
                    currentModel
                  )
                "
                :data-cy="'orders_invoice_' + currentFormId + '_' + id"
              >
                <span>{{ $t('order-invoices') }}</span>
              </b-dropdown-item>
            </div>
            <div
              class="border-top"
              v-if="
                orderItemTransitionsHistory &&
                $checkFeature('list-order-item-transition-history', 'v1')
              "
            >
              <b-dropdown-item
                @click="OrderStateTransitions('order-item-transition-history')"
                :data-cy="
                  'order-item-transition-history_' + currentFormId + '_' + id
                "
              >
                <span>{{ $t('order-item-transition-history') }}</span>
              </b-dropdown-item>
            </div>
          </b-dropdown>
        </b-tooltip>
      </div>
      <div v-if="actions">
        <b-dropdown
          position="is-bottom-left"
          append-to-body
          aria-role="list"
          v-if="actions.action_list && actions.action_list.length"
        >
          <button
            class="button is-primary is-small rounded-icons"
            slot="trigger"
          >
            <b-icon icon="dots-horizontal"></b-icon>
          </button>
          <div
            v-if="actions && $checkFeature('companies-list-actions-view', 'v1')"
          >
            <div v-if="actions.action_list">
              <b-dropdown-item
                v-for="(action, index) in actions.action_list"
                :key="index"
                aria-role="listitem"
              >
                <router-link tag="div" :to="action.params.link">
                  <span @click="openActionForm(action)">{{ action.name }}</span>
                </router-link>
              </b-dropdown-item>
            </div>
          </div>
        </b-dropdown>
      </div>
      <div>
        <a
          v-if="hasDownloadInvoice"
          class="button is-small is-primary rounded-icons"
          @click="downloadDocument"
          :data-cy="'download_' + currentFormId + '_' + id"
        >
          <b-icon
            size="is-small"
            :title="$t('download')"
            icon="file-download"
          />
        </a>
      </div>
      <div>
        <a
          v-if="hasDownloadCannedReports"
          class="button is-small is-primary rounded-icons"
          @click="generateReports"
          :data-cy="'download_' + currentFormId + '_' + id"
        >
          <b-icon
            size="is-small"
            :title="$t('download')"
            icon="file-download"
          />
        </a>
      </div>
    </div>
    <b-modal
      v-if="isAssignModalActive"
      :active.sync="isAssignModalActive"
      has-modal-card
      aria-role="dialog"
      aria-modal
      custom-class="form-model"
      :class="[PdfIsActive ? 'hasPdf' : '']"
    >
      <form-generator-modal
        :schema="schema"
        :model="model"
        :transition="formTransition"
        @close="isAssignModalActive = false"
        @transitionFormSubmit="transitionFormSubmit"
        @TogglePdf="PdfIsActive = !PdfIsActive"
      ></form-generator-modal>
      <div v-if="PdfIsActive" class="pdf-container">
        <div class="pdf-controllers modal-card-head" v-if="fileType !== 'pdf'">
          <button
            @click="$refs.pdf.print()"
            type="button"
            class="button ui"
            data-cy="file-print-button"
          >
            {{ $t('print') }}
          </button>
          <button
            @click="downloadFile()"
            type="button"
            class="button ui"
            data-cy="file-download-button"
          >
            {{ $t('download') }}
          </button>
          <button
            v-on:click="ZoomIn(10)"
            class="button ui"
            data-cy="file-zoom-in-button"
          >
            <i class="mdi mdi-magnify-plus default"></i>
          </button>
          <button
            v-on:click="ZoomOut(10)"
            class="button ui"
            data-cy="file-zoom-out-button"
          >
            <i class="mdi mdi-magnify-minus default"></i>
          </button>
        </div>
        <div class="img" v-if="fileType !== 'pdf'">
          <img :src="src" alt="name" :style="'width:' + zoom + '%'" />
        </div>
        <div class="pdf" v-if="fileType === 'pdf'">
          <vue-pdf-app
            style="height: calc(100vh - 40px)"
            :pdf="src"
            theme="light"
            :config="config"
          ></vue-pdf-app>
        </div>
      </div>
    </b-modal>
    <b-modal
      :active.sync="isCardModalConfirmBusinessActive"
      :width="400"
      :can-cancel="false"
      scroll="keep"
    >
      <header class="modal-card-head">
        <p class="modal-card-title">{{ $t('delete_title') }}</p>
      </header>
      <section class="modal-card-body">
        {{ $t('delete_question') }}
      </section>
      <footer class="modal-card-foot">
        <button
          class="button"
          type="button"
          @click="isCardModalConfirmBusinessActive = false"
        >
          {{ $t('delete_close') }}
        </button>
        <button
          class="button is-danger"
          @click="
            genericApiCall('company-business-activities-destroy', { id: id })
          "
        >
          {{ $t('delete_confirm') }}
        </button>
      </footer>
    </b-modal>
    <b-modal
      :active.sync="isCardModalConfirmAbstractActive"
      :width="400"
      :can-cancel="false"
      scroll="keep"
    >
      <header class="modal-card-head">
        <p class="modal-card-title">{{ $t('delete_title') }}</p>
      </header>
      <section class="modal-card-body">
        {{ $t('delete_question') }}
      </section>
      <footer class="modal-card-foot">
        <button
          class="button"
          type="button"
          @click="isCardModalConfirmAbstractActive = false"
        >
          {{ $t('delete_close') }}
        </button>
        <button
          class="button is-danger"
          @click="genericApiCall('abstract-activities-destroy', { id: id })"
        >
          {{ $t('delete_confirm') }}
        </button>
      </footer>
    </b-modal>
    <b-modal
      :active.sync="isCardModalConfirmAbstractRelationActive"
      :width="400"
      :can-cancel="false"
      scroll="keep"
    >
      <header class="modal-card-head">
        <p class="modal-card-title">{{ $t('delete_title') }}</p>
      </header>
      <section class="modal-card-body">
        {{ $t('delete_question') }}
      </section>
      <footer class="modal-card-foot">
        <button
          class="button"
          type="button"
          @click="isCardModalConfirmAbstractRelationActive = false"
        >
          {{ $t('delete_close') }}
        </button>
        <button
          class="button is-danger"
          @click="genericApiCall('abstract-destroy', { id: id })"
        >
          {{ $t('delete_confirm') }}
        </button>
      </footer>
    </b-modal>
    <b-modal
      :active.sync="isCardModalConfirmActive"
      :width="400"
      :can-cancel="false"
      scroll="keep"
    >
      <header class="modal-card-head">
        <p class="modal-card-title">{{ $t('delete_title') }}</p>
      </header>
      <section class="modal-card-body">
        {{ $t('delete_question') }}
      </section>
      <footer class="modal-card-foot">
        <button
          class="button"
          type="button"
          @click="isCardModalConfirmActive = false"
        >
          {{ $t('delete_close') }}
        </button>
        <button
          class="button is-danger"
          @click="
            genericApiCall(
              hasRoutingMatrix
                ? 'routing-matrix-delete'
                : 'price-matrix-delete',
              { id: id }
            )
          "
        >
          {{ $t('delete_confirm') }}
        </button>
      </footer>
    </b-modal>
    <b-modal
      :active.sync="isCardModalConfirmSMActive"
      :width="400"
      :can-cancel="false"
      scroll="keep"
    >
      <header class="modal-card-head">
        <p class="modal-card-title">{{ $t('delete_title') }}</p>
      </header>
      <section class="modal-card-body">
        {{ $t('delete_question') }}
      </section>
      <footer class="modal-card-foot">
        <button
          class="button"
          type="button"
          @click="isCardModalConfirmSMActive = false"
        >
          {{ $t('delete_close') }}
        </button>
        <button
          class="button is-danger"
          @click="genericApiCall('sm-flow-matrix-delete', { id: id })"
        >
          {{ $t('delete_confirm') }}
        </button>
      </footer>
    </b-modal>
    <b-modal
      :active.sync="isCardModalConfirmCMActive"
      :width="400"
      :can-cancel="false"
      scroll="keep"
    >
      <header class="modal-card-head">
        <p class="modal-card-title">{{ $t('delete_title') }}</p>
      </header>
      <section class="modal-card-body">
        {{ $t('delete_question') }}
      </section>
      <footer class="modal-card-foot">
        <button
          class="button"
          type="button"
          @click="isCardModalConfirmCMActive = false"
        >
          {{ $t('delete_close') }}
        </button>
        <button
          class="button is-danger"
          @click="genericApiCall('contract-matrix-delete', { id: id })"
        >
          {{ $t('delete_confirm') }}
        </button>
      </footer>
    </b-modal>
    <b-modal
      v-model="isDetailsListActive"
      :active.sync="isDetailsListActive"
      aria-role="dialog"
    >
      <template default="props">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ modalTitle }}</p>
        </header>
        <section class="modal-card-body">
          <list-form
            :paginated="false"
            :stickyHeader="true"
            :data="listFormData"
            :columns="listFormColumns"
            :hasDownloadInvoice="true"
            :isLoading="isLoadingList"
            @close="isDetailsListActive = false"
          >
          </list-form>
        </section>
        <footer class="modal-card-foot"></footer>
      </template>
    </b-modal>
    <b-loading :active.sync="isLoading" :is-full-page="true"> </b-loading>
  </div>
</template>
<script>
import { storeToRefs } from 'pinia'
import moment from 'moment'
import VuePdfApp from 'vue-pdf-app'
import { callTransition as smCallTransition } from '@/services/state-machine'
import { getForm, setCompleteFormModel } from '@/services/form-builder'
import {
  downloadFileNew,
  downloadFilePopup,
  getCompareDocument
} from '@/services/document'
import {
  orderItemTransitionTracking,
  getFileInfo,
  downloadFile
} from '@/services/order'
import { i18n } from '@/plugins/i18n'
import 'vue-pdf-app/dist/icons/main.css'
import { config } from '@/helpers/config'
import { usePageStore } from '../stores/pages'

const mime = require('mime-types')

export default {
  components: { VuePdfApp },
  props: [
    'actions',
    'hasRouteModal',
    'status',
    'row',
    'hasContractMatrix',
    'hasRoutingMatrix',
    'id',
    'hasPriceMatrix',
    'hasDetailsList',
    'hasSmFlowMatrix',
    'hasBusinessActivity',
    'hasAbstractRelation',
    'hasAbstractPageRelation',
    'hasDownloadInvoice',
    'hasDownloadCannedReports',
    'hasStateMachine',
    'checkable',
    'currentModel',
    'orderItemTransitionsHistory',
    'clearTranstionsFlag'
  ],
  setup() {
    const pageStore = usePageStore()
    const { currentFormId, priceMatrixSlug } = storeToRefs(pageStore)

    return { currentFormId, priceMatrixSlug }
  },
  data() {
    return {
      model: {},
      schema: { groups: [] },
      isAssignModalActive: false,
      isCardModalConfirmActive: false,
      isCardModalConfirmSMActive: false,
      isCardModalConfirmCMActive: false,
      isCardModalConfirmBusinessActive: false,
      isCardModalConfirmAbstractActive: false,
      isCardModalConfirmAbstractRelationActive: false,
      formTransition: {},
      isDetailsListActive: false,
      listFormData: [],
      listFormColumns: {},
      modalTitle: '',
      isLoadingList: false,
      transitions: [],
      isLoadingTransitions: true,
      exportExcel: false,
      isLoading: false,
      show: true,
      page: 1,
      numPages: 0,
      loadedRatio: 0,
      existsPdf: true,
      src: '',
      PdfIsActive: false,
      zoom: 100,
      baseURL: `${config.storageUrl}`,
      fileType: '',
      currentdocumentModel: '',
      documents: [],
      config: {
        toolbar: {
          toolbarViewerMiddle: {
            zoomOut: true,
            zoomIn: true,
            scaleSelectContainer: false
          },
          toolbarViewerRight: {
            presentationMode: false,
            openFile: false,
            print: true,
            download: true,
            viewBookmark: false
          },
          sidebar: {
            viewThumbnail: true,
            viewOutline: false,
            viewAttachments: false
          }
        },
        secondaryToolbar: {
          secondaryPresentationMode: false,
          secondaryOpenFile: false,
          secondaryPrint: false,
          secondaryDownload: false,
          secondaryViewBookmark: false,
          firstPage: false,
          lastPage: false,
          pageRotateCw: true,
          pageRotateCcw: true,
          cursorSelectTool: false,
          cursorHandTool: false,
          scrollVertical: false,
          scrollHorizontal: false,
          scrollWrapped: false,
          spreadNone: false,
          spreadOdd: false,
          spreadEven: false,
          documentProperties: false
        }
      }
    }
  },
  methods: {
    changeBusinessActivityTab() {
      this.$emit('changeTab', this.id)
    },
    openTransitionConfirm(transition) {
      if (!transition.params.form_slug) {
        this.$buefy.dialog.confirm({
          title: transition.name,
          message: i18n.t('sm-confirm-flow', { transition: transition.name }),
          confirmText: transition.name,
          cancelText: i18n.t('returns-button-cancel'),
          type: 'is-info',
          hasIcon: true,
          onConfirm: () => this.callTransition(transition)
        })
      } else {
        const flow = transition.params.flow
          ? transition.params.flow
          : 'authenticated-forms'
        this.loadForm(transition.params.form_slug, transition, flow)
      }
    },
    openActionForm(action) {
      this.priceMatrixSlug = action.params
    },
    downloadDocument() {
      this.isLoading = true
      if (this.row.document_builder_id && this.row.document_builder_id !== '') {
        const documentBuilderId = this.row.document_builder_id
        downloadFileNew(
          `invoicing/document-builder/download/${documentBuilderId}`
        ).then(response => {
          this.isLoading = false
          if (response.status === 'error') {
            this.$buefy.toast.open({
              duration: 3000,
              message: this.$t('invoicing-download_error'),
              position: 'is-top',
              type: 'is-danger'
            })
          } else {
            const url = window.URL.createObjectURL(response)
            const a = document.createElement('a')
            a.href = url
            a.download = `${this.row.document_builder_id}.pdf`
            a.click()
            window.URL.revokeObjectURL(url)
          }
        })
      } else {
        alert(
          'Document Builder ID missing. Please add a hidden field to the form.'
        )
      }
    },
    generateReports() {
      if (
        this.row.form_definition &&
        Object.keys(this.row.form_definition).length > 0
      ) {
        this.loadForm(this.row.form_definition.slug, {}, 'authenticated-forms')
        this.exportExcel = true
        return
      }
      this.$buefy.dialog.confirm({
        message: this.$t('canned-reports-generate_confirm_message'),
        onConfirm: () => {
          this.isLoading = true
          downloadFileNew('get-canned-report', this.row).then(response => {
            if (response.status === 'error') {
              this.$buefy.toast.open({
                duration: 3000,
                message: this.$t(response.message),
                position: 'is-top',
                type: 'is-danger'
              })
            } else {
              const url = window.URL.createObjectURL(response)
              const fileName = this.row.title ?? 'Canned Report - '
              const extension =
                mime.extension(response.type) === 'html'
                  ? 'xml'
                  : mime.extension(response.type)

              const a = document.createElement('a')
              const today = moment(Date.now()).format('DD.MM.YYYY HH.mm.ss')
              a.href = url
              a.download = `${fileName} - ${today}.${extension}`

              a.click()
              window.URL.revokeObjectURL(url)
            }
            this.isLoading = false
          })
        }
      })
    },
    callTransition(transition) {
      this.$emit('isLoadingEvent', true)
      smCallTransition(transition).then(() => {
        this.$emit('loadContent')
      })
    },
    loadForm(slug, transition, flow) {
      this.model = {}
      this.$emit('isLoadingEvent', true)
      const id = flow !== 'authenticated-forms' ? this.id : ''
      getForm(flow, slug, id).then(data => {
        this.schema.groups = data.groups
        this.model = { ...data.model }
        this.model.form_slug = slug
        this.formTransition = transition
        this.isAssignModalActive = true
        setCompleteFormModel(this.model)
      })
    },
    submitExportExcel(endpoint) {
      this.isLoading = true

      downloadFileNew(endpoint, this.model).then(response => {
        if (response.status === 'error') {
          this.$buefy.toast.open({
            duration: 3000,
            message: this.$t(response.message),
            position: 'is-top',
            type: 'is-danger'
          })
        } else {
          const url = window.URL.createObjectURL(response)
          const fileName = this.row.title ?? 'Canned Report - '
          const extension =
            mime.extension(response.type) === 'html'
              ? 'xml'
              : mime.extension(response.type)

          const a = document.createElement('a')
          const today = moment(Date.now()).format('DD.MM.YYYY HH.mm.ss')
          a.href = url

          a.download = `${fileName} - ${today}.${extension}`
          a.click()
          window.URL.revokeObjectURL(url)
        }

        this.isLoading = false
      })
    },
    transitionFormSubmit(model) {
      if (!this.exportExcel) {
        this.formTransition.params = Object.assign(
          this.formTransition.params,
          model
        )
        this.callTransition(this.formTransition)
        return
      }
      this.model.hash = this.row.hash
      this.model.title = this.row.title
      this.submitExportExcel('canned-report/calculate')

      this.isAssignModalActive = false
    },
    assignRouteModal(row, single = false, view = false) {
      this.$emit('assignRouteModalEvent', row, single, view)
    },
    goTo(name, params) {
      this.$router.replace({ name, params })
    },
    async genericApiCall(slug, content) {
      this.$emit('isLoadingEvent', true)
      const APIResponse = await this.$api.post(slug, content)

      if (APIResponse.code === 200) {
        if (
          !APIResponse.data.hasOwnProperty('code') &&
          APIResponse.data.code !== 500
        ) {
          if (this.isCardModalConfirmActive === true) {
            this.isCardModalConfirmActive = false
          }
          if (this.isCardModalConfirmSMActive === true) {
            this.isCardModalConfirmSMActive = false
          }
          if (this.isCardModalConfirmCMActive === true) {
            this.isCardModalConfirmCMActive = false
          }
          if (this.isCardModalConfirmBusinessActive === true) {
            this.isCardModalConfirmBusinessActive = false
          }
          if (this.isCardModalConfirmAbstractActive === true) {
            this.isCardModalConfirmAbstractActive = false
          }
          if (this.isCardModalConfirmAbstractRelationActive === true) {
            this.isCardModalConfirmAbstractRelationActive = false
          }
          this.$emit('loadContent')
        }
      }
    },
    callListForm(endpoint, form) {
      this.isDetailsListActive = true
      this.isLoadingList = true

      const params = { order_item_id: this.id }

      this.isLoading = true
      this.listFormColumns = []
      this.listFormData = []

      getForm(endpoint, form, '', this.$i18n.locale, params).then(response => {
        this.listFormColumns = response.columns
        this.listFormData = response.dataset
        this.total = response.total_results
        this.modalTitle = response.form_name

        this.isLoading = false
        this.isLoadingList = false
      })
    },
    callOrderInvoiceListForm(endpoint, form, modelName) {
      this.isDetailsListActive = true
      this.isLoadingList = true

      const params = {
        id: this.id,
        modelName
      }

      this.isLoading = true
      this.listFormColumns = []
      this.listFormData = []

      getForm(endpoint, form, '', this.$i18n.locale, params).then(response => {
        this.listFormColumns = response.columns
        this.listFormData = response.dataset
        this.total = response.total_results
        this.modalTitle = response.form_name

        this.isLoading = false
        this.isLoadingList = false
      })
    },
    ZoomIn(incre) {
      this.zoom += incre
    },
    ZoomOut(incre) {
      this.zoom -= incre
    },
    downloadFile() {
      getFileInfo(
        JSON.stringify({
          document_id: this.documents[this.documents.length - 1].id
        })
      ).then(response => {
        const aux =
          response.data.actions.get_order_document.route_response.content
        const mime =
          response.data.actions.get_order_document_binary.route_response.content
            .mimetype
        downloadFile(aux.location).then(response => {
          const blobAux = new Blob([response.data], { type: mime })
          downloadFilePopup(window.URL.createObjectURL(blobAux), aux.name)
        })
      })
    },
    OrderStateTransitions(endpoint) {
      this.isDetailsListActive = true
      this.isLoadingList = true

      const params = {
        id: this.id
      }

      this.isLoading = true
      this.listFormColumns = []
      this.listFormData = []

      orderItemTransitionTracking(endpoint, params, this.$i18n.locale).then(
        response => {
          this.listFormColumns =
            response.actions.order_item_transition_history.route_response.content.form.fields.map(
              f => ({
                field: f.field_name,
                label: f.field_label,
                renderHtml: true,
                visible: true
              })
            )

          this.listFormData =
            response.actions.order_item_transition_history.route_response.content.dataset
          this.modalTitle =
            response.actions.order_item_transition_history.route_response.content.form.form_name

          this.orderItemTransitionHistory = true

          this.isLoading = false
          this.isLoadingList = false
        }
      )
    },
    async loadTransitions() {
      if (!this.transitions.length) {
        let modelRoute =
          this.$route.name === 'orders.view' ||
          this.$route.name === 'orders.items.all.v1'
            ? 'order-item'
            : 'order'
        if (this.$route.name === 'companies.v1') {
          modelRoute = 'company'
        }
        if (this.$route.name === 'users.v1') {
          modelRoute = 'user'
        }
        await this.$api
          .post(`${modelRoute}/transitions`, { id: this.id })
          .then(response => {
            this.transitions = response.data.transitions
            this.isLoadingTransitions = false
          })
          .catch(err => console.log(err))
      }
    }
  },
  watch: {
    PdfIsActive(newValue) {
      if (newValue === true) {
        if (this.model.hasOwnProperty('Order')) {
          if (this.model.Order.hasOwnProperty('documents')) {
            this.currentdocumentModel = 'Order'
            this.documents = this.model[this.currentdocumentModel].documents
          }
        }
        if (this.model.hasOwnProperty('OrderItem')) {
          if (this.model.OrderItem.hasOwnProperty('item_documents')) {
            this.currentdocumentModel = 'OrderItem'
            this.documents =
              this.model[this.currentdocumentModel].item_documents
          }
        }

        if (this.model['Document.document_type_id']) {
          this.documents = this.documents.filter(
            e =>
              e.document_type_id ===
                parseInt(this.form.model['Document.document_type_id']) &&
              (e.mime_type === 'image/jpeg' ||
                e.mime_type === 'image/png' ||
                e.mime_type === 'application/pdf')
          )
        }

        let documentModel = this.model[this.currentdocumentModel].documents
          ? this.model[this.currentdocumentModel].documents[
              this.model[this.currentdocumentModel].documents.length - 1
            ].id
          : ''

        if (this.currentdocumentModel === 'OrderItem')
          documentModel = this.model[this.currentdocumentModel].item_documents
            ? this.model[this.currentdocumentModel].item_documents[
                this.model[this.currentdocumentModel].item_documents.length - 1
              ].id
            : ''

        getFileInfo(
          JSON.stringify({
            document_id: documentModel
          })
        ).then(response => {
          const aux =
            response.data.actions.get_order_document.route_response.content
          const mime =
            response.data.actions.get_order_document_binary.route_response
              .content.mimetype
          if (mime === 'application/pdf') {
            this.fileType = 'pdf'
          }
          getCompareDocument(aux.location).then(response => {
            this.src = `data:${mime};base64,${response.data}`
          })
        })
      }
    },
    isAssignModalActive(value) {
      if (value === false) {
        this.PdfIsActive = false
      }
    },
    clearTranstionsFlag() {
      if (this.transitions.length) {
        this.transitions = []
      }
    }
  }
}
</script>
<style>
.border-top {
  border-top: 1px solid rgba(24, 28, 33, 0.15);
}

.img img {
  max-width: unset;
  transition: all 0.5s linear;
  padding: 20px;
  margin-top: 80px;
}
.pdf span {
  transition: all 0.5s linear;
}
.modal.hasPdf .animation-content {
  display: flex;
  margin-right: 20px;
  overflow: hidden;
  position: relative;
}

.pdf-app #mainContainer {
  bottom: 50px;
}
</style>

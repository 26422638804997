import { defineStore } from 'pinia'

export const useListFiltersStore = formSlug =>
  defineStore(
    `listFilters/${(formSlug || 'generic-filters').replace(' ', '-')}`,
    {
      state: () => {
        return {
          filters: [],
          columns: [],
          hiddenColumns: {},
          selectedFilter: null
        }
      },
      getters: {
        getActiveFilter: state =>
          state.filters.filter(({ filter }) => filter === state.selectedFilter)
      },
      actions: {},
      persist: {
        paths: ['selectedFilter', 'filters', 'hiddenColumns']
      }
    }
  )()

import { defineStore } from 'pinia'

export const useUserSettingsStore = defineStore('userSettings', {
  state: () => {
    return {
      pagination: 25,
      filters: {}
    }
  },
  getters: {
    getFilter: state => {
      return key => state.filters[key]
    }
  },
  persist: true
})

<template>
  <div>
    <p
      class="text-left"
      :style="
        schema.hasOwnProperty('properties') &&
        schema.properties.hasOwnProperty('css')
          ? schema.properties.css
          : ''
      "
    >
      {{ schema.flabel }}
    </p>
  </div>
</template>
<script>
import { abstractField } from 'vue-form-generator'

export default {
  mixins: [abstractField]
}
</script>

import { storeToRefs } from 'pinia'
import moment from 'moment'
import isDate from 'lodash/isDate'
import { useAppStore } from '@/stores/app'
import { usePageStore } from '@/stores/pages'

export default {
  install(Vue, options) {
    // checks if a feature is active
    Vue.prototype.$checkFeature = code => {
      const pageStore = usePageStore()
      const { page } = storeToRefs(pageStore)

      return !!page.value.features.includes(code)
    }

    Vue.prototype.$handleQueryParams = search => {
      const query = Object.entries(search).reduce((acc, [key, val]) => {
        if (!val) return acc
        if (isDate(val)) {
          val = moment(val).format('YYYY-MM-DD HH:mm:ss')
        }
        return { ...acc, [key]: val }
      }, {})
      return query
    }

    Vue.prototype.$convertToKebabCase = string => {
      return string.replace(/\s+/g, '-').toLowerCase()
    }

    Vue.prototype.$convertToSnakeCase = string => {
      return string
        .split(/(?=[A-Z])/)
        .join('_')
        .toLowerCase()
    }

    Vue.prototype.$dateFormatter = value => {
      const appStore = useAppStore()
      const { dateConfig } = storeToRefs(appStore)
      return moment(value).format(
        dateConfig.value.dateFormatJs ||
          dateConfig.value.dateFormat ||
          'YYYY-MM-DD'
      )
    }

    Vue.prototype.$dateTimeFormatter = value => {
      const appStore = useAppStore()
      const { dateConfig } = storeToRefs(appStore)
      return moment(value).format(
        dateConfig.value.datetimeFormatJs ||
          dateConfig.value.datetimeFormat ||
          'YYYY-MM-DD HH:mm:ss'
      )
    }

    Vue.prototype.$isDate = value => value instanceof Date

    Vue.prototype.$recurse = obj => {
      const res = {}

      const recurse = (obj, current) => {
        for (const key in obj) {
          const value = obj[key]
          const newKey = current ? `${current}.${key}` : key
          if (value && typeof value === 'object') {
            recurse(value, newKey)
          } else {
            res[newKey] = value
          }
        }
      }

      recurse(obj)

      return res
    }

    Vue.directive('cy', {
      inserted: (el, bind) => {
        el.setAttribute(
          'data-cy',
          bind.value.trim().toLowerCase().replace(/ /g, '-')
        )
      }
    })
  }
}

/**
 * @param {*} type
 * Helper : Set the default value for model based on the data type
 */
export default function getDefaultValueByType(type) {
  let defaultValue = null

  if (type !== undefined && type !== null) {
    switch (type.toUpperCase()) {
      case 'ARRAY':
        defaultValue = []
        break
      case 'BOOLEAN':
        defaultValue = false
        break
    }
  }

  return defaultValue
}

export default {
  apiResponseKeys: [
    'set_order_transition',
    'set_order_item_transition',
    'set_bulk_transition'
  ],

  handle: ({ view, res, responseContentKey }) => {
    view.toast({
      type: 'is-success',
      message: res.actions[responseContentKey].route_response.content
    })

    location.reload()
  }
}

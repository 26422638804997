<template>
  <div>
    <button type="submit" class="button is-primary">{{ schema.flabel }}</button>
  </div>
</template>
<script>
import { abstractField } from 'vue-form-generator'

export default {
  mixins: [abstractField]
}
</script>

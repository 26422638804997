/**
 * @param {*} obj
 * @param {*} path
 * @helper : Recursive search values on object
 */
export default function getValue(obj, path) {
  if (!path) return obj
  const properties = path.split('.')
  try {
    return getValue(obj[properties.shift()], properties.join('.'))
  } catch (error) {
    // TODO: This needs refactoring, triggers an error when opening the Container field
  }
}

<template>
  <validation-provider
    :name="schema.flabel"
    :rules="schema.validatorCommon"
    :vid="schema.model"
    v-slot="{ errors }"
  >
    <b-field
      class="custom-field"
      :type="{ 'is-danger': errors[0] }"
      :message="errors[0]"
    >
      <template slot="label">
        <span>
          {{ labelHandler }}
        </span>
        <span class="has-text-danger" v-if="checkRequired">*</span>
      </template>
      <password
        v-model="value"
        :toggle="true"
        :secureLength="8"
        :showStrengthMeter="false"
        :data-cy="
          schema.hasOwnProperty('properties') &&
          schema.properties.hasOwnProperty('data-cy')
            ? schema.properties['data-cy']
            : ''
        "
      />
    </b-field>
  </validation-provider>
</template>
<script>
import { abstractField } from 'vue-form-generator'
import Password from 'vue-password-strength-meter'
import { ValidationProvider } from 'vee-validate'

export default {
  mixins: [abstractField],
  components: { Password, ValidationProvider },
  computed: {
    labelHandler() {
      return this.schema.showLabel ? this.schema.flabel : ''
    },
    checkRequired() {
      const validator = this.schema.validatorCommon
      if (validator) {
        const validatorSplit = validator.split('|')
        if (validatorSplit.includes('required')) {
          // contains required
          return true
        }
      }
      return false
    }
  }
}
</script>

<template>
  <validation-provider
    v-show="!schema.hidden"
    :class="{ 'hide-class': schema.hidden }"
    :name="schema.flabel"
    :rules="schema.validatorCommon"
    :customMessages="messages"
    v-slot="{ errors }"
    :vid="schema.model"
  >
    <b-field
      class="custom-field"
      :type="{ 'is-danger': errors[0] }"
      :message="errors[0]"
      expanded
    >
      <template slot="label">
        <span
          :style="
            schema.hasOwnProperty('properties') &&
            schema.properties.hasOwnProperty('css')
              ? schema.properties.css
              : ''
          "
        >
          <b-icon
            v-if="
              schema.hasOwnProperty('properties') &&
              schema.properties.hasOwnProperty('icon') &&
              schema.properties['icon'] !== ''
            "
            :icon="schema.properties['icon']"
            :size="
              schema.hasOwnProperty('properties') &&
              schema.properties.hasOwnProperty('icon_size')
                ? 'is-' + schema.properties['icon_size']
                : 'is-small'
            "
            :style="
              schema.hasOwnProperty('properties') &&
              schema.properties.hasOwnProperty('icon_color')
                ? 'color:' + schema.properties['icon_color']
                : ''
            "
          >
          </b-icon>
          {{ labelHandler }}
        </span>
        <span class="has-text-danger" v-if="checkRequired">*</span>
      </template>
      <b-field>
        <p
          v-if="schema.showPreviousValue && schema.inputType !== 'hidden'"
          class="control previous-value-control"
        >
          <b-button :label="String(oldValue)" disabled expanded />
        </p>
        <b-input
          ref="input"
          expanded
          :type="schema.inputType"
          color="dark"
          :placeholder="schema.placeholder"
          v-model="value"
          :hidden="schema.hidden"
          :disabled="disabled"
          :maxlength="schema.flength"
          v-cleave="mask"
          @input.native="onInput"
          :data-cy="
            schema.hasOwnProperty('properties') &&
            schema.properties.hasOwnProperty('data-cy')
              ? schema.properties['data-cy']
              : ''
          "
          @input="callRunDependency(schema, model, value)"
          @blur="mask ? mask.focusOut($event) : null"
        >
        </b-input>
      </b-field>
    </b-field>
  </validation-provider>
</template>
<script>
import { abstractField } from 'vue-form-generator'
import { ValidationProvider } from 'vee-validate'
import Cleave from 'cleave.js'
import { storeToRefs } from 'pinia'
import { callRunDependency } from '@/services/form-builder'
import { useAppStore } from '@/stores/app'
import { i18n } from '@/plugins/i18n'

const cleave = {
  name: 'cleave',
  bind(el, binding) {
    const input = el.querySelector('input')
    if (binding.value) {
      input._vCleave = new Cleave(input, binding.value)
    }
  },
  unbind(el) {
    const input = el.querySelector('input')
    if (typeof input._vCleave !== 'undefined') {
      input._vCleave.destroy()
    }
  }
}

export default {
  directives: { cleave },
  mixins: [abstractField],
  components: {
    ValidationProvider
  },
  setup() {
    const appStore = useAppStore()
    const { numberFormat } = storeToRefs(appStore)
  },
  data() {
    return {
      oldValue: '',
      callRunDependency,
      mask: null,
      messages: {}
    }
  },
  created() {
    this.setDefaultValue()
    callRunDependency(this.schema, this.model, this.value, true)
    this.$set(this, 'value', this.value)
    this.setPreviousValue()

    const [delimiter, numeralDecimalMark] = this.numberFormat
      ? this.numberFormat.split('||')
      : [undefined, undefined]

    this.messages.regex = i18n.t(this.schema.RegexValidatorErrorMessage)

    const masks = {
      INTEGER: {
        numeral: true,
        numeralDecimalMark,
        delimiter,
        numeralIntegerScale: 10,
        numeralDecimalScale: 0,
        focusOut: () => {}
      },
      FLOAT: {
        numeral: true,
        numeralDecimalMark,
        delimiter,
        numeralIntegerScale: 10,
        focusOut: e => {
          if (this.schema.properties.force_decimals !== 'true') {
            return
          }

          const value = e ? e.target._vCleave.getRawValue() : 0

          // does the trick for now, a better solution would be the api returing the locale string instead of the mark
          const localeString =
            this.mask.numeralDecimalMark === ',' ? 'de-DE' : 'en-US'

          const formatted = parseFloat(value || 0).toLocaleString(
            localeString,
            {
              minimumFractionDigits: this.mask.numeralDecimalScale
            }
          )

          console.log('formatted:', formatted)
          this.value = formatted
        }
      }
    }

    if (masks[this.schema.dataType]) {
      this.mask = masks[this.schema.dataType]
      this.mask.numeralDecimalScale = this.schema.properties.decimal_scale
    }
  },
  mounted() {
    document.querySelectorAll('.hide-class').forEach(element => {
      element.parentNode.parentNode.style.setProperty(
        'margin-bottom',
        '0px',
        'important'
      )
    })
  },
  methods: {
    onInput(event) {
      if (typeof event.target._vCleave !== 'undefined') {
        this.rawValue = event.target._vCleave.getRawValue()
        this.value = event.target._vCleave.getFormattedValue()

        // to fix buefy bug no firefox, computedValue stays with not allowed chars
        this.$refs.input.updateValue(this.value)
      }
    },

    runDependency() {
      if (
        this.schema.hasOwnProperty('onChanged') &&
        this.value &&
        typeof this.value !== 'undefined'
      ) {
        this.schema.onChanged(this.model, this.value, '', '', true)
      }
    },
    setDefaultValue() {
      if (
        !this.value &&
        this.schema.default &&
        !this.schema.default.includes('url_params:') &&
        !this.schema.default.includes('user_params:')
      ) {
        this.value = this.schema.default
      }
    },
    setPreviousValue() {
      if (!this.schema.showPreviousValue) {
        return
      }

      this.oldValue = this.value ? this.value : ''
    }
  },
  computed: {
    labelHandler() {
      return this.schema.showLabel ? this.schema.flabel : ''
    },
    checkRequired() {
      const validator = this.schema.validatorCommon
      if (validator) {
        const validatorSplit = validator.split('|')
        if (validatorSplit.includes('required')) {
          // contains required
          return true
        }
      }
      return false
    }
  }
}
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',{staticClass:"top-registration-title text-left",style:(_vm.schema.hasOwnProperty('properties') &&
      _vm.schema.properties.hasOwnProperty('css')
        ? _vm.schema.properties.css
        : '')},[(
        _vm.schema.hasOwnProperty('properties') &&
        _vm.schema.properties.hasOwnProperty('icon') &&
        _vm.schema.properties['icon'] !== ''
      )?_c('b-icon',{style:(_vm.schema.hasOwnProperty('properties') &&
        _vm.schema.properties.hasOwnProperty('icon_color')
          ? 'color:' + _vm.schema.properties['icon_color']
          : ''),attrs:{"icon":_vm.schema.properties['icon'],"size":_vm.schema.hasOwnProperty('properties') &&
        _vm.schema.properties.hasOwnProperty('icon_size')
          ? 'is-' + _vm.schema.properties['icon_size']
          : 'is-small'}}):_vm._e(),_vm._v(" "+_vm._s(_vm.schema.flabel)+" ")],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
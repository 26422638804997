import setProperties from '@/services/form-builder/setProperties'
import setFormModel from '@/services/form-builder/setFormModel'

/**
 * @param {*} form
 * @param {*} buttons
 */

export default function handleViewForm(form, buttons) {
  const fieldObj = []
  const fieldArr = []
  const finalObj = {}

  for (const field in form) {
    fieldObj[field] = {}
    switch (form[field].field_type) {
      case 'waste':
        form[field].field_default_value = []
        fieldObj[field] = {
          legend: '',
          fields: [
            {
              type: 'array',
              label: '',
              model: form[field].field_name,
              itemContainerComponent: 'Container',
              newElementButtonLabelClasses: 'display-none',
              newElementButtonLabel: '',
              showRemoveButton: false,
              fieldClasses: 'columns is-multiline is-mobile',
              items: {
                readonly: true,
                form: window.formType,
                type: 'object',
                default: {},
                schema: {
                  fields: []
                }
              }
            }
          ]
        }
        for (const child2 in form[field].childs.fields) {
          fieldObj[field].fields[0].items.schema.fields.push(
            setProperties(form[field].childs.fields[child2])
          )
        }

        if (
          form[field].field_name !== null &&
          form[field].field_name !== undefined
        ) {
          setFormModel(form[field])
        }
        break

      // fieldArr.push(fieldObj[field])
      // continue
      case 'group':
        fieldObj[field].legend = ''
        fieldObj[field].styleClasses = ['field', 'is-grouped', 'custom-group']
        fieldObj[field].fields = []
        if (form[field].childs && form[field].childs.fields) {
          for (const child in form[field].childs.fields) {
            fieldObj[field].fields.push(
              setProperties(form[field].childs.fields[child])
            )
          }
        }
        break
      case 'card':
        fieldObj[field] = {
          legend: '',
          styleClasses: ['group-card-form-builder'],
          fields: [setProperties(form[field])]
        }
        break
      case 'header':
        fieldObj[field] = { legend: '', fields: [setProperties(form[field])] }
        fieldObj[field].fields[0].childs = []
        for (const child3 in form[field].childs.fields) {
          fieldObj[field].fields[0].childs.push(
            setProperties(form[field].childs.fields[child3])
          )
        }
        break
      default:
        fieldObj[field] = { legend: '', fields: [setProperties(form[field])] }
        break
    }

    fieldArr.push(fieldObj[field])
  }

  finalObj.model = window.formModel
  finalObj.groups = fieldArr

  finalObj.options = {
    validateAfterLoad: false,
    validateAfterChanged: true
  }

  return finalObj
}

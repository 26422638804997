import { validate as vValidate } from 'vee-validate'
import _ from 'lodash'
import moment from 'moment'
import { i18n } from '@/plugins/i18n'
import numbers from '@/services/helpers/numbers'

const parseValue = (value, validation, fieldType, mask) => {
  switch (true) {
    case Array.isArray(value) && !value.length:
      return ''
    case _.isDate(value):
      const isValidDateTime = moment(
        value,
        'YYYY-MM-DD HH:mm:ss',
        true
      ).isValid()
      const dateFormat = isValidDateTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD'
      return moment(value).format(dateFormat)
    case fieldType === 'select':
      return value || ''
    case typeof validation.rule !== 'object' &&
      validation.rule.includes('is_not') &&
      fieldType === 'select':
      return String(value)
    case (validation.rule || []).includes('integer') && fieldType === 'input':
      return mask ? numbers.unmask(mask, value) : value
    default:
      return value
  }
}

const parseMessage = (message, field) => {
  if (!message) return undefined

  const variables = {
    '{field}': field.field_label
  }

  const matches = message.match(/{(.*?)}/g) || []

  matches.forEach(match => {
    message = message.replace(match, variables[match] || '')
  })

  return message
}

const parseValidation = (
  validation,
  field,
  matrix,
  comparativeValue,
  targetValues,
  destinationValues,
  total
) => {
  const _validation = { ...validation }

  _validation.rule = _.reduce(
    _validation.rule,
    (acc, rule) => {
      const [ruleName, params] = rule.split(/:(.*)/s)
      const paramsArray = (params || '').split(',')

      const validationByRule = {
        required: { allowFalse: field.field_type !== 'checkbox' },
        inflate_required_if: {
          currentHierarchy: field.rowHierarchyId,
          targets: params,
          matrix
        },
        compare_rule: {
          target:
            paramsArray.includes('@comparative') ||
            paramsArray[0].includes('@sub')
              ? comparativeValue
              : paramsArray[0],
          valueCap: paramsArray[1],
          diffCap: paramsArray[2]
        },
        inflate_sum_compare: {
          operator: paramsArray[0],
          targetValues,
          destinationValues
        },
        inflate_sum_max: {
          maxValue: paramsArray[0],
          isOn: paramsArray[1],
          total
        },
        required_if_visible: {
          isVisible: field.visible
        },
        table_waste_sum_max: {
          maxValue: paramsArray[0],
          total
        }
      }

      const isComparativeRule = paramsArray.some(param =>
        param.includes('@comparative')
      )

      if (isComparativeRule && !validationByRule[ruleName]) {
        paramsArray[0] = [comparativeValue]
      }

      const ruleObj = ruleName
        ? {
            [ruleName]: validationByRule[ruleName] || paramsArray
          }
        : null

      return {
        ...acc,
        ...ruleObj
      }
    },
    {}
  )

  return _validation
}

const validate = async (
  value,
  validation,
  field,
  {
    name,
    values,
    matrix,
    customMessage,
    comparativeValue,
    targetValues,
    destinationValues,
    total
  }
) => {
  const _value = parseValue(value, validation, field.field_type, field.mask)
  const _validation = parseValidation(
    validation,
    field,
    matrix,
    comparativeValue,
    targetValues,
    destinationValues,
    total
  )

  const result = await vValidate(_value, _validation.rule, {
    name,
    values
  })

  const failedRuleName = Object.keys(result.failedRules)[0] || ''

  const message = !result.failedRules.regex
    ? i18n.t(_validation.error_slug) ||
      parseMessage(customMessage, field) ||
      result.errors[0] ||
      false
    : i18n.t(_validation.error_slug || result.errors[0])

  return { result, error: { error: failedRuleName, message } }
}

export default validate

import { storeToRefs } from 'pinia'
import axios from 'axios'
import { api } from '@/services/api'
import { config } from '@/helpers/config'
import { i18n } from '@/plugins/i18n'
import { uploadDocument } from '@/services/document'
import { useAppStore } from '../stores/app'
import { useUserStore } from '../stores/user'
import { usePageStore } from '../stores/pages'

export {
  getAll,
  create,
  destroy,
  one,
  cancel,
  update,
  status,
  downloadFile,
  deleteFile,
  getOrderStatus,
  assignContractorDetails,
  categoriesTree,
  categoryUnits,
  categoryContainers,
  assignDestination,
  formatCategoriesTree,
  getAllUnits,
  getFileInfo,
  orderItemChangeStatus,
  getAllContainers,
  exportData,
  exportOrdersList,
  orderItemTransitionTracking
}

// eslint-disable-next-line default-param-last
async function orderItemTransitionTracking(endpoint, params = [], locale) {
  const newParams = params
  newParams.locale = locale
  const APIResponse = await api.post(endpoint, newParams)

  if (APIResponse.code === 200) {
    return APIResponse.data
  }
}

async function getAll(
  role,
  lp,
  status = '',
  locale = 'en',
  page = 1,
  sortBy = '',
  search = {}
) {
  let params = {}
  params.locale = locale
  params.page = page
  params.sort_by = sortBy
  params.search = search
  if (status !== '') {
    params.status = status
  }

  params.locale = locale
  params.form_slug = 'list-form-orders'

  switch (role) {
    case 'Logistic Partner':
      params = { driver_id: lp }
      break
    case 'Collection Point':
      params = { origin_id: lp }
      break
    case 'Recycling Center':
      params = { destination_id: lp }
      break
    default:
  }

  const APIResponse = await api.post('orders', params)

  if (APIResponse.code === 200) {
    return APIResponse
  }
}

async function create(form) {
  const APIResponse = await api.post('order-create', form).then(response => {
    if (response.data.actions.orders_create.route_response.content.id) {
      form.Order.id =
        response.data.actions.orders_create.route_response.content.id

      fileUpload(form, 'Order')
    }

    if (response.code === 200) {
      if ('accessToken' in response.data) {
        return true
      }
      return response
    }
  })

  return APIResponse.data
}

async function update(form) {
  const APIResponse = await api.post('order-update', form).then(response => {
    if (form.hasOwnProperty('files')) {
      fileUpload(form, 'Order')
    }

    if (response.code === 200) {
      if ('accessToken' in response.data) {
        return true
      }
      return response
    }
  })

  if (APIResponse.code === 200) {
    return APIResponse.data
  }
}

async function destroy(id) {
  const params = { id }

  const APIResponse = await api.post('order-delete', params)

  if (APIResponse.code === 200) {
    return APIResponse
  }
}

async function one(id) {
  const params = { id }

  params.form_slug = 'list-order-details'

  const APIResponse = await api.post('order', params)

  if (APIResponse.code === 200) {
    return APIResponse
  }
}

async function status(status, orderID) {
  const params = { id: orderID }

  const APIResponse = await api.post('order-update-status-up', params)

  if (APIResponse.code === 200) {
    return APIResponse
  }
}

async function assignDestination(form) {
  const params = { form: JSON.parse(form) }

  const APIResponse = await api.post('order-assign-destination', params.form)

  if (APIResponse.code === 200) {
    return true
  }
}

async function categoriesTree() {
  const APIResponse = await api.post('get-categories-tree-by-app')

  if (APIResponse.code === 200) {
    return APIResponse
  }
}

async function categoryUnits(category) {
  const params = { category_id: category }

  const APIResponse = await api.post('get-units-by-category', params)

  if (APIResponse.code === 200) {
    return APIResponse
  }
}

async function categoryContainers(category) {
  const params = { category_id: category }

  const APIResponse = await api.post('get-containers-by-category', params)

  if (APIResponse.code === 200) {
    return APIResponse
  }
}

async function getAllUnits() {
  const APIResponse = await api.post('get-units-tree-by-app')

  if (APIResponse.code === 200) {
    return APIResponse
  }
}

async function getAllContainers() {
  const APIResponse = await api.post('get-containers-tree-by-app')

  if (APIResponse.code === 200) {
    return APIResponse
  }
}

async function assignContractorDetails(form) {
  const params = { form: JSON.parse(form) }

  const APIResponse = await api.post('assign-contractor-details', params.form)

  if (APIResponse.code === 200) {
    return true
  }
}

async function orderItemChangeStatus(form) {
  const params = { form: JSON.parse(form) }

  const APIResponse = await api.post('order-item-change-status', params.form)

  if (APIResponse.code === 200) {
    return true
  }
}

async function getOrderStatus() {
  const APIResponse = await api.post('orders-per-status')

  if (APIResponse.code === 200) {
    return APIResponse
  }
}

async function getFileInfo(form) {
  const params = { form: JSON.parse(form) }

  const APIResponse = await api.post('get-order-doc-binary', params.form)

  if (APIResponse.code === 200) {
    return APIResponse
  }
}

async function downloadFile(location) {
  const axiosConfig = {
    responseType: 'blob'
  }

  const APIResponse = axios.post(
    `${config.ApiUrl}download-file`,
    { document_location: location },
    axiosConfig
  )

  return APIResponse
}

async function exportOrdersList(data) {
  const appStore = useAppStore()
  const userStore = useUserStore()
  const { token } = storeToRefs(userStore)
  const { clientKey } = storeToRefs(appStore)

  data.client_key = clientKey.value
  if (i18n.locale) {
    data.locale = i18n.locale
  }

  const axiosConfig = {
    responseType: 'blob',
    headers: {
      Authorization: token.value ? `Bearer ${token.value}` : ''
    }
  }

  const APIResponse = axios.post(
    `${config.ApiUrl}orders/export`,
    data,
    axiosConfig
  )

  return APIResponse
}

async function deleteFile(id) {
  const params = { id }

  const APIResponse = await api.post('delete-order-document', params)

  if (APIResponse.code === 200) {
    return APIResponse
  }
}

async function cancel(form) {
  const params = { form: JSON.parse(form) }

  const APIResponse = await api.post('cancel-order-direct', params.form)

  if (APIResponse.code === 200) {
    return APIResponse
  }
}

function formatCategoriesTree(categories) {
  const options = []
  const option = {}
  let cnt = 1
  const cache = []

  for (const category in categories) {
    option[cnt] = {}
    option[cnt].key = categories[category].name
    option[cnt].name = categories[category].name

    if (Object.keys(categories[category].families).length > 0) {
      const children = []
      let cntChildren = 1

      for (const family in categories[category].families) {
        if (categories[category].families[family] !== undefined) {
          children[cntChildren] = {
            key: categories[category].families[family].name,
            name: categories[category].families[family].name
          }

          if (
            Object.keys(
              categories[category].families[family].categories.length > 0
            )
          ) {
            children[cntChildren].subOptions = []
            for (const cat in categories[category].families[family]
              .categories) {
              cache[categories[category].families[family].categories[cat].id] =
                {
                  key: categories[category].families[family].categories[cat].id,
                  name: categories[category].families[family].categories[cat]
                    .name
                }
              children[cntChildren].subOptions.push({
                key: categories[category].families[family].categories[cat].id,
                name: categories[category].families[family].categories[cat].name
              })
            }
          }
        }
        cntChildren++
      }

      option[cnt].subOptions = children
    }

    options.push(option[cnt])
    cnt++
  }

  const pageStore = usePageStore()
  const { order } = storeToRefs(pageStore)

  order.value = { items: cache }

  return options
}

function exportData(sortBy, search) {
  return this.generic('orders', {
    locale: i18n.locale,
    search,
    sort_by: sortBy,
    form_slug: 'list-form-orders'
  })
}

async function fileUpload(form, model) {
  if (form.files) {
    form.model = model
    await uploadDocument(
      form.files,
      form.model,
      form[form.model].id,
      form.Document.type_id,
      form.Document.document_type_id,
      form.form_slug
    ).then(response => {
      return response
    })
  }
}

<template>
  <li :class="{ 'is-active': isDropdownActive }">
    <component
      :is="componentIs"
      :to="itemTo"
      :exact-active-class="exactActiveClass"
      :class="{
        'has-icon': !!item.icon,
        'has-dropdown-icon': hasDropdown,
        'is-active': isActive
      }"
      @click="menuClick"
    >
      <b-icon
        v-if="item.icon"
        :icon="item.icon"
        custom-size="default"
        v-cy="`aside-menu-item-icon-${item.icon}`"
      />
      <span
        v-if="item.label && !item.parent_label"
        :class="{ 'menu-item-label': !!item.icon }"
        v-cy="`aside-menu-item-${item.label}`"
        >{{ item.label }}</span
      >
      <span
        v-if="item.label && item.parent_label"
        :class="{ 'menu-item-label': !!item.icon }"
        v-cy="`aside-menu-item-${item.parent_label}-${item.label}`"
        >{{ item.label }}</span
      >
      <div
        v-if="hasDropdown"
        class="dropdown-icon"
        v-cy="`aside-menu-item-has-drop-${hasDropdown}`"
      >
        <b-icon :icon="dropdownIcon" custom-size="default" />
      </div>
    </component>
    <aside-menu-list
      v-if="hasDropdown"
      :menu="item.menu"
      :is-submenu-list="true"
    />
  </li>
</template>

<script>
import { storeToRefs } from 'pinia'
import { usePageStore } from '../stores/pages'

export default {
  name: 'AsideMenuItem',
  components: {
    AsideMenuList: () => import('@/components/AsideMenuList')
  },
  setup() {
    const pageStore = usePageStore()
    const { selectedRow } = storeToRefs(pageStore)

    return { selectedRow }
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    route: String
  },
  data() {
    return {
      isDropdownActive: false,
      // eslint-disable-next-line vue/no-computed-properties-in-data
      exactActiveClass: this.hasDropdown ? 'is-active' : ''
    }
  },
  created() {
    this.isDropdownActive = this.item.menu
      ? Boolean(this.item.menu.filter(item => item.to === this.route).length)
      : false
  },
  computed: {
    componentIs() {
      return this.item.to ? 'router-link' : 'a'
    },
    hasDropdown() {
      return !!this.item.menu
    },
    dropdownIcon() {
      return this.isDropdownActive ? 'minus' : 'plus'
    },
    itemTo() {
      return this.item.to ? this.item.to : null
    },
    itemHref() {
      return this.item.href ? this.item.href : null
    },
    isActive() {
      return this.$route.path === this.itemTo && !this.item.submenus
    }
    // ...mapState(['selectedRow'])
  },
  methods: {
    menuClick() {
      if (this.hasDropdown) {
        const pageStore = usePageStore()
        const { selectedRow } = storeToRefs(pageStore)
        this.isDropdownActive = !this.isDropdownActive
        selectedRow.value = {
          routeName: null,
          selected: null
        }
      }
    }
  }
}
</script>

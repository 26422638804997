<script
  src="../main.js"
  integrity="sha384-OLBgp1GsljhM2TJ+sbHjaiH9txEUvgdDTAzHv2P24donTt6/529l+9Ua0vFImLlb"
  crossorigin="anonymous"
></script>
<template>
  <div class="card form-builder-card">
    <header class="card-header">
      <p class="card-header-title">
        {{ schema.flabel }}
      </p>
    </header>
  </div>
</template>
<script>
import { abstractField } from 'vue-form-generator'
export default {
  mixins: [abstractField]
}
</script>

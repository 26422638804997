<template>
  <b-field grouped>
    <div
      class="field custom-field is-expanded"
      v-for="item in schema.childs['fields']"
      v-bind:key="item.id"
    >
      <b-field v-if="item.element_type !== 'select'" :label="item.field_label">
        <b-input
          :type="item.element_type"
          color="dark"
          :placeholder="item.placeholder"
          v-model="value"
          :readonly="item.readonly"
          required="required"
        ></b-input>
      </b-field>
      <b-field v-else :label="item.field_label" class="custom-field">
        <b-select
          v-model="value"
          placeholder="Select a option"
          expanded
          required
        >
          <option v-for="option in item.options" :value="option" :key="option">
            {{ option }}
          </option>
        </b-select>
      </b-field>
    </div>
  </b-field>
</template>
<script>
import { abstractField } from 'vue-form-generator'

export default {
  mixins: [abstractField]
}
</script>

<template>
  <div>
    <b-field :label="schema.flabel">
      <b-switch
        v-model="value"
        :rounded="false"
        :outlined="false"
        type="is-success"
        size="is-medium"
        true-value="1"
        false-value="0"
      >
      </b-switch>
    </b-field>
  </div>
</template>
<script>
import { abstractField } from 'vue-form-generator'

export default {
  mixins: [abstractField]
}
</script>

import { features } from '@/data/features'

export default {
  apiResponseKeys: ['getdeclarationhistory'],

  handle: ({ view, res, responseContentKey }) => {
    const data = res.actions[responseContentKey].route_response.content

    const columns = !data.form.fields
      ? []
      : data.form.fields.map(f => ({
          name: f.field_name,
          field: f.field_name,
          label: f.field_label,
          type: f.field_type,
          id: f.id,
          render:
            f.field_name === 'actions'
              ? {
                  field: 'actions',
                  searchable: false,
                  class: 'list-actions',
                  content: features.filter(f =>
                    view.$parent.page.features.includes(f.feature)
                  )
                }
              : null
        }))

    view.modal({
      component: 'DynamicList',
      props: {
        res,
        params: {
          isHistoryModal: true,
          paginated: false,
          filterable: false,
          title: data.form.form_name,
          data: data.dataset,
          columns
        },
        model: {}
      }
    })
  }
}

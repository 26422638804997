export default {
  init(view) {
    view.params[this.name] = view.$route.params
  },

  handles(view) {
    return {
      onCreate: _this => {
        view.api({
          module: this,
          method: 'post',
          endPoint: view.modules[this.name].uses.flow || '/authenticated-forms',
          body: {
            ...view.params[this.name],
            form: view.modules[this.name].uses.form
          },
          callback: res => {
            res = res.actions
              ? res.actions[Object.keys(res.actions)[0]].route_response.content
              : res

            view.params[this.name].reference_hash = res.reference_hash

            _this.form.name = res.form.form_name

            _this.form.buttons = res.form.buttons

            _this.formBuilder({
              ...res,
              formDependency: view.modules[this.name].uses.form
            })
          }
        })
      },

      onSubmit: (_this, button) => {
        _this.flagDisable = true

        _this.formValidation({
          success: result => {
            view.api({
              method: 'post',
              module: this,
              endPoint: view.modules[this.name].uses.submitFlow,
              body: {
                ...result.payload,
                ...view.params[this.name],
                transition: button.value,
                form: view.modules[this.name].uses.form
              },
              callback: async res => {
                res = res.actions
                  ? res.actions[Object.keys(res.actions)[0]].route_response
                      .content
                  : res

                view.toast({
                  type: 'is-success',
                  message: res.message
                })
                _this.flagDisable = false
                view.$router.push('/declarations')
              }
            })
          }
        })
      }
    }
  },

  elements(view) {
    return []
  }
}

import moment from 'moment'
import { storeToRefs } from 'pinia'
import { useAppStore } from '@/stores/app'

export const typeFields = [
  {
    element: 'date',
    component: 'b-datepicker',
    size: 'is-small',
    icon: 'calendar-today',
    'icon-right': 'close-circle',
    'icon-right-clickable': true,
    class: '',
    position: 'is-bottom-right',
    'append-to-body': false,
    'nearby-selectable-month-days': true,
    'date-formatter': value => {
      const appStore = useAppStore()
      const { dateConfig } = storeToRefs(appStore)

      return moment(value).format(
        dateConfig.value.dateFormatJs ||
          dateConfig.value.dateFormat ||
          'YYYY-MM-DD'
      )
    }
  },
  {
    element: 'calendar_datetime',
    component: 'b-datetimepicker',
    size: 'is-small',
    icon: 'calendar-today',
    'icon-right': 'close-circle',
    'icon-right-clickable': true,
    position: 'is-bottom-right',
    timepicker: { enableSeconds: true, 'hour-format': '24' },
    'append-to-body': false,
    'nearby-selectable-month-days': true,
    'datetime-formatter': value => {
      const appStore = useAppStore()
      const { dateConfig } = storeToRefs(appStore)

      return moment(value).format(
        dateConfig.value.datetimeFormatJs ||
          dateConfig.value.datetimeFormat ||
          'YYYY-MM-DD HH:mm:ss'
      )
    }
  },
  {
    element: 'datetime',
    component: 'b-timepicker',
    icon: 'clock',
    editable: true
  },
  {
    element: 'checkbox',
    component: 'b-checkbox'
  },
  {
    element: 'select',
    component: 'multiselect',
    'track-by': 'value',
    label: 'text',
    searchable: true,
    showLabel: true,
    showLabels: false, // hides "Press Enter to select"
    showNoResults: true,
    class: ''
  },
  {
    element: 'textarea',
    component: 'b-input',
    type: 'textarea',
    defaultMaxlength: 1500,
    class: ''
  },
  {
    element: 'sub_form',
    component: '',
    type: '',
    class: ''
  },
  {
    element: 'hidden',
    component: 'input',
    type: 'hidden',
    showLabel: false
  },
  {
    element: 'input',
    component: 'b-input',
    type: 'text',
    default: true,
    class: 'mb-4'
  },
  {
    element: 'docmodal',
    component: '',
    showLabel: false
  },
  {
    element: 'signature_hover',
    component: '',
    showLabel: false
  },
  // NO INPUT
  {
    element: 'section',
    component: 'section',
    class: 'top-registration-title text-left',
    showLabel: false
  },
  {
    element: 'separator',
    component: 'hr',
    showLabel: false
  },
  {
    element: 'readonly',
    component: 'b-field',
    class: 'field-readonly custom-field review-fields',
    showLabel: false
  },
  {
    element: 'group',
    component: 'section',
    showLabel: false
  },
  {
    element: 'waste',
    component: 'input',
    'trap-focus': true,
    'has-modal-card': true,
    class: 'button is-grey is-outlined is-materials-button',
    showLabel: false,
    autocomplete: 'off',
    readonly: true
  },
  {
    element: 'table_waste',
    component: null,
    showLabel: false
  },
  {
    element: 'files',
    component: 'b-upload',
    type: 'files',
    class2: 'content has-text-centered',
    'drag-drop': true,
    content: [{ component: 'p', class: 'has-text-centered' }]
  },
  {
    element: 'space',
    component: 'br',
    showLabel: false
  },
  {
    element: 'card',
    component: 'fieldset',
    class: 'group-card-form-builder form-group mb-4',
    style: {
      backgroundColor: '#ededed',
      color: 'grey',
      padding: '6px',
      border: '1px solid rgba(24, 28, 33, 0.15)'
    },
    showLabel: false
  },
  {
    element: 'image',
    component: 'img',
    showLabel: false
  },
  {
    element: 'tags',
    component: 'b-taginput',
    field: 'text',
    icon: 'label',
    content: '&nbsp;',
    autocomplete: true,
    'allow-new': false,
    'open-on-focus': true,
    'append-to-body': false,
    onTyping: (_this, field, text) => {
      const selectedValues = _this
        .getValue(field)
        .map(selected => selected.value)

      const options = _this.form.options[field.field_name].filter(option => {
        if (option.text == null) {
          option.text = option.value
        }

        return (
          (option.text || '')
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0 &&
          !selectedValues.includes(option.value)
        )
      })

      _this.$set(_this.form.filteredOptions, field.field_name, options)
    },
    onRemove: (_this, field, text) => {
      const selectedValues = _this
        .getValue(field)
        .map(selected => selected.value)

      const options = _this.form.options[field.field_name].filter(option => {
        if (option.text == null) {
          option.text = option.value
        }

        return !selectedValues.includes(option.value)
      })

      _this.$set(_this.form.filteredOptions, field.field_name, options)
    },
    beforeAdding: (tag, value) => {
      const add = !value.some(({ value }) => value === tag.value)

      return add
    }
  },
  {
    element: 'open_html',
    component: 'div',
    showLabel: false
  },
  {
    element: 'html_editor',
    component: 'tinymce',
    plugins: ['image'],
    toolbar1:
      'bold italic  strikethrough  forecolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  ',
    other_options: {
      content_style:
        "@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap'); body { font-family: 'Roboto'; }"
    }
  },
  {
    element: 'html_editor_simple',
    component: 'tinymce',
    toolbar1: 'bold italic  strikethrough  forecolor',
    other_options: {
      content_style:
        "@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap'); body { font-family: 'Roboto'; }"
    },
    class: 'display-none-toolbar'
  },
  {
    element: 'password',
    component: 'password',
    toggle: true,
    showStrengthMeter: false,
    secureLength: 8
  },
  {
    element: 'year',
    component: 'multiselect',
    'track-by': 'value',
    label: 'text',
    searchable: true,
    showLabel: true,
    showLabels: false,
    showNoResults: true,
    class: ''
  },
  {
    element: 'month',
    component: 'b-datepicker',
    icon: 'calendar-today',
    type: 'month'
  },
  {
    element: 'vueSignature',
    component: 'vueSignature',
    class: 'vueSignature mt-1',
    sigOption: {
      penColor: 'rgb(0, 0, 0)',
      backgroundColor: 'rgb(255,255,255)'
    }
  },
  {
    element: 'steps',
    component: '',
    showLabel: false
  },
  {
    element: 'radio',
    component: null,
    showLabel: false
  },
  {
    element: 'checkboxgroup',
    component: null,
    showLabel: false
  }
]

export const typeFieldsList = [
  {
    element: 'date',
    component: 'span',
    class: 'tag is-secondary'
  },
  {
    element: 'calendar_datetime',
    component: 'span',
    class: 'tag is-secondary'
  },
  {
    element: 'datetime',
    component: 'span',
    class: 'tag is-secondary'
  },
  {
    element: 'docmodal',
    component: '',
    alwaysVisible: true
  },
  {
    element: 'signature_hover',
    component: '',
    alwaysVisible: true
  },
  {
    element: 'div',
    component: 'div',
    default: true
  }
]

export const typeFieldsFilters = [
  {
    element: 'date',
    component: 'b-datepicker',
    icon: 'calendar-today',
    'nearby-selectable-month-days': true,
    'append-to-body': true,
    'date-formatter': value => {
      const appStore = useAppStore()
      const { dateConfig } = storeToRefs(appStore)

      return moment(value).format(
        dateConfig.value.dateFormatJs ||
          dateConfig.value.dateFormat ||
          'YYYY-MM-DD'
      )
    }
  },
  {
    element: 'datetime',
    component: 'b-datetimepicker',
    icon: 'calendar-today',
    timepicker: { enableSeconds: true, 'hour-format': '24' },
    'nearby-selectable-month-days': true,
    'append-to-body': true,
    'datetime-formatter': value => {
      const appStore = useAppStore()
      const { dateConfig } = storeToRefs(appStore)

      return moment(value).format(
        dateConfig.value.datetimeFormatJs ||
          dateConfig.value.datetimeFormat ||
          'YYYY-MM-DD HH:mm:ss'
      )
    }
  },
  {
    element: 'calendar_datetime',
    component: 'b-datetimepicker',
    icon: 'calendar-today',
    timepicker: { enableSeconds: true, 'hour-format': '24' },
    'nearby-selectable-month-days': true,
    'append-to-body': true,
    'datetime-formatter': value => {
      const appStore = useAppStore()
      const { dateConfig } = storeToRefs(appStore)

      return moment(value).format(
        dateConfig.value.datetimeFormatJs ||
          dateConfig.value.datetimeFormat ||
          'YYYY-MM-DD HH:mm:ss'
      )
    }
  },
  {
    element: 'select',
    component: 'b-input'
  },
  {
    element: 'textarea',
    component: 'b-input'
  },
  {
    element: 'input',
    component: 'b-input',
    type: 'text',
    default: true
  },
  {
    element: 'boolean',
    component: 'b-input'
  }
]

export const typeBulkFilters = [
  {
    element: 'date',
    component: 'b-datepicker',
    icon: 'calendar-today',
    class: 'mb-3',
    'nearby-selectable-month-days': true,
    'date-formatter': value => {
      const appStore = useAppStore()
      const { dateConfig } = storeToRefs(appStore)

      return moment(value).format(
        dateConfig.value.dateFormatJs ||
          dateConfig.value.dateFormat ||
          'YYYY-MM-DD'
      )
    }
  },
  {
    element: 'datetime',
    component: 'b-datetimepicker',
    icon: 'calendar-today',
    'nearby-selectable-month-days': true,
    'append-to-body': true,
    timepicker: { enableSeconds: true, 'hour-format': '24' },
    'datetime-formatter': value => {
      const appStore = useAppStore()
      const { dateConfig } = storeToRefs(appStore)

      return moment(value).format(
        dateConfig.value.datetimeFormatJs ||
          dateConfig.value.datetimeFormat ||
          'YYYY-MM-DD HH:mm:ss'
      )
    }
  },
  {
    element: 'select',
    component: 'multiselect',
    'track-by': 'value',
    label: 'label',
    searchable: true,
    class: 'mb-3',
    showLabels: false // hides "Press Enter to select"
  }
]

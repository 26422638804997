import setTransition from './setTransition'
import transitionHistory from './transitionHistory'
import declarationHistory from './declarationHistory'
import cannedReportDownloadHistory from './cannedReportDownloadHistory'
import priceMatrixPreviewFeature from './priceMatrixPreviewFeature'
import getOrderInvoices from './getOrderInvoices'
import ignoreDefaultResponseAction from './ignoreDefaultResponseAction'

export default [
  setTransition,
  transitionHistory,
  cannedReportDownloadHistory,
  priceMatrixPreviewFeature,
  getOrderInvoices,
  ignoreDefaultResponseAction,
  declarationHistory
]

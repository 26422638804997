import { useUserStore } from '../../stores/user'
import { updateDetails } from '@/services/authentication'
import { api } from '@/services/api'
import { getGenericData } from '@/services/document'

export default function elements({ view }) {
  const documentDownload = function ({ response }) {
    if ('file' in response && 'extension' in response && 'name' in response) {
      const a = document.createElement('a')
      a.href = `data:application/${response.extension};base64,${response.file}`
      a.download = response.name
      a.click()
    }
  }
  return [
    {
      if: () => true,
      disabled: ({ row }) =>
        !row.results || JSON.parse(row.results).length === 0,
      routes: ['/asynchronous-requests'],
      sequency: 1,
      component: 'b-button',
      content: { element: 'b-icon', title: 'View', icon: 'eye' },
      class: 'is-primary is-small rounded-icons is-outlined',
      onClick: ({ _this, row }) => {
        if (!row.results) {
          return
        }

        const itemsDetail = JSON.parse(row.results)

        if (itemsDetail.length === 0) {
          return
        }

        view.modal({
          component: 'DynamicList',
          props: {
            params: {
              paginated: false,
              filterable: false,
              title: `Detail ${_this.params.title}`,
              data: itemsDetail,
              columns: [
                {
                  name: 'name',
                  field: 'name',
                  label: view.$t('asynchronous-requests-name'),
                  type: 'text'
                },
                {
                  name: 'status',
                  field: 'status',
                  label: view.$t('asynchronous-requests-status'),
                  type: 'text'
                },
                {
                  name: 'date',
                  field: 'date',
                  label: view.$t('asynchronous-requests-date'),
                  type: 'date'
                },
                {
                  name: 'message',
                  field: 'message',
                  label: view.$t('asynchronous-requests-message'),
                  type: 'text'
                }
              ]
            },
            model: {}
          }
        })
      }
    },
    {
      if: () => true,
      disabled: () => false,
      routes: ['/abs/periods'],
      sequency: 1,
      component: 'router-link',
      to: `/abs/edit/:id`,
      content: { element: 'b-icon', title: 'Edit', icon: 'pencil' },
      class: 'button is-primary is-small is-outlined rounded-icons'
    },
    {
      if: () => true,
      disabled: ({ row }) => !!row.deleted_at,
      routes: ['/abs/periods'],
      sequency: 2,
      component: 'b-button',
      content: {
        element: 'b-icon',
        title: 'Delete',
        icon: 'delete'
      },
      class: 'is-danger is-small rounded-icons',
      onClick: ({ _this, row }) =>
        view.handles.list.setAction({
          dialog: true,
          type: 'is-danger',
          title: view.$t('delete_title'),
          message: view.$t('delete_question'),
          endpoint: view.modules.list.props.endPoints.delete,
          params: { id: row.id }
        })
    },
    {
      if: () => {
        const userStore = useUserStore()
        return (
          userStore.isImpersonating === false &&
          userStore.numberOfImpersonateRoles > 0
        )
      },
      disabled: ({ row }) => {
        const userStore = useUserStore()
        return (
          !row.role_id ||
          userStore.getUserImpersonateRoles.includes(row.role_id) === false
        )
      },
      routes: ['/users'],
      sequency: 3,
      component: 'b-button',
      content: {
        element: 'b-icon',
        title: 'Impersonate',
        icon: 'account-switch'
      },
      class: 'button is-primary is-small is-outlined rounded-icons',
      onClick: ({ _this, row }) => {
        _this.handles.setAction({
          dialog: true,
          refresh: false,
          endpoint: '/impersonate',
          params: {
            user_id: row.id
          },
          finally: async res => {
            const userStore = useUserStore()
            userStore.impseronate({
              id: row.id,
              name: row.name,
              token: res.accessToken
            })

            await updateDetails()
            _this.$router.push('/')
          },
          title: view.$t('user-impersonate-dialog-title'),
          message: view.$t('user-impersonate-dialog-message')
        })
      }
    },
    {
      if: () => true,
      disabled: () => false,
      routes: ['/canned-report-templates'],
      sequency: 1,
      component: 'b-button',
      content: {
        element: 'b-icon',
        title: 'Generate',
        icon: 'file-download'
      },
      class: 'is-primary is-small rounded-icons',

      onClick: ({ _this, row }) =>
        row.form
          ? view.modal({
              component: 'DynamicForm',
              props: {
                res: null,
                model: view.model.list.modal,
                params: {
                  title: row.title,
                  forms: [row.form.slug]
                },
                hasCard: 'true',
                handles: {
                  api: view.api,
                  onCreate: _this =>
                    view.api({
                      endPoint: 'authenticated-forms',
                      method: 'post',
                      body: {
                        form: row.form
                      },
                      callback: res => {
                        res = res.actions
                          ? res.actions[Object.keys(res.actions)[0]]
                              .route_response.content
                          : res

                        _this.formBuilder({
                          formDependency: row.form,
                          ...res,
                          fields: res.fields
                        })
                      }
                    })
                },
                elements: [
                  {
                    component: 'b-button',
                    label: view.$t('button-close'),
                    onClick: _this => _this.$emit('close')
                  },
                  {
                    component: 'b-button',
                    label: view.$t('canned-report-generate-button'),
                    class: 'is-primary',
                    loading: false,
                    onClick: _this =>
                      _this.formValidation({
                        success: result => {
                          return view.handles.list.setAction({
                            dialog: false,
                            endpoint: `canned_report_templates/${row.id}/export`,
                            finally: response => {
                              documentDownload({ response })
                              _this.$emit('close')
                            },
                            params: result.payload
                          })
                        }
                      })
                  }
                ]
              }
            })
          : view.handles.list.setAction({
              dialog: false,
              endpoint: `canned_report_templates/${row.id}/export`,
              finally: response => {
                documentDownload({ response })
                _this.$emit('close')
              },
              params: {}
            })
    },
    {
      if: () => true,
      disabled: () => false,
      routes: ['/protocols'],
      sequency: 1,
      component: 'router-link',
      to: `/protocols/view/:id`,
      content: {
        element: 'b-icon',
        title: 'View',
        icon: 'eye'
      },
      class: 'button is-primary is-small is-outlined rounded-icons'
    },
    {
      if: () => true,
      disabled: () => false,
      routes: ['/protocols'],
      sequency: 1,
      component: 'router-link',
      to: `/protocols/edit/:id`,
      content: {
        element: 'b-icon',
        title: 'Edit',
        icon: 'pencil'
      },
      class: 'button is-primary is-small is-outlined rounded-icons'
    },
    {
      if: () => true,
      disabled: ({ row }) => !row.period_id || !row.company_id,
      routes: ['/declarations-pending-list'],
      sequency: 1,
      component: 'router-link',
      to: `/declarations/new/:period_id/:company_id`,
      content: {
        element: 'b-icon',
        title: 'Create Declaration',
        icon: 'calendar'
      },
      class: 'button is-primary is-small is-outlined rounded-icons'
    }
  ].filter(element => element.routes.includes(view.$route.path))
}

import { defineStore } from 'pinia'

export const useUtilitiesStore = defineStore('utilities', {
  state: () => {
    return {
      countries: {},
      measures: {},
      containers: {},
      loadings: {},
      currentForm: null,
      currentFormId: null,
      perPageConfig: null,
      itemBreakDown: null
    }
  },
  persist: true
})

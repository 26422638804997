var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.prop.hasOwnProperty('properties') &&
    _vm.prop.properties.hasOwnProperty('position') &&
    _vm.prop.properties['position'] === _vm.position
  )?_c('div',[(
      _vm.prop.hasOwnProperty('properties') &&
      _vm.prop.properties.hasOwnProperty('icon')
    )?_c('b-icon',{attrs:{"icon":_vm.prop.properties['icon'],"size":_vm.prop.hasOwnProperty('properties') &&
      _vm.prop.properties.hasOwnProperty('size')
        ? 'is-' + _vm.prop.properties['size']
        : 'is-small'}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.prop.flabel))]),_c('p',[_c('vue-form-generator',{attrs:{"schema":_vm.schemaY,"model":_vm.model}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
import { storeToRefs } from 'pinia'
import { updateDetails } from '@/services/authentication'
import { useUserStore } from '@/stores/user'
import router from '@/router'

export default newDetailsVersion => {
  if (!newDetailsVersion || router.history.current.meta.public) return

  const userStore = useUserStore()
  const { detailsVersion, id } = storeToRefs(userStore)

  if (!id.value) return

  const update =
    detailsVersion.value === null || newDetailsVersion !== detailsVersion.value

  if (!update) return

  updateDetails(newDetailsVersion)
}

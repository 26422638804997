import omit from 'lodash/omit'
import mapValues from 'lodash/mapValues'
import isEqual from 'lodash/isEqual'
import moment from 'moment'
import { storeToRefs } from 'pinia'
import actions from './actions'
import elements from './elements'
import { features } from '@/data/features'
import { useUserSettingsStore } from '@/stores/userSettings'
import { useLayoutStore } from '@/stores/layout'
import {
  downloadFileNew,
  getDocument,
  downloadFile,
  downloadFilePopup
} from '@/services/document'
import forms from './forms'
import { api } from '@/services/api'
import { useListFiltersStore } from '../../stores/listFilters'
import setModelFeatureToSlug from './slugModelFeatures'

const mime = require('mime-types')

export default {
  init(view) {
    const userSettings = useUserSettingsStore()
    const layoutStore = useLayoutStore()
    let perPage = 25

    this.resetSelectedRow = layoutStore.resetSelectedRow
    this.addSelectedRow = layoutStore.addSelectedRow
    this.getSelectedRow = layoutStore.getSelectedRow

    // to review
    window.transiting = {}

    view.model[this.name].page = view.$route.query.page
      ? parseInt(view.$route.query.page)
      : 1

    if (typeof view.modules[this.name].props.model !== 'undefined')
      perPage = view.modules[this.name].props.model.perPage

    if (this.name === 'tabs') {
      if (
        typeof view.modules[this.name].uses.tabs[
          view.model[this.name].activeTabIndex
        ].view.props.model !== 'undefined'
      )
        perPage =
          view.modules[this.name].uses.tabs[
            view.model[this.name].activeTabIndex
          ].view.props.model.perPage
    }

    view.model[this.name].perPage =
      userSettings.getFilter(`per-page-${this.form}`) || perPage

    this.handles(view).setModelSearch()

    this.get(view)

    this.resetSelectedRow(this.form, view.$route.params.id)
  },

  get(view) {
    const listFiltersStore = useListFiltersStore(this.form)

    const filters = listFiltersStore.getActiveFilter

    view.api({
      module: this,
      endPoint: view.modules[this.name].uses.flow,
      method: 'post',
      body: {
        ...view.model[this.name],
        id: view.$route.params.id,
        search: view.$handleQueryParams(view.model[this.name].search),
        form: this.form,
        adv_search: filters,
        abstractModel: this.abstractModel
      },
      callback: res => {
        res = res.dataset
          ? res
          : res.actions[Object.keys(res.actions)[0]].route_response.content

        view.model[this.name].model = res.form.form_model

        view.params[this.name].notifications = res.notifications

        if (view.model.bulk) view.model.bulk.model = res.form.form_model

        this.handles(view).table(res)
      }
    })
  },

  params(view) {
    return {
      checkedRows: [],
      listActions: [],
      transitionsId: null,
      checkable: true,
      selected: null,
      hiddenFields: {}
    }
  },

  handles(view) {
    return {
      setCheckedRowsCount: () => {
        if (view.model.bulk)
          view.model.bulk.checkedRows =
            view.params[this.name].checkedRows.length
      },
      pageChange: value => {
        view.model[this.name].page = value

        view.$router.push({
          name: view.$route.name,
          query: { ...view.$route.query, ...{ page: value } }
        })

        this.get(view)
      },

      sort: (col, order) => {
        view.model[this.name].sort_by = `${col}.${order}`
        this.get(view)
      },

      select: row => {
        this.addSelectedRow(row.id, this.form)
      },

      filterInput: () => {
        view.model[this.name].page = 1
        clearTimeout(this.typingTimer)

        const query = view.$handleQueryParams(view.model[this.name].search)

        this.typingTimer = setTimeout(() => {
          view.$router.push({ name: view.$route.name, query })
          // runs $route.query watcher and calls routeQueryChange
        }, 500)
      },

      routeQueryChange: (searchQuery, oldSearchQuery) => {
        if (isEqual(omit(searchQuery, 'page'), omit(oldSearchQuery, 'page')))
          return

        // parse query params
        const search = omit(
          mapValues(
            {
              ...view.model[this.name].bulkSearch,
              ...searchQuery
            },
            value =>
              moment(value, 'YYYY-MM-DD', true).isValid() ||
              moment(value, 'YYYY-MM-DD HH:mm:ss', true).isValid()
                ? new Date(value)
                : value
          ),
          'page'
        )

        view.model[this.name].search = search

        this.get(view)
      },

      filterInputClear: field => {
        if (!view.model[this.name].search[field.name]) return

        view.model[this.name].search[field.name] = undefined

        const query = view.$handleQueryParams(view.model[this.name].search)

        view.$router.push({ name: view.$route.name, query }).catch(() => {})
        // runs $route.query watcher and calls routeQueryChange
      },

      cleanSearch: () => {
        view.model[this.name].search =
          { ...view.model[this.name].bulkSearch } || {}

        view.$router.push({ name: view.$route.name, query: {} }).catch(() => {})
        // runs $route.query watcher and calls routeQueryChange
      },

      setModelSearch: () => {
        const query = omit(view.$route.query, 'page')
        view.model[this.name].search = {}

        // can be changed to mapValues
        Object.entries(query).forEach(([key, value]) => {
          view.model[this.name].search[key] =
            moment(value, 'YYYY-MM-DD', true).isValid() ||
            moment(value, 'YYYY-MM-DD HH:mm:ss', true).isValid()
              ? new Date(value)
              : value
        })
      },

      startBulkSearch: () => {
        if (Object.keys(view.$route.query).length > 0)
          return view.$router.push({ name: view.$route.name, query: {} })

        view.model[this.name].search = { ...view.model[this.name].bulkSearch }

        this.get(view)
      },

      changePerPage: value => {
        const userSettings = useUserSettingsStore()

        view.model[this.name].perPage = value

        userSettings.$patch(state => {
          state.filters[`per-page-${this.form}`] = value
          state.hasChanged = true
        })

        switch (this.name) {
          case 'tabs':
            view.modules[this.name].uses.get(view)
            break

          default:
            view.modules[this.name].uses.init(view)
            break
        }
      },

      table: res => {
        const listFiltersStore = useListFiltersStore(this.form)

        const { filters, columns, hiddenColumns } =
          storeToRefs(listFiltersStore)

        const { perPage, page } = view.model[this.name]
        const datasetLength = res.dataset.length

        let total = page * perPage
        if (datasetLength === view.model[this.name].perPage) {
          total = page * perPage + perPage
        }
        view.model[this.name].total = total

        const formFields = res.form.fields.map(f => ({
          ...f,
          name: f.field_name,
          field: f.field_name,
          label: f.field_label,
          type: f.field_type,
          id: f.id,
          condition: f.field_condition,
          render: view.model[this.name].activeTab
            ? view.model[this.name].activeTab.view.props.elements.find(
                e => e.field === f.field_name || e.element === f.field_type
              )
            : view.elements[this.name].find(
                e => e.field === f.field_name || e.element === f.field_type
              )
        }))

        columns.value = formFields

        if (res.user_advanced_filters && res.user_advanced_filters.length > 0) {
          filters.value = res.user_advanced_filters
        }

        view.params[this.name] = {
          ...view.params[this.name],
          title: res.form.form_name,
          data: res.dataset,
          columns: formFields,
          hiddenColumns
        }

        view.params[this.name].selected = res.dataset.find(
          row => row.id === this.getSelectedRow.id
        )

        if (view.model.bulk) view.params.bulk.active = true
      },

      setTransitionsId: param => {
        view.params[this.name].transitionsId = param.row.id
      },

      getActions: row => {
        const usesStateMachine = view.$checkFeature('uses-state-machine')

        if (row.isLoadingActions) return

        view.$set(row, 'isLoadingActions', true)

        if (row.dropActions && row.dropActions.length > 0) {
          row.isLoadingActions = false
          return
        }

        row.dropActions = []
        view.params[this.name].listActionsMsg = null

        view.api({
          module: null,
          method: 'post',
          endPoint:
            view.modules[this.name].props.endPoints.actions ||
            view.modules[this.name].props.endPoints.transitions,
          body: { id: row.id },
          noLoading: true,
          callback: res => {
            let listActionsMsg =
              !res.transitions.length && usesStateMachine
                ? view.$t('no-transitions-available')
                : null

            view.model[this.name].modal = {}

            const modelName = view.model[this.name].model ?? null
            const modelSlugFeature = setModelFeatureToSlug({ modelName })

            res.transitions = res.transitions.concat(
              actions({ view, row, usesStateMachine, modelSlugFeature })
            )

            listActionsMsg =
              !usesStateMachine && !res.transitions.length
                ? 'No actions available.'
                : listActionsMsg

            view.params[this.name].listActionsMsg = listActionsMsg

            row.dropActions.push(
              ...res.transitions.map(action => ({
                ...omit(row, 'dropActions'),
                label: action.name,
                class: action.class,
                onClick: () =>
                  view.handles[this.name].checkModal(
                    action,
                    omit(row, 'dropActions')
                  )
              }))
            )

            row.isLoadingActions = false
          }
        })
      },

      responseAction: ({ res, action }) => ({
        default: () => {
          view.model[this.name].modal = {}

          if (action.refresh !== false) {
            view.modules[this.name].uses.get(view)
          }

          let message = ''

          switch (true) {
            case typeof Object.values(res)[0] === 'string':
              message = view.$t(Object.values(res)[0])
              break
            case typeof res === 'object':
              message = view.$t(
                res.actions[Object.keys(res.actions)[0]].route_response.content
              )
              break
            default:
              break
          }

          view.toast({
            type: 'is-success',
            duration: 5000,
            message
          })
        }
      }),

      onFieldClick: (parent, row, field, index = null) => {
        if (row.documents) {
          Object.keys(row.documents).forEach(key => {
            row.documents[key].documented_added_at = parent.$dateTimeFormatter(
              row.documents[key].created_at
            )
            if (row.documents[key].document_type) {
              row.documents[key].document_type_description =
                row.documents[key].document_type.description
            }
          })
        }

        let hub = {
          docmodal: () => {
            view.modal({
              component: 'DynamicList',
              props: {
                params: {
                  paginated: false,
                  filterable: false,
                  title: 'Documents',
                  data: row.documents,
                  columns: [
                    {
                      name: 'name',
                      field: 'name',
                      label: view.$t('docmodal-document-name'),
                      type: 'text'
                    },
                    {
                      name: 'documented_added_at',
                      field: 'documented_added_at',
                      label: view.$t('docmodal-document-added'),
                      type: 'datetime'
                    },
                    {
                      name: 'document_type_description',
                      field: 'document_type_description',
                      label: view.$t('docmodal-document-type'),
                      type: 'text'
                    },
                    {
                      name: 'id',
                      field: 'id',
                      label: view.$t('docmodal-document-download'),
                      type: 'text',
                      render: {
                        field: 'id',
                        content: [
                          {
                            if: () => true,
                            sequency: 1,
                            component: 'b-button',
                            content: {
                              element: 'b-icon',
                              title: 'Download',
                              icon: 'download'
                            },
                            class: 'is-primary is-small rounded-icons',
                            onClick: ({ row }) => {
                              const data = { document_id: row.id }
                              getDocument(data).then(response => {
                                const aux =
                                  response.data.actions.get_document_binary
                                    .route_response.content
                                const mime =
                                  response.data.actions.get_document_binary
                                    .route_response.content.mimetype
                                downloadFile(aux.location).then(response => {
                                  const blobAux = new Blob([response.data], {
                                    type: mime
                                  })
                                  downloadFilePopup(
                                    window.URL.createObjectURL(blobAux),
                                    aux.real_name
                                  )
                                })
                              })
                            }
                          }
                        ]
                      }
                    },
                    {
                      name: 'id',
                      field: 'id',
                      label: view.$t('docmodal-document-delete'),
                      type: 'text',
                      render: {
                        field: 'id',
                        content: [
                          {
                            if: ({ row }) => row.can_delete,
                            sequency: 1,
                            component: 'b-button',
                            content: {
                              element: 'b-icon',
                              title: 'Delete',
                              icon: 'delete'
                            },
                            class: 'is-primary is-small rounded-icons',
                            onClick: ({ _this, row }) => {
                              view.dialog({
                                type: 'is-danger',
                                hasIcon: true,
                                title: view.$t('remove-document-title'),
                                message: view.$t(
                                  'remove-document-confirm-message'
                                ),
                                onCancel: () => {},
                                onConfirm: () => {
                                  const data = {
                                    document_id: row.id
                                  }

                                  const apiResponse = api

                                    .post('delete-file', data)

                                    .then(response => {
                                      if (response.code === 200) {
                                        const index = parent.form.model[
                                          field.field_name
                                        ].findIndex(obj => {
                                          return obj.id === row.id
                                        })

                                        parent.form.model[
                                          field.field_name
                                        ].splice(
                                          index,

                                          1
                                        )
                                      }
                                    })

                                  _this.$emit('close')

                                  return apiResponse
                                }
                              })
                            }
                          }
                        ]
                      }
                    }
                  ]
                },
                model: {}
              }
            })
          }
        }

        hub = hub[field.type] || (() => null)

        hub()
      },

      isRowCheckable: row => ![3, 8, 9, 12, 17, 18].includes(row.status_id),

      aproveItems: async () => {
        if (
          view.params[this.name].checkedRows &&
          view.params[this.name].checkedRows.length > 0
        ) {
          const fileList = {
            fileList: { ...view.params[this.name].checkedRows }
          }

          const apiResponse = await api
            .post('chunk-model-upload-bulk-approve-files', fileList)
            .then(response => {
              if (response.code === 200) {
                location.reload()
                return response
              }
            })
            .catch(error => {
              console.log(error)
            })

          return apiResponse
        }
      },
      rejectItems: async () => {
        if (
          view.params[this.name].checkedRows &&
          view.params[this.name].checkedRows.length > 0
        ) {
          const fileList = {
            fileList: { ...view.params[this.name].checkedRows }
          }

          const apiResponse = await api
            .post('chunk-model-upload-bulk-reject-files', fileList)
            .then(response => {
              if (response.code === 200) {
                location.reload()
                return response
              }
            })
            .catch(error => {
              console.log(error)
            })

          return apiResponse
        }
      }
    }
  },

  elements(view) {
    let disabled
    return [
      {
        field: 'actions',
        searchable: false,
        class: 'list-actions',
        content: [
          {
            if: ({ row }) => true,
            sequency: 1000,
            render: ({ _this, row }) => {
              disabled = true
              if (![3, 8, 9, 12, 13, 17, 18].includes(row.status_id)) {
                disabled = false
              }

              return {
                component: 'b-button',
                content: {
                  element: 'b-icon',
                  title: 'View',
                  class: 'is-small',
                  icon: 'eye'
                },
                class: 'is-primary is-small rounded-icons',
                outlined: true,
                position: 'is-top-left'
              }
            },
            disabled: () => disabled,
            onClick: ({ _this, row }) => _this.$emit('selectedFileRow', row)
          }
        ]
      },
      {
        component: 'b-button',
        label: view.$t('aprove_selected'),
        position: 'top',
        class: 'custom-table-cell is-small is-success',
        onClick: _this => {
          view.dialog({
            type: 'is-info',
            hasIcon: true,
            title: view.$t('chunk_upload_save_title'),
            message: view.$t('chunk_upload_save_question'),
            onCancel: () => {},
            onConfirm: () => {
              view.handles[this.name].aproveItems()
            }
          })
        }
      },
      {
        component: 'b-button',
        label: view.$t('reject_selected'),
        position: 'top',
        class: 'custom-table-cell is-small is-danger',
        onClick: _this => {
          view.dialog({
            type: 'is-info',
            hasIcon: true,
            title: view.$t('chunk_upload_reject_title'),
            message: view.$t('chunk_upload_reject_question'),
            onCancel: () => {},
            onConfirm: () => {
              view.handles[this.name].rejectItems()
            }
          })
        }
      }
    ]
  }
}

export default {
  apiResponseKeys: ['canned_report_download_history'],

  handle: ({ view, res, responseContentKey }) => {
    const data = res.actions[responseContentKey].route_response.content

    const columns = !data.form.fields
      ? []
      : data.form.fields.map(f => ({
          name: f.field_name,
          field: f.field_name,
          label: f.field_label,
          type: f.field_type,
          id: f.id,
          render: null
        }))

    view.modal({
      component: 'DynamicList',
      props: {
        res,
        params: {
          paginated: false,
          filterable: false,
          title: data.form.form_name,
          data: data.dataset,
          columns
        },
        model: {}
      }
    })
  }
}

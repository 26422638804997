import { downloadFileNew } from '@/services/document'

export default {
  apiResponseKeys: ['getorderinvoices'],

  handle: ({ view, res, responseContentKey }) => {
    const data = res.actions[responseContentKey].route_response.content

    const columns = !data.form
      ? []
      : data.form.fields.map(f => ({
          name: f.field_name,
          field: f.field_name,
          label: f.field_label,
          type: f.field_type,
          id: f.id,
          render:
            f.field_name === 'actions'
              ? {
                  field: 'actions',
                  content: [
                    {
                      if: () => true,
                      sequency: 1,
                      component: 'b-button',
                      content: {
                        element: 'b-icon',
                        title: 'Download',
                        icon: 'download'
                      },
                      class: 'is-primary is-small rounded-icons',
                      onClick: ({ _this, row }) =>
                        downloadFileNew(
                          `invoicing/document-builder/download/${row.document_builder_id}`
                        ).then(response => {
                          if (response.status === 'error')
                            return _this.$buefy.toast.open({
                              message: _this.$t('invoicing-download_error'),
                              position: 'is-top',
                              type: 'is-danger',
                              duration: 3000
                            })

                          if (
                            typeof response.file_name !== 'undefined' &&
                            typeof response.file_content !== 'undefined'
                          ) {
                            const data = Uint8Array.from(
                              atob(response.file_content),
                              c => c.charCodeAt(0)
                            )
                            const blob = new Blob([data], {
                              type: 'octet/stream'
                            })
                            const link = document.createElement('a')
                            link.href = window.URL.createObjectURL(blob)
                            link.style = 'visibility:hidden'
                            link.download = `${row.document_builder_id}.pdf`
                            document.body.appendChild(link)
                            link.click()
                            document.body.removeChild(link)
                            return
                          }

                          const url = window.URL.createObjectURL(response)
                          const a = document.createElement('a')

                          a.href = url
                          a.download = `${row.document_builder_id}.pdf`

                          a.click()
                          window.URL.revokeObjectURL(url)
                        })
                    }
                  ]
                }
              : null
        }))

    view.modal({
      component: 'DynamicList',
      props: {
        res,
        params: {
          paginated: false,
          filterable: false,
          title: data.form.form_name,
          data: data.dataset,
          columns
        },
        model: {}
      }
    })
  }
}

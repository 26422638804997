<!-- fieldAwesome.vue -->
<template>
  <validation-provider
    :name="schema.flabel"
    :rules="schema.validatorCommon"
    :vid="schema.model"
    v-slot="{ errors }"
  >
    <input type="hidden" :value="value" />
    <b-field
      class="custom-field"
      :type="{ 'is-danger': errors[0] }"
      :message="errors[0]"
    >
      <template slot="label">
        <span
          :style="
            schema.hasOwnProperty('properties') &&
            schema.properties.hasOwnProperty('css')
              ? schema.properties.css
              : ''
          "
        >
          <b-icon
            v-if="
              schema.hasOwnProperty('properties') &&
              schema.properties.hasOwnProperty('icon') &&
              schema.properties['icon'] !== ''
            "
            :icon="schema.properties['icon']"
            :size="
              schema.hasOwnProperty('properties') &&
              schema.properties.hasOwnProperty('icon_size')
                ? 'is-' + schema.properties['icon_size']
                : 'is-small'
            "
            :style="
              schema.hasOwnProperty('properties') &&
              schema.properties.hasOwnProperty('icon_color')
                ? 'color:' + schema.properties['icon_color']
                : ''
            "
          >
          </b-icon>
          {{ labelHandler }}
        </span>
        <span class="has-text-danger" v-if="checkRequired">*</span>
      </template>
      <b-field
        :selected="(selected = schema.values.find(v => v.value == value) || {})"
      >
        <p
          v-if="schema.showPreviousValue"
          class="control previous-value-control"
        >
          <b-tooltip
            :label="String(oldValue)"
            type="is-dark"
            :multilined="true"
          >
            <b-button :label="String(oldValue)" disabled expanded />
          </b-tooltip>
        </p>
        <div
          class="input-select"
          :text="selected.text || ''"
          :value="value"
          :data-cy="
            schema.hasOwnProperty('properties') &&
            schema.properties.hasOwnProperty('data-cy')
              ? schema.properties['data-cy']
              : ''
          "
        >
          <multiselect
            @input="onInput"
            @search-change="search"
            :value="schema.values.find(v => v.value == value)"
            :options="options"
            :placeholder="value ? selected.text : schema.placeholder"
            :disabled="disabledHandler"
            :searchable="true"
            :showLabels="false"
            :internal-search="false"
            :showNoResults="true"
            :maxHeight="120"
            label="text"
            track-by="value"
            class=""
          >
            <span slot="noResult">{{ $t('no-results') }}</span>
          </multiselect>
        </div>
        <!--
        <b-select
          v-model="value"
          expanded
          :placeholder="schema.placeholder"
          :disabled="disabledHandler"
          :data-cy="
            schema.hasOwnProperty('properties') &&
            schema.properties.hasOwnProperty('data-cy')
              ? schema.properties['data-cy']
              : ''
          "
          @input="onInput"
        >
          <option value="" key="">-</option>
          <option
            v-for="option in schema.values"
            :value="option.value"
            :key="option.value"
          >
            {{ option.text }}
          </option>
        </b-select>
        -->
      </b-field>
    </b-field>
  </validation-provider>
</template>

<script>
import { abstractField } from 'vue-form-generator'
import { ValidationProvider } from 'vee-validate'
import Multiselect from 'vue-multiselect'
import { callRunDependency } from '@/services/form-builder'

export default {
  mixins: [abstractField],
  components: {
    ValidationProvider,
    Multiselect
  },
  data() {
    return {
      oldValue: '',
      callRunDependency,
      isLoading: false,
      options: []
    }
  },
  created() {
    if (this.value === 0) {
      this.value = null
    }
    this.setDefaultValue()
    callRunDependency(this.schema, this.model, this.value, true)
    this.setPreviousValue()
    this.init()
  },
  methods: {
    init() {
      this.options = [{ value: '', text: '-' }].concat(
        this.$props.schema.values
      )
    },
    onInput(obj) {
      obj = obj || { value: null }
      this.value = obj.value
      callRunDependency(this.schema, this.model, this.value)
      if (this.schema.clear) {
        this.schema.clear(this.model)
      }
    },
    setPreviousValue() {
      if (this.schema.showPreviousValue) {
        for (let i = 0; i < this.schema.values.length; i++) {
          if (this.schema.values[i].value === this.value) {
            this.oldValue = this.schema.values[i].text
          }
        }
      }
    },
    setDefaultValue() {
      if (
        !this.value &&
        this.schema.default &&
        !this.schema.default.includes('url_params:')
      ) {
        this.value = this.schema.default
      }
    },
    search(word = '') {
      if (word === '') return this.init()

      word = word.toUpperCase()

      const match = this.$props.schema.values
        .filter(a => a.text.toUpperCase().indexOf(word) > -1)
        .sort((a, b) => {
          let result = 1

          if (a.text === word) return -10
          result = a.text.toUpperCase() === word ? -1 : result

          return result
        })
      this.options = match
    }
  },
  computed: {
    labelHandler() {
      return this.schema.showLabel ? this.schema.flabel : ''
    },
    checkRequired() {
      const validator = this.schema.validatorCommon
      if (validator) {
        const validatorSplit = validator.split('|')
        if (validatorSplit.includes('required')) {
          // contains required
          return true
        }
      }
      return false
    },
    disabledHandler() {
      let { disabled } = this.schema
      if (this.schema.values.length === 0) {
        disabled = true
      }
      return disabled
    }
  },
  watch: {
    'schema.values': function (values) {
      this.setPreviousValue()
      this.init()
    }
  }
}
</script>

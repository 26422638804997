import axios from 'axios'
import tippy from 'tippy.js'
import { DialogProgrammatic as Dialog, ToastProgrammatic as Toast } from 'buefy'
import { storeToRefs } from 'pinia'
import router from '@/router'
import { api } from '@/services/api'
import { config } from '@/helpers/config'
import { i18n } from '@/plugins/i18n'
import { updateDetails, logout } from '@/services/authentication'
import { uploadDocument, externalUploadDocument } from '@/services/document'
import { usePageStore } from '../stores/pages'
import { useUserStore } from '@/stores/user'
import { useAppStore } from '@/stores/app'

async function generic(endpoint, body, customHeaders = {}) {
  const promise = await api
    .post(endpoint, body, customHeaders)
    .then(response => {
      if (response.data.actions) {
        const actions = Object.values(response.data.actions)

        if (actions[0] && actions[0].route_response) {
          return actions[0].route_response
        }

        return actions
      }

      return response.data
    })
    .catch(error => {
      throw error
    })

  // generic file document upload
  if (
    endpoint === 'order-item/set-public-transition' ||
    endpoint === 'order/set-public-transition'
  ) {
    await externalFileUpload(body, body.model).catch(error =>
      console.log(error)
    )
  } else {
    await fileUpload(body, body.model, promise).catch(error =>
      console.log(error)
    )
  }

  return promise
}

async function exportExcel(flow, body) {
  const userStore = useUserStore()
  const { token } = storeToRefs(userStore)
  const appStore = useAppStore()
  const { clientKey } = storeToRefs(appStore)

  body.client_key = clientKey.value

  if (i18n.locale) {
    body.locale = i18n.locale
  }

  const axiosConfig = {
    responseType: 'blob',
    headers: {
      Authorization: token.value ? `Bearer ${token.value}` : ''
    }
  }

  const response = axios.post(`${config.ApiUrl}${flow}`, body, axiosConfig)

  return response
}

async function getContries() {
  const APIResponse = await api.post('countries')

  if (APIResponse.code === 200) {
    return APIResponse
  }
}

async function getClientLogo(key) {
  const params = { client_key: key }
  const APIResponse = await api.post('applogo', params).catch(() => {
    window.location.href = '/about.html'
  })

  if (APIResponse.code === 200) {
    return APIResponse
  }
}

async function categoryBreakdown() {
  const APIResponse = await api.post('get-category-info')

  if (APIResponse.code === 200) {
    return APIResponse
  }
}

function getPageForms(formKey, routerBack = true) {
  const pageStore = usePageStore()
  const { page } = storeToRefs(pageStore)

  if (!page.value) {
    openDialog('is-danger', 'Error', 'Page not configured.', routerBack)
    return
  }

  if (!page.value.forms) {
    openDialog('is-danger', 'Error', 'Page forms not configured.', routerBack)
    return
  }

  if (!page.value.forms[formKey]) {
    openDialog('is-danger', 'Error', 'Page form not configured.', routerBack)
    return
  }

  return page.value.forms[formKey]
}

function openDialog(type, title, message, routerBack = false) {
  Dialog.alert({
    title,
    message,
    type,
    onConfirm: () => (routerBack ? router.back() : '')
  })
}

function formatValueSelect(values, code = '') {
  const selectData = {}
  const dataArr = []

  for (const value in values) {
    if (value !== undefined) {
      if (values[value].hasOwnProperty('name')) {
        selectData[values[value].id] = {
          value: values[value].id,
          text: values[value].name
        }
      } else if (values[value].hasOwnProperty('city_name')) {
        selectData[values[value].id] = {
          value: values[value].city_id,
          text: values[value].city_name
        }
      } else if (values[value].hasOwnProperty('state_name')) {
        selectData[values[value].id] = {
          value: values[value].state_id,
          text: values[value].state_name
        }
      } else if (values[value].hasOwnProperty('country')) {
        selectData[values[value].id] = {
          value: values[value].id,
          text: values[value].country
        }
      } else if (values[value].hasOwnProperty('app_roles')) {
        selectData[values[value].id] = {
          value: values[value].app_roles.id,
          text: values[value].app_roles.name
        }
      }

      switch (code) {
        case 'COMPANY':
          if (values[value].company !== null) {
            if (
              selectData[values[value].user_company[0].company.id] === undefined
            ) {
              selectData[values[value].user_company[0].company.id] = {
                value: values[value].user_company[0].company.id,
                text: values[value].user_company[0].company.name
              }
              dataArr.push(selectData[values[value].user_company[0].company.id])
            }
          }
          break
        case 'COMPANY_ACTIVE':
          if (values[value].company !== null) {
            if (values[value]) {
              if (values[value].active === 1) {
                selectData[values[value].id] = {
                  value: values[value].id,
                  text: values[value].name
                }
                dataArr.push(selectData[values[value].id])
              }
            }
          }
          break
        case 'CATEGORY':
          selectData[values[value].id] = {
            value: values[value].category.id,
            text: values[value].category.name
          }
          dataArr.push(selectData[values[value].id])
          break
        case 'CATEGORY_SERVICE':
          selectData[values[value].id] = {
            value: values[value].id,
            text: values[value].name
          }
          dataArr.push(selectData[values[value].id])
          break
        case 'CONTAINER':
          selectData[values[value].id] = {
            value: values[value].container.id,
            text: values[value].container.name
          }
          dataArr.push(selectData[values[value].id])
          break
        case 'CONTAINER_SERVICE':
          selectData[values[value].id] = {
            value: values[value].id,
            text: values[value].name
          }
          dataArr.push(selectData[values[value].id])
          break
        default:
          dataArr.push(selectData[values[value].id])
          break
      }
    }
  }

  return dataArr
}

async function fileUpload(form, model, promise = {}) {
  if (form.files) {
    if (
      promise.hasOwnProperty('user') &&
      model === 'User' &&
      form[form.model].id === '0'
    ) {
      form[form.model].id = promise.user.id
    } else if (model === 'Company' && form[form.model].id === '0') {
      form[form.model].id = promise.content
    }
    form.model = model
    await uploadDocument(
      form.files,
      form.model,
      form[form.model].id,
      form.Document.type_id,
      form.Document.document_type_id,
      form.form_slug
    )
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  }
}

async function externalFileUpload(form, model) {
  form.model = model

  if (form.Document) {
    Object.entries(form.Document).forEach(([fieldName, documents]) => {
      const field = form.fields.find(f => f.raw_field_name === fieldName)

      if (documents) {
        let documentTypeId = null
        if (field.properties) {
          Object.entries(field.properties).forEach(([property]) => {
            if (property === 'document_type_id') {
              documentTypeId = field.properties[property]
            }
          })
        }

        const tempFiles = {}
        tempFiles.show = []
        tempFiles.append = new FormData()

        if (!Array.isArray(documents)) {
          documents = [documents]
        }
        if (documents.length) {
          for (let i = 0; i < documents.length; i++) {
            const file = documents[i]
            tempFiles.append.append(`files[${i}]`, file)
            tempFiles.show[i] = documents[i].name
          }

          externalUploadDocument(
            tempFiles,
            form.model,
            form[form.model].id,
            documentTypeId,
            form.form_slug
          )
            .then(response => {
              return response
            })
            .catch(error => {
              throw error
            })
        }
      }
    })
  }
}

function datePickerModalExpander() {
  document
    .querySelectorAll('div.modal.is-active div.dropdown-trigger > div > input')
    .forEach(e => {
      e.classList.remove('is-small')
      // eslint-disable-next-line
      e.addEventListener('click', function() {
        const modalBodyHeight = this.closest('.modal-card-body').offsetHeight
        if (modalBodyHeight <= 300) {
          this.closest('.modal-card-body').style.height = `${
            modalBodyHeight + 300
          }px`
        }
      })
    })
}

let activeDialog = null

const errorPromptHandler = {
  activeDialog: null,

  handle: async toThrow => {
    if (toThrow.message && toThrow.message === 'canceled') return

    if (activeDialog && activeDialog.isActive) {
      activeDialog.message = activeDialog.message.concat(
        ` and <b id="error-modal-code">${toThrow.code}</b>`
      )

      errorPromptHandler.copyClipboard()

      return
    }

    if (!toThrow.message && !toThrow.error) {
      activeDialog = await Dialog.alert({
        title: i18n.t('error-modal-title'),
        message: `${i18n.t('error-modal-message')} <b id="error-modal-code">${
          toThrow.code
        }</b>`,
        type: 'is-danger',
        hasIcon: true,
        icon: 'alert-circle',
        onConfirm: () => {
          activeDialog = null
          window.location.href = '/'
        }
      })

      errorPromptHandler.copyClipboard()

      return
    }

    const appStore = useAppStore()
    const userStore = useUserStore()
    const { clientKey } = storeToRefs(appStore)

    if (toThrow.status === 403) {
      Toast.open({
        duration: 10000,
        message: i18n.t('unauthorized'),
        type: 'is-danger',
        pauseOnHover: true
      })
    }

    if (toThrow.status === 401 && userStore.isImpersonating) {
      Toast.open({
        duration: 10000,
        message: i18n.t('user-impersonate-unauthenticated'),
        type: 'is-danger',
        pauseOnHover: true
      })
      userStore.unimpseronate()
      await updateDetails()
      router.push('/').catch(() => {})
      return
    }

    Toast.open({
      message: toThrow.message || toThrow.error,
      duration: toThrow.toastDuration ?? 5000,
      type: toThrow.toastType ?? 'is-danger',
      pauseOnHover: toThrow.toastPauseOnHover ?? true
    })

    if (toThrow.status === 401) {
      router
        .replace({
          name: 'login',
          params: {
            key: clientKey.value
          }
        })
        .catch(() => {})

      logout()
    }
  },

  copyClipboard: () => {
    tippy('#error-modal-code', {
      content: i18n.t('error-modal-copy-clipboard')
    })

    document.querySelector('#error-modal-code').addEventListener('click', e => {
      navigator.clipboard.writeText(e.target.innerText)
      tippy('#error-modal-code', {
        content: i18n.t('error-modal-copied-clipboard'),
        delay: [null, 500]
      })
      e.target._tippy.show()
      setTimeout(() => e.target._tippy.disable(), 1500)
    })
  }
}

export {
  generic,
  exportExcel,
  getContries,
  getClientLogo,
  formatValueSelect,
  categoryBreakdown,
  getPageForms,
  openDialog,
  errorPromptHandler,
  externalFileUpload,
  datePickerModalExpander
}

import Vue from 'vue'
import { storeToRefs } from 'pinia'
import VueI18n from 'vue-i18n'
import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import { config } from '@/helpers/config'
import mt from '@/locales/custom_validation_translations/mt.json'
import no from '@/locales/custom_validation_translations/no.json'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '../stores/user'

Vue.use(VueI18n)

const messages = {}
const loadedLanguages = ['en']

export const i18n = new VueI18n({
  silentTranslationWarn: true,
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en'
})

function setI18nLanguage(locale) {
  i18n.locale = locale
  axios.defaults.headers.common['Accept-Language'] = locale
  document.querySelector('html').setAttribute('lang', locale)
  return locale
}

function getTranslations(locale) {
  const appStore = useAppStore()
  const { clientKey } = storeToRefs(appStore)

  if (
    !clientKey.value ||
    (clientKey.value && typeof clientKey.value === 'undefined')
  ) {
    return Promise.resolve({})
  }

  const messages = axios
    .get(`${config.ApiUrl}app-translations/${clientKey.value}/${locale}`, {
      headers: {
        'Cache-Control': 'max-age=0'
      }
    })
    .then(response => {
      return response.data
    })

  return messages
}

function buildTranslationsObj(translations) {
  const validationMessages = require.context(
    'vee-validate/dist/locale',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  )

  const messages = {}

  Object.entries(translations).forEach(([locale, values]) => {
    messages[locale] = values

    const realLocales = {
      cz: 'cs',
      dk: 'da',
      ee: 'et',
      se: 'sv',
      pt: 'pt_PT'
    }

    const realLocale = realLocales[locale] || locale

    switch (realLocale) {
      case 'mt':
        messages[locale].validation = mt.messages
        break
      case 'no':
        messages[locale].validation = no.messages
        break
      default:
        messages[locale].validation = validationMessages(
          `./${realLocale}.json`
        ).messages
        break
    }
  })

  return messages
}

export function loadMessages() {
  const appStore = useAppStore()
  const userStore = useUserStore()

  const { language } = storeToRefs(userStore)
  const { translations } = storeToRefs(appStore)

  const locale = language.value ?? 'en'

  if (
    translations.value === {} ||
    translations.value == null ||
    isEmpty(translations.value[locale])
  ) {
    const loadedTranslations = getTranslations(locale).then(messages => {
      i18n.locale = locale

      const messagesByLocale = buildTranslationsObj({ [locale]: messages })

      appStore.$patch({
        translations: messagesByLocale
      })

      i18n.setLocaleMessage(locale, messagesByLocale[locale])
    })

    return loadedTranslations
  }

  const localStorageTranslations = buildTranslationsObj(translations.value)
  i18n.setLocaleMessage(locale, localStorageTranslations[locale])

  return localStorageTranslations
}

export function loadLanguageAsync(locale, force = false) {
  const appStore = useAppStore()

  if (i18n.locale === locale && !force) {
    return Promise.resolve(setI18nLanguage(locale))
  }

  if (loadedLanguages.includes(locale) && !force) {
    i18n.setLocaleMessage(locale, messages[locale])
    return Promise.resolve(setI18nLanguage(locale))
  }

  return getTranslations(locale).then(messages => {
    i18n.locale = locale
    const messagesByLocale = buildTranslationsObj({ [locale]: messages })

    appStore.$patch({
      translations: messagesByLocale
    })

    i18n.setLocaleMessage(locale, messagesByLocale[locale])
    loadedLanguages.push(locale)
    return setI18nLanguage(locale)
  })
}

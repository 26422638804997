<template>
  <section class="order-initial-setup">
    <div class="order-header-information">
      <div class="top-header-information">
        <div class="columns">
          <div
            v-for="child in schema.childs"
            v-bind:key="child.id"
            :class="{
              column:
                child.hasOwnProperty('properties') &&
                child.properties.hasOwnProperty('position') &&
                child.properties.position === 'top'
            }"
          >
            <field-header-property
              :prop="child"
              :model="model"
              position="top"
            />
          </div>
        </div>
      </div>
      <div class="bottom-header-information">
        <div class="columns">
          <div
            v-for="child in schema.childs"
            v-bind:key="child.id"
            :class="{
              column:
                child.hasOwnProperty('properties') &&
                child.properties.hasOwnProperty('position') &&
                child.properties.position === 'bottom'
            }"
          >
            <field-header-property
              :prop="child"
              :model="model"
              position="bottom"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { abstractField } from 'vue-form-generator'
import fieldHeaderProperty from '@/components/fields/fieldHeaderProperty'

export default {
  mixins: [abstractField],
  components: {
    fieldHeaderProperty
  }
}
</script>

import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      name: null,
      role: null,
      id: null,
      email: null,
      roleCode: null,
      avatar: null,
      language: null,
      token: null,
      impersonateUser: {
        id: null,
        token: null,
        name: null
      },
      notifications: [],
      userImpersonateRoles: [],
      numberOfImpersonateRoles: 0,
      previousUserId: null,
      detailsVersion: null
    }
  },
  actions: {
    logout() {
      this.$reset()
    },
    impseronate(impersonateUser) {
      this.impersonateUser = impersonateUser
    },
    unimpseronate() {
      this.impersonateUser = {
        id: null,
        token: null,
        name: null
      }
    }
  },
  getters: {
    getAccessToken: state => state.impersonateUser.token ?? state.token,
    isImpersonating: state => state.impersonateUser.token != null,
    getUserImpersonateRoles: state => state.userImpersonateRoles
  },
  persist: true
})

import omit from 'lodash/omit'
import mapValues from 'lodash/mapValues'
import mapKeys from 'lodash/mapKeys'
import remove from 'lodash/remove'
import { typeBulkFilters } from '@/data/fields.js'

export default {
  moduleLink: 'list',
  init(view) {
    view.$set(view.params[this.name], 'active', false)

    view.$set(view.model[this.name], 'sm_flow_id', null)
    view.$set(view.model[this.name], 'state', null)
    view.$set(view.model[this.name], 'transition', null)
    view.$set(view.model[this.name], 'checkedRows', 0)
  },
  handles(view) {
    return {
      open: () =>
        view.api({
          module: this,
          method: 'post',
          endPoint: '/sm-flows',
          body: {
            model: view.model[this.name].model,
            slug: view.modules.list.forms[0].slug
          },
          callback: res => {
            view.elements[this.name][0].options = Object.keys(res.data).map(
              k => ({
                value: k,
                label: res.data[k]
              })
            )

            if (view.elements[this.name][0].options.length === 1) {
              view.model[this.name].sm_flow_id = {
                value: view.elements[this.name][0].options[0].value,
                label: view.elements[this.name][0].options[0].label
              }

              this.handles(view).getSmFlows({
                smFlowIdValue: view.elements[this.name][0].options[0].value
              })
            }
          }
        }),
      save: () => {
        const transitionConfig =
          view.model[this.name].transitions[
            view.model[this.name].transition.value
          ] ?? view.model[this.name].transition.value

        const formParams = transitionConfig.form_slug
          ? {
              type: 'REGULAR_FORM',
              slug: transitionConfig.form_slug
            }
          : {}

        const bodyParams = mapValues(
          omit(
            view.model[this.name],
            'transitions',
            'checkedRows',
            'sm_flow_id',
            'state'
          ),
          value => (value && value.value ? value.value : value)
        )

        view.model[this.moduleLink].modal = bodyParams

        view.dialog({
          hasIcon: true,
          title: transitionConfig.translation,
          confirmText: transitionConfig.translation,
          message: view.$i18n.t('sm-confirm-flow-bulk', {
            transition: transitionConfig.translation,
            elementsSelect: view.params[this.moduleLink].checkedRows.length
          }),
          onConfirm: () => {
            view.isLoading[this.moduleLink] = true
            view.handles[this.moduleLink].checkModal({
              dialog: false,
              endpoint: '/set-bulk-transitions',
              params: {
                ...bodyParams,
                bulkEndpoint: true,
                model: view.model[this.name].model,
                ids: view.params[this.moduleLink].checkedRows.map(c => c.id),
                form: {
                  ...formParams
                }
              }
            })
            view.isLoading[this.moduleLink] = false
            // eslint-disable-next-line no-unused-expressions
            view.handles[this.name].cancel()
          }
        })
      },
      cancel: () => {
        view.model[this.moduleLink].search = {}
        view.model[this.moduleLink].bulkSearch = {}
        view.modules[this.moduleLink].uses.init(view)
        view.model[this.name] = { model: view.model[this.name].model }

        remove(view.elements[this.name], element => {
          if (element.bulkFilter) {
            delete view.model[this.name][element.name]
            return true
          }

          return false
        })

        window.transiting = {}

        this.init(view)
      },
      switch: () => {
        view.params[this.moduleLink].checkable = Boolean(
          view.model[this.name].sm_flow_id &&
            view.model[this.name].state &&
            view.model[this.name].transition
        )
      },
      filterResults: search => {
        view.params[this.moduleLink].checkedRows = []

        view.params[this.moduleLink].checkable =
          !!view.model[this.name].transition

        view.model[this.moduleLink].bulkSearch = { ...search }

        view.modules[this.moduleLink].uses.handles(view).startBulkSearch()
      },
      getSmFlows: value => {
        view.api({
          module: this,
          method: 'post',
          endPoint: '/states-by-flow',
          body: {
            sm_flow_id: value.smFlowIdValue
          },
          callback: res => {
            view.elements[this.name][1].options = Object.keys(res.data).map(
              k => ({ value: k, label: res.data[k] })
            )
            if (view.elements[this.name][1].options.length === 1) {
              view.model[this.name].state = {
                value: view.elements[this.name][1].options[0].value,
                label: view.elements[this.name][1].options[0].label
              }

              this.handles(view).getTransitionsByState({
                smFlowIdValue: view.model[this.name].sm_flow_id.value,
                stateValue: view.model[this.name].state.value
              })
            }
          }
        })
      },
      getTransitionsByState: value => {
        view.api({
          module: this,
          method: 'post',
          endPoint: '/transitions-by-state',
          body: {
            sm_flow_id: value.smFlowIdValue,
            state: value.stateValue,
            slug: view.modules.list.forms[0].slug
          },
          callback: res => {
            view.model[this.name].transitions = res.data
            view.elements[this.name][2].options = Object.keys(res.data).map(
              k => ({
                value: k,
                label: res.data[k].translation,
                action: res.data[k]
              })
            )

            if (view.elements[this.name][2].options.length === 1) {
              view.model[this.name].transition = {
                value: view.elements[this.name][2].options[0].value,
                label: view.elements[this.name][2].options[0].label
              }

              this.handles(view).filterResults({
                state: view.model[this.name].state.value,
                sm_flow_id: view.model[this.name].sm_flow_id.value
              })
            }
          }
        })
      }
    }
  },
  elements(view) {
    return [
      {
        element: 'select',
        name: 'sm_flow_id',
        ...typeBulkFilters.find(f => ['select'].includes(f.element)),
        placeholder: view.$i18n.t('bulk-transitions-select-flow'),
        options: [],
        onChange: option => {
          if (!option) return

          view.model[this.name].state = null
          view.model[this.name].transition = null
          view.params[this.moduleLink].checkable = false
          view.params[this.moduleLink].checkedRows = []

          this.handles(view).getSmFlows({
            smFlowIdValue: option.value
          })
        }
      },
      {
        element: 'select',
        name: 'state',
        ...typeBulkFilters.find(f => ['select'].includes(f.element)),
        placeholder: view.$i18n.t('bulk-transitions-select-state'),
        options: [],
        loading: () => view.params[this.name].loading,
        disabled: () => !view.model[this.name].sm_flow_id,
        onChange: () => {
          view.model[this.name].transition = null
          view.params[this.moduleLink].checkable = false
          view.params[this.moduleLink].checkedRows = []

          this.handles(view).getTransitionsByState({
            smFlowIdValue: view.model[this.name].sm_flow_id.value,
            stateValue: view.model[this.name].state.value
          })
        }
      },
      {
        element: 'select',
        name: 'transition',
        ...typeBulkFilters.find(f => ['select'].includes(f.element)),
        placeholder: view.$i18n.t('bulk-transitions-select-transition'),
        options: [],
        disabled: () => !view.model[this.name].state,
        onChange: () => {
          const transitionConfig =
            view.model[this.name].transitions[
              view.model[this.name].transition
            ] ?? view.model[this.name].transition

          remove(view.elements[this.name], element => {
            if (element.bulkFilter) {
              delete view.model[this.name][element.name]
              return true
            }

            return false
          })

          transitionConfig.action.bulk_filters.forEach((filter, index) => {
            const isLast =
              index === transitionConfig.action.bulk_filters.length - 1

            const isPreviousValueEmpty = () =>
              transitionConfig.action.bulk_filters[index - 1] &&
              !view.model[this.name][
                transitionConfig.action.bulk_filters[index - 1].field_model_name
              ]

            view.elements[this.name].push({
              ...typeBulkFilters.find(f =>
                [filter.field_type].includes(f.element)
              ),
              name: filter.field_model_name,
              placeholder: filter.field_label,
              disabled: isPreviousValueEmpty,
              options: filter.options,
              bulkFilter: true,
              onChange: () => {
                if (!isLast) return

                const searchParams = mapValues(
                  mapKeys(
                    omit(
                      view.model[this.name],
                      'model',
                      'transitions',
                      'transition',
                      'checkedRows'
                    ),
                    (value, key) =>
                      key.includes('.') ? key.split('.')[1] : key
                  ),
                  value => (value && value.value ? value.value : value)
                )

                this.handles(view).filterResults(searchParams)
              }
            })
          })

          if (transitionConfig.action.bulk_filters.length) return

          this.handles(view).filterResults({
            state: view.model[this.name].state.value,
            sm_flow_id: view.model[this.name].sm_flow_id.value
          })
        }
      },
      {
        element: 'button',
        label: view.$i18n.t('returns-button-cancel'),
        class: 'is-light',
        onClick: view.handles[this.name].cancel,
        disabled: () => view.isLoading[this.name]
      },
      {
        element: 'button',
        label: view.$i18n.t('bulk-transitions-set-transition'),
        class: 'is-primary',
        onClick: view.handles[this.name].save,
        disabled: () =>
          !view.model[this.name].sm_flow_id ||
          !view.model[this.name].state ||
          !view.model[this.name].transition ||
          !view.params[this.moduleLink].checkedRows.length
      }
    ]
  }
}

export default function actions({
  view,
  row,
  usesStateMachine,
  modelSlugFeature
}) {
  const actions = []

  if (view.$checkFeature('list-invoice-popup', 'v1')) {
    actions.push({
      endpoint: '/get-order-invoices',
      name: 'order-invoices',
      class: usesStateMachine ? 'border-top' : '',
      dialog: false,
      params: {
        id: row.id || row.order_id,
        list_form: true,
        form: 'order-invoices-list',
        sort_by: 'invoice_id.desc',
        // modelName: view.model.ordersList.model
        modelName: view.model.list.model
      }
    })
  }

  if (
    view.$checkFeature('list-order-item-transition-history', 'v1') &&
    modelSlugFeature !== null &&
    modelSlugFeature === 'order-item'
  ) {
    actions.push({
      endpoint: '/order-item-transition-history',
      name: 'order-item-transition-history',
      class: usesStateMachine ? 'border-top' : '',
      dialog: false,
      params: {
        id: row.id || row.order_id,
        list_form: true,
        form: 'order-item-transition-history-list',
        // modelName: view.model.ordersList.model
        modelName: view.model.list.model
      }
    })
  }

  if (
    view.$checkFeature('list-order-transition-history', 'v1') &&
    modelSlugFeature !== null &&
    modelSlugFeature === 'order'
  ) {
    actions.push({
      endpoint: '/order-transition-history',
      name: 'order-transition-history',
      class: usesStateMachine ? 'border-top' : '',
      dialog: false,
      params: {
        id: row.id || row.order_id,
        list_form: true,
        form: 'order-transition-history-list',
        // modelName: view.model.ordersList.model
        modelName: view.model.list.model
      }
    })
  }

  if (view.$checkFeature('price-matrix-preview', 'v1')) {
    actions.push({
      endpoint: '/price-matrix-preview-feature',
      name: 'price-matrix-preview-feature',
      class: usesStateMachine ? 'border-top' : '',
      dialog: false,
      params: {
        id: row.id,
        list_form: true,
        form: 'price-matrix-preview-list',
        // modelName: view.model.ordersList.model
        modelName: view.model.list.model
      }
    })
  }

  if (view.$checkFeature('price-matrix-preview-order-information', 'v1')) {
    actions.push({
      endpoint: '/price-matrix-preview-feature',
      name: 'price-matrix-preview-order-information-feature',
      class: usesStateMachine ? 'border-top' : '',
      dialog: false,
      params: {
        id: row.id,
        list_form: true,
        form: 'price-matrix-preview-list',
        modelName: view.model.list.model,
        show_order_information: true
      }
    })
  }

  if (view.$checkFeature('price-matrix-preview-show-all-results', 'v1')) {
    actions.push({
      endpoint: '/price-matrix-preview-feature',
      name: 'price-matrix-preview-show-all-results-feature',
      class: usesStateMachine ? 'border-top' : '',
      dialog: false,
      params: {
        order_id: row.id,
        list_form: true,
        form: 'price-matrix-preview-show-all-results-list',
        modelName: view.model.list.model,
        price_matrix_options: {
          frontend_test_page: false,
          from_test_page: false,
          debug: false,
          show_all_results: true
        }
      }
    })
  }

  if (
    view.$checkFeature(
      'price-matrix-preview-order-information-show-all-results',
      'v1'
    )
  ) {
    actions.push({
      endpoint: '/price-matrix-preview-feature',
      name: 'price-matrix-preview-show-all-results-order-information-feature',
      class: usesStateMachine ? 'border-top' : '',
      dialog: false,
      params: {
        order_id: row.id,
        list_form: true,
        form: 'price-matrix-preview-show-all-results-list',
        modelName: view.model.list.model,
        show_order_information: true,
        price_matrix_options: {
          frontend_test_page: false,
          from_test_page: false,
          debug: false,
          show_all_results: true
        }
      }
    })
  }

  if (view.$checkFeature('price-matrix-preview-debug', 'v1')) {
    actions.push({
      endpoint: '/price-matrix-preview-feature',
      name: 'price-matrix-preview-debug-feature',
      class: usesStateMachine ? 'border-top' : '',
      dialog: false,
      params: {
        order_id: row.id,
        flow: '/order-update-insert-wf',
        form: {
          slug: 'price-matrix-preview-debug-rule',
          type: 'EDIT_FORM'
        },
        price_matrix_options: {
          frontend_test_page: true,
          from_test_page: false,
          debug: true
        },
        form_slug: 'price-matrix-preview-debug'
      }
    })
  }

  if (view.$checkFeature('price-matrix-preview-debug-items', 'v1')) {
    actions.push({
      endpoint: '/price-matrix-preview-feature',
      name: 'price-matrix-preview-debug-feature-items',
      class: usesStateMachine ? 'border-top' : '',
      dialog: false,
      params: {
        order_item_id: row.id,
        order_id: row.order_id,
        flow: '/order-item-update-insert-wf',
        form: {
          slug: 'price-matrix-preview-debug-rule',
          type: 'EDIT_FORM'
        },
        price_matrix_options: {
          frontend_test_page: true,
          from_test_page: false,
          debug: true
        },
        form_slug: 'price-matrix-preview-debug'
      }
    })
  }

  if (
    view.$checkFeature('transition-history-list', 'v1') &&
    modelSlugFeature !== null
  ) {
    actions.push({
      endpoint: '/transition-history-list',
      name: 'list-transition-history',
      class: usesStateMachine ? 'border-top' : '',
      dialog: false,
      params: {
        id: row.id || row.order_id,
        list_form: true,
        form: `${modelSlugFeature}-transition-history-list`,
        // modelName: view.model.ordersList.model
        modelName: view.model.list.model
      }
    })
  }

  if (
    view.$checkFeature('declaration-history-list', 'v1') &&
    modelSlugFeature !== null
  ) {
    actions.push({
      endpoint: '/get-declaration-history',
      name: 'declaration-history-list',
      class: usesStateMachine ? 'border-top' : '',
      dialog: false,
      params: {
        original_declaration_id: row.id,
        form: `declaration-history-list`
      }
    })
  }

  return actions
}

import axios from 'axios'
import set from 'lodash/set'
import pickBy from 'lodash/pickBy'
import router from '@/router'

export const cancelRequest = {
  onGoingRequests: {},
  create: () => axios.CancelToken.source(),
  add: (module, route, cancelSource) =>
    set(cancelRequest.onGoingRequests, `${module}.${route}`, cancelSource),
  check: (cancelSource, module) => {
    if (!module) return

    const toCancel =
      cancelRequest.onGoingRequests[module] &&
      !cancelRequest.onGoingRequests[module][router.history.current.path]
        ? Object.values(
            pickBy(
              cancelRequest.onGoingRequests[module],
              (v, i) => i !== router.history.current.path
            )
          )
        : []

    cancelRequest.cancel(toCancel, module)

    window.onpopstate = () => {
      cancelRequest.cancel(toCancel, module)
    }

    cancelRequest.add(
      !toCancel.length ? module : null,
      router.history.current.path,
      cancelSource
    )
  },

  cancel: (toCancel, module) => {
    if (!toCancel.length) return

    toCancel.forEach(e => e.cancel('canceled'))
    delete cancelRequest.onGoingRequests[module]
  },

  clear: module => {
    if (!module) return
    delete cancelRequest.onGoingRequests[module]
  }
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-field',{staticClass:"custom-field review-fields",class:{ 'field-word-break': _vm.schema.disabled },style:(_vm.schema.hasOwnProperty('properties') &&
    _vm.schema.properties.hasOwnProperty('css')
      ? _vm.schema.properties.css
      : ''),attrs:{"horizontal":_vm.schema.showLabel > 0,"label":_vm.labelHandler,"hidden":_vm.schema.hidden}},[(
      _vm.schema.hasOwnProperty('properties') &&
      _vm.schema.properties.hasOwnProperty('before')
    )?_c('span',{domProps:{"innerHTML":_vm._s(_vm.handleBeforeAfter(_vm.schema.properties.before))}}):_vm._e(),_vm._v(" "+_vm._s(_vm.mask)+" "),(
      _vm.schema.hasOwnProperty('properties') &&
      _vm.schema.properties.hasOwnProperty('after')
    )?_c('span',{staticStyle:{"margin-left":"3px"},domProps:{"innerHTML":_vm._s(_vm.handleBeforeAfter(_vm.schema.properties.after))}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
import { i18n } from '@/plugins/i18n'
import { getValue } from '@/services/form-builder'

/* FORM HELPER FuNCTIONS */

export { maskValue, handleBeforeAfter }

// eslint-disable-next-line default-param-last
function maskValue(value, type = null, options = [], objectProperty) {
  if (type !== null) {
    switch (type) {
      case 'INTEGER':
        if (options.length > 0) {
          for (const option in options) {
            if (parseInt(value) === parseInt(options[option].value)) {
              value = options[option].text
            }
          }
        }
        break
      case 'ARRAY':
        const aux = []
        for (const item in value) {
          if (options.length > 0) {
            aux.push(options[value[item]].text)
          } else {
            aux.push(value[item])
          }
        }
        value = aux.join(', ')
        break
      case 'ARRAY_OBJECT':
        const auxArrayObject = []
        if (value && Array.isArray(value)) {
          if (objectProperty !== null && objectProperty !== '') {
            for (const item in value) {
              auxArrayObject.push(value[item][objectProperty])
            }
            value = auxArrayObject.join(', ')
          }
        } else if (value && value.hasOwnProperty(objectProperty)) {
          value = value[objectProperty]
        } else if (value === null || value === undefined) {
          value = ''
        }
        break
      case 'BOOLEAN':
        if (value > 0) {
          value = i18n.tc('mask-boolean-true')
        } else {
          value = i18n.tc('mask-boolean-false')
        }
        break
      default:
    }
  }

  return value
}

function handleBeforeAfter(value, model = {}) {
  let final = ''
  let param = null
  if (value.search('||') >= 0) {
    const split = value.split('||')
    for (const splitAux in split) {
      let modelDS = false
      if (split[splitAux].search('::') >= 0) {
        const splitProp = split[splitAux].split('::')
        const element = splitProp[0]
        let value_ = splitProp[1]

        if (value_.match(/{([^}]+)}/g) !== null) {
          param = value_.match(/{([^}]+)}/g)[0].replace(/.*\{|\}/gi, '')
          if (
            getValue(model, param) !== null &&
            getValue(model, param) !== undefined
          ) {
            modelDS = true
            value_ = value_.replace(/{([^}]+)}/g, getValue(model, param))
          }
        }

        switch (element) {
          case 'img':
            if (modelDS) {
              final += `<img style="margin-right: 5px;" src="${require(`../../assets/${value_.toLowerCase()}`)}" />` // eslint-disable-line import/no-dynamic-require
            } else {
              final += `<img style="margin-right: 5px;" src="${value_}" />`
            }
            break
          default:
            final += value_
            break
        }
      }
    }
  }

  return final
}

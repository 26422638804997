<template>
  <div
    v-if="
      prop.hasOwnProperty('properties') &&
      prop.properties.hasOwnProperty('position') &&
      prop.properties['position'] === position
    "
  >
    <b-icon
      v-if="
        prop.hasOwnProperty('properties') &&
        prop.properties.hasOwnProperty('icon')
      "
      :icon="prop.properties['icon']"
      :size="
        prop.hasOwnProperty('properties') &&
        prop.properties.hasOwnProperty('size')
          ? 'is-' + prop.properties['size']
          : 'is-small'
      "
    >
    </b-icon>

    <span>{{ prop.flabel }}</span>

    <p>
      <vue-form-generator :schema="schemaY" :model="model"></vue-form-generator>
    </p>
  </div>
</template>
<script>
import { getValue } from '@/services/form-builder'
import { maskValue } from '@/services/helpers/form-builder'

export default {
  name: 'fieldHeaderProperty',
  props: ['prop', 'position', 'model'],
  data() {
    return {
      schemaY: {
        fields: [this.prop]
      },
      modelX: {
        id: null
      }
    }
  },
  methods: {
    handleValue(name, dataType, options, objectProperty) {
      return maskValue(
        getValue(this.model, name) || '',
        dataType,
        options,
        objectProperty
      )
    }
  }
}
</script>

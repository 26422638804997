<template>
  <b-field horizontal :label="schema.flabel" class="custom-field review-fields">
    {{ value }}
  </b-field>
</template>
<script>
import { abstractField } from 'vue-form-generator'

export default {
  mixins: [abstractField]
}
</script>

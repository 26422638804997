/**
 * @param {*} type
 * Helper : Parse the value based on the data type
 */

export default function parseValueByType(type, data) {
  let parsedValue = data

  if (type !== undefined && type !== null) {
    switch (type.toUpperCase()) {
      case 'STRING':
        parsedValue = String(data)
        break
      case 'INTEGER':
        // parsedValue = parseInt(data)
        break
      case 'FLOAT':
        // parsedValue = parseFloat(data)
        break
      case 'BOOLEAN':
        parsedValue = data === '1' || data === 'true' || data === 1
        break
      case 'DATE':
        break
      case 'DATETIME':
        break
      case 'TIMESTAMP':
        if (data !== null) {
          parsedValue = new Date(data)
        }
        break
    }
  }

  return parsedValue
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('validation-provider',{directives:[{name:"show",rawName:"v-show",value:(!_vm.schema.hidden),expression:"!schema.hidden"}],class:{ 'hide-class': _vm.schema.hidden },attrs:{"name":_vm.schema.flabel,"rules":_vm.schema.validatorCommon,"customMessages":_vm.messages,"vid":_vm.schema.model},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-field',{staticClass:"custom-field",attrs:{"type":{ 'is-danger': errors[0] },"message":errors[0],"expanded":""}},[_c('template',{slot:"label"},[_c('span',{style:(_vm.schema.hasOwnProperty('properties') &&
          _vm.schema.properties.hasOwnProperty('css')
            ? _vm.schema.properties.css
            : '')},[(
            _vm.schema.hasOwnProperty('properties') &&
            _vm.schema.properties.hasOwnProperty('icon') &&
            _vm.schema.properties['icon'] !== ''
          )?_c('b-icon',{style:(_vm.schema.hasOwnProperty('properties') &&
            _vm.schema.properties.hasOwnProperty('icon_color')
              ? 'color:' + _vm.schema.properties['icon_color']
              : ''),attrs:{"icon":_vm.schema.properties['icon'],"size":_vm.schema.hasOwnProperty('properties') &&
            _vm.schema.properties.hasOwnProperty('icon_size')
              ? 'is-' + _vm.schema.properties['icon_size']
              : 'is-small'}}):_vm._e(),_vm._v(" "+_vm._s(_vm.labelHandler)+" ")],1),(_vm.checkRequired)?_c('span',{staticClass:"has-text-danger"},[_vm._v("*")]):_vm._e()]),_c('b-field',[(_vm.schema.showPreviousValue && _vm.schema.inputType !== 'hidden')?_c('p',{staticClass:"control previous-value-control"},[_c('b-button',{attrs:{"label":String(_vm.oldValue),"disabled":"","expanded":""}})],1):_vm._e(),_c('b-input',{directives:[{name:"cleave",rawName:"v-cleave",value:(_vm.mask),expression:"mask"}],ref:"input",attrs:{"expanded":"","type":_vm.schema.inputType,"color":"dark","placeholder":_vm.schema.placeholder,"hidden":_vm.schema.hidden,"disabled":_vm.disabled,"maxlength":_vm.schema.flength,"data-cy":_vm.schema.hasOwnProperty('properties') &&
          _vm.schema.properties.hasOwnProperty('data-cy')
            ? _vm.schema.properties['data-cy']
            : ''},on:{"input":function($event){return _vm.callRunDependency(_vm.schema, _vm.model, _vm.value)},"blur":function($event){_vm.mask ? _vm.mask.focusOut($event) : null}},nativeOn:{"input":function($event){return _vm.onInput.apply(null, arguments)}},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)],2)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <validation-provider
    :ref="schema.id"
    :rules="(schema.schema.validatorCommon || {}).rule"
    v-if="containerActive"
  >
    <transition-group name="fade">
      <div :key="schema.id" class="column">
        <div class="card materials-card" v-if="checkContainerValues">
          <div class="card-content">
            <div v-if="!model.hasOwnProperty('Period') && !isCardModalActive">
              <template v-for="(detail, index) in getModelSorted(model)">
                <div
                  v-if="!detail.field.hidden"
                  :key="`label-${index}`"
                  class="text-info has-text-left"
                >
                  <div :render="(render = getFieldValue(detail))">
                    <p v-if="render">
                      <span>
                        <b>{{ detail.field.flabel }}:</b>
                        {{ render }}
                      </span>
                    </p>
                  </div>
                </div>
              </template>
            </div>
            <div
              v-else-if="model.hasOwnProperty('company_type_id')"
              class="text-info"
            >
              <h2
                v-if="
                  model.hasOwnProperty('company_type_id') &&
                  model.company_type_id > 0
                "
                v-html="
                  getLookupText(model.company_type_id, '.company_type_id')
                "
              ></h2>
              <h3
                v-if="
                  model.hasOwnProperty('document_type_id') &&
                  model.document_type_id > 0
                "
                v-html="
                  getLookupText(model.document_type_id, '.document_type_id')
                "
              ></h3>
              <h3
                v-if="model.hasOwnProperty('prefix') && model.prefix != ''"
                v-html="getLookupText(model.prefix, '.prefix', true)"
              ></h3>
            </div>
            <div v-else-if="model.hasOwnProperty('Period')" class="text-info">
              <h2
                v-if="
                  model.Period.hasOwnProperty('company_type_id') &&
                  model.Period.company_type_id > 0
                "
                v-html="
                  getLookupText(
                    model.Period.company_type_id,
                    'Period.company_type_id'
                  )
                "
              ></h2>
              <h3
                v-if="
                  model.Period.hasOwnProperty('document_type_id') &&
                  model.Period.document_type_id > 0
                "
                v-html="
                  getLookupText(
                    model.Period.document_type_id,
                    'Period.document_type_id'
                  )
                "
              ></h3>
              <h3
                v-if="
                  model.Period.hasOwnProperty('prefix') &&
                  model.Period.prefix != ''
                "
                v-html="
                  getLookupText(model.Period.prefix, 'Period.prefix', false)
                "
              ></h3>
            </div>
          </div>
          <div v-if="schema.form !== 'OVERVIEW_FORM'" class="actions-order">
            <div @click="isCardModalActive = true" class="action">
              <span class="mdi mdi-24px mdi-pencil"></span>
            </div>
            <div @click="isDeleteModalActive = true" class="action">
              <span class="mdi mdi-24px mdi-close-circle order-delete"></span>
            </div>
          </div>
        </div>
      </div>
    </transition-group>
    <b-modal
      :active.sync="isCardModalActive"
      :width="400"
      :can-cancel="false"
      scroll="keep"
    >
      <div class="card">
        <div class="card-content custom-card-content">
          <validation-observer ref="form" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(confirm)" ref="form">
              <slot></slot>
              <footer class="materials-modal-footer">
                <button
                  type="submit"
                  class="button is-primary is-fullwidth materials-modal-footer-buttons"
                  :data-cy="'order_item_update_' + currentFormId"
                >
                  {{ $t('button-update') }}
                </button>
                <b-button
                  type="is-danger is-fullwidth"
                  @click="isDeleteModalActive = true"
                  :data-cy="'order_item_remove_' + currentFormId"
                  >{{ $t('button-remove') }}</b-button
                >
              </footer>
            </form>
          </validation-observer>
        </div>
      </div>
    </b-modal>
    <b-modal :active.sync="isDeleteModalActive" :width="400" scroll="keep">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ $t('order-item-remove') }}</p>
        </header>
        <section class="modal-card-body">
          <span>{{ $t('order-item-check-delete-item') }}</span>
        </section>
        <footer class="modal-card-foot">
          <button
            class="button"
            type="button"
            @click="isDeleteModalActive = false"
          >
            {{ $t('returns-button-cancel') }}
          </button>
          <b-button type="is-danger" @click="removeItem" icon-left="delete">{{
            $t('button-remove')
          }}</b-button>
        </footer>
      </div>
    </b-modal>
  </validation-provider>
</template>
<script>
// import { mapState } from 'vuex
import { storeToRefs } from 'pinia'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import moment from 'moment'
import { useAppStore } from '@/stores/app'
import { useUtilitiesStore } from '@/stores/utilities'
import { usePageStore } from '@/stores/pages'

export default {
  props: ['model', 'schema'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  setup() {
    const appStore = useAppStore()
    const utilitiesStore = useUtilitiesStore()
    const pageStore = usePageStore()
    const { clientKey, dateConfig } = storeToRefs(appStore)
    const { container, measures, itemBreakdown } = storeToRefs(utilitiesStore)
    const { currentFormId } = storeToRefs(pageStore)

    return {
      clientKey,
      dateConfig,
      container,
      measures,
      itemBreakdown,
      currentFormId
    }
  },
  data() {
    return {
      isCardModalActive: false,
      isDeleteModalActive: false,
      allValues: [],
      allValuesType: [],
      containerActive: true,
      dataStore: {}
    }
  },
  // computed: {
  //   ...mapState([
  //     'containers',
  //     'measures',
  //     'itemBreakdown',
  //     'clientKey',
  //     'dateConfig'
  //   ])
  // },
  created() {
    if (['REGULAR_FORM', 'MATRIX'].includes(this.schema.form)) {
      this.$nextTick(() => {
        this.$refs[this.schema.id].validate().then(result => {
          if (!this.schema.schema.validatorCommon) return

          const rule = this.schema.schema.validatorCommon.rule
            .split('|')
            .filter(r => r.includes('fill_before'))[0]

          const fieldModel = rule ? rule.split('@').pop() : null

          if (!result.valid) {
            this.containerActive = false
            this.$parent.$parent.$parent.$parent.setErrors({
              [fieldModel]: [result.errors[0]]
            })

            if (this.$parent.model.Order && this.$parent.model.Order.items) {
              this.$parent.model.Order.items = []
            }

            this.$nextTick(() => {
              document.querySelector('.help.is-danger').scrollIntoView({
                behavior: 'smooth',
                block: 'center'
              })
            })
          }

          this.isCardModalActive = result.valid
        })
      })
    } else if (
      this.schema.form === 'EDIT_FORM' &&
      Object.keys(this.model).length === 0
    ) {
      this.isCardModalActive = true
    }
  },
  methods: {
    checkContainerValues() {
      if (
        this.model.OrderItemDetail &&
        (this.model.OrderItemDetail.hasOwnProperty('hierarchy_category_id') ||
          this.model.OrderItemDetail.hasOwnProperty('quantity') ||
          this.model.OrderItemDetail.hasOwnProperty('hierarchy_container_id'))
      ) {
        return true
      }

      if (
        this.model.hasOwnProperty('Period') &&
        (this.model.Period.hasOwnProperty('company_type_id') ||
          this.model.Period.hasOwnProperty('document_type_id') ||
          this.model.Period.hasOwnProperty('prefix'))
      ) {
        return true
      }

      return false
    },
    getModelSorted(model) {
      const modelSorted = {}
      let modelToBeSorted = {}

      if (typeof model.ContractMatrixResult !== 'undefined') {
        modelToBeSorted = { ...model.ContractMatrixResult }
      }

      if (typeof model.ContractMatrixDeclarationResult !== 'undefined') {
        modelToBeSorted = { ...model.ContractMatrixDeclarationResult }
      }
      if (
        typeof model.OrderItem !== 'undefined' &&
        typeof model.OrderItemDetail !== 'undefined'
      ) {
        modelToBeSorted = { ...model.OrderItem, ...model.OrderItemDetail }
      }
      if (this.schema.schema.fields && Object.keys(modelToBeSorted).length) {
        const { fields } = this.schema.schema
        fields.forEach(field => {
          const { name } = field
          const names = name.split('.')
          const answer = names[names.length - 1]
          modelSorted[field.name] = { field, value: modelToBeSorted[answer] }
        })
      }
      return modelSorted
    },
    removeItem() {
      this.$emit('removeItem')
      this.isDeleteModalActive = false
      this.isCardModalActive = false
    },
    confirm(e) {
      this.isCardModalActive = false
      this.dataStore = JSON.parse(JSON.stringify(this.schema.schema))
    },
    getLookupText(typeId, key, returnValue = false) {
      if (this.schema.schema.fields) {
        const { fields } = this.schema.schema
        let text = ''
        let label = ''
        fields.forEach(field => {
          if (field.hidden === true) {
            return
          }
          if (field.name === key) {
            label = field.flabel
            if (field.inputType === 'select') {
              returnValue = true
            }
            field.values.forEach(value => {
              this.allValues[value.value] = value.text
              this.allValuesType[value.value] = field.dataType
            })
          }
        })
        if (this.allValues && returnValue) {
          this.allValues.forEach((lookupText, value) => {
            if (this.allValuesType[value] === 'INTEGER') {
              typeId = parseInt(typeId)
            }
            if (value === typeId) {
              text = lookupText
            }
          })
        } else {
          text = typeId
          if (moment(typeId, 'YYYY-MM-DD', true).isValid()) {
            text = `${moment(typeId).format(this.dateConfig.dateFormat)}`
          }
        }

        if (
          key === 'OrderItemDetail.hierarchy_business_type' &&
          typeof this.model.OrderItemDetail.hierarchy_business_type !==
            'undefined' &&
          this.allValues[this.model.OrderItemDetail.hierarchy_business_type]
        ) {
          text = `${
            this.allValues[this.model.OrderItemDetail.hierarchy_business_type]
          }`
        }
        if (!text) {
          text = `${typeId}`
        }
        if (label && text) {
          return `<b>${label}</b>: ${text}`
        }
      }
    },
    getFieldValue(params) {
      const transform = {
        DATE: value => moment(value).format(this.dateConfig.dateFormat)
      }

      let values = params.field.values.find(
        v => String(v.value) === String(params.value)
      )

      if (!values && this.dataStore.fields)
        values = this.dataStore.fields
          .find(f => f.name === params.field.name)
          .values.find(v => String(v.value) === String(params.value))

      values = values || {}

      let result =
        params.field.values && params.field.values.length > 0
          ? values.text
          : params.value
      result = transform[params.field.dataType]
        ? transform[params.field.dataType](result)
        : result

      return result
    }
  }
}
</script>
<style></style>

<template>
  <b-collapse class="card items-accordion" :open="true">
    <div slot="trigger" class="card-header" slot-scope="props">
      <span class="card-header-title">
        <b-icon :icon="props.open ? 'chevron-down' : 'chevron-right'"></b-icon>
        {{ schema.id }}
      </span>
      <a class="card-header-icon" @click="removeItem">
        <b-icon
          icon="minus-circle-outline"
          type="is-danger"
          size="is-small"
        ></b-icon>
      </a>
    </div>
    <div class="card-content">
      <span class="content">
        <slot></slot>
      </span>
    </div>
  </b-collapse>
</template>
<script>
export default {
  props: ['model', 'schema'],
  methods: {
    removeItem() {
      this.$emit('removeItem')
    }
  }
}
</script>

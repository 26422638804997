<template>
  <b-field :label="schema.flabel" class="custom-field">
    <b-field>
      <p
        v-if="schema.showPreviousValue && schema.inputType !== 'hidden'"
        class="control previous-value-control"
      >
        <b-button :label="String(oldValue)" disabled expanded />
      </p>
      <b-timepicker
        expanded
        v-model="value"
        placeholder="00:00"
        icon="clock"
        :disabled="disabled"
        editable
      ></b-timepicker>
    </b-field>
  </b-field>
</template>
<script>
import { abstractField } from 'vue-form-generator'
import moment from 'moment'

export default {
  mixins: [abstractField],
  data() {
    return {
      oldValue: ''
    }
  },
  created() {
    if (this.value && !(this.value instanceof Date)) {
      const date = new Date()
      const split = this.value.split(':')
      const hours = split[0]
      const minutes = split[1]

      date.setHours(hours)
      date.setMinutes(minutes)

      this.value = date
    }
    this.oldValue = this.schema.showPreviousValue
      ? moment(this.value).format('HH:MM')
      : ''
  }
}
</script>

<template>
  <validation-provider
    :name="schema.flabel"
    :rules="schema.validatorCommon"
    :vid="schema.model"
    v-slot="{ errors }"
  >
    <b-field
      class="custom-field"
      :type="{ 'is-danger': errors[0] }"
      :message="errors[0]"
      :data-cy="
        schema.hasOwnProperty('properties') &&
        schema.properties.hasOwnProperty('data-cy')
          ? schema.properties['data-cy']
          : ''
      "
    >
      <template slot="label">
        <span>
          {{ labelHandler }}
        </span>
        <span class="has-text-danger" v-if="checkRequired">*</span>
      </template>
      <b-taginput
        v-model="value"
        :data="filteredTags"
        autocomplete
        :allow-new="false"
        :open-on-focus="true"
        :field="fieldHandlet"
        :disabled="disabled"
        :closable="!disabled"
        icon="label"
        :placeholder="schema.placeholder"
        :data-cy="
          schema.hasOwnProperty('properties') &&
          schema.properties.hasOwnProperty('data-cy')
            ? schema.properties['data-cy']
            : ''
        "
        @typing="getFilteredTags"
      >
      </b-taginput>
    </b-field>
  </validation-provider>
</template>

<script>
import { abstractField } from 'vue-form-generator'
import { ValidationProvider } from 'vee-validate'

export default {
  mixins: [abstractField],
  components: {
    ValidationProvider
  },
  data() {
    return {
      filteredTags: this.schema.values
    }
  },
  created() {
    if (this.value) {
      this.value = this.value.map(value => {
        let textValue = value[this.fieldHandlet]
        if (textValue === undefined || this.fieldHandlet === 'value') {
          const fallbackTextValue = this.schema.values.find(
            v => v.id === value.id
          )

          textValue = fallbackTextValue[this.fieldHandlet]
        }
        return { id: value.id, [this.fieldHandlet]: textValue }
      })
    }
  },
  computed: {
    labelHandler() {
      return this.schema.showLabel ? this.schema.flabel : ''
    },
    fieldHandlet() {
      if (this.schema.values && this.schema.values.length > 0) {
        if (this.schema.values[0].hasOwnProperty(this.schema.lookup_text)) {
          return this.schema.lookup_text
        }
      }
      return 'text'
    },
    checkRequired() {
      const validator = this.schema.validatorCommon
      if (validator) {
        const validatorSplit = validator.split('|')
        if (validatorSplit.includes('required')) {
          // contains required
          return true
        }
      }
      return false
    },
    selectedValues() {
      return (this.value || []).map(
        value => value.id || value.value || value[this.schema.lookup_value]
      )
    }
  },
  methods: {
    getFilteredTags(text) {
      this.filteredTags = this.schema.values.filter(option => {
        const tmpValue = option.value ?? option[this.schema.lookup_value]

        if (option.value == null) {
          option.value = tmpValue
        }
        return (
          option[this.fieldHandlet]
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0 &&
          !this.selectedValues.includes(tmpValue)
        )
      })
    }
  },
  watch: {
    value() {
      this.filteredTags = this.schema.values.filter(
        tag =>
          !this.selectedValues.includes(tag.id || tag[this.schema.lookup_value])
      )
    }
  }
}
</script>

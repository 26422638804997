import numbers from '@/services/helpers/numbers'

export default {
  init(view) {
    view.params[this.name] = view.$route.params
  },

  handles(view) {
    return {
      onCreate: _this => {
        view.api({
          module: this,
          method: 'post',
          endPoint: view.modules[this.name].uses.flow || '/authenticated-forms',
          body: {
            ...view.params[this.name],
            form: view.modules[this.name].uses.form
          },
          callback: res => {
            res = res.actions
              ? res.actions[Object.keys(res.actions)[0]].route_response.content
              : res

            view.params[this.name].reference_hash = res.reference_hash

            _this.form.name = res.form.form_name

            _this.form.buttons = res.form.buttons

            _this.formBuilder({
              ...res,
              formDependency: view.modules[this.name].uses.form
            })
          }
        })
      },

      onSubmit: (_this, button) => {
        _this.flagDisable = true
        view.params[this.name].transition = button.value

        _this.formValidation({
          success: result => {
            view.api({
              method: 'post',
              module: this,
              endPoint: view.modules[this.name].uses.submitFlow,
              body: {
                ...result.payload,
                ...view.params[this.name],
                form: view.modules[this.name].uses.form
              },
              callback: async res => {
                res = res.actions
                  ? res.actions[Object.keys(res.actions)[0]].route_response
                      .content
                  : res

                view.toast({
                  type: 'is-success',
                  message: res.message
                })
                _this.flagDisable = false
                view.$router.push('/declarations')
              }
            })
          }
        })
      },

      getAllLevels: levels => {
        const reduceLevels = arr =>
          arr.reduce((list, { children, ...level }) => {
            list.push({ children, ...level })
            if (children) list.push(...reduceLevels(children))
            return list
          }, [])

        return reduceLevels(levels)
      },

      getInputFields: fields => {
        const inputFields = fields.reduce((acc, field) => {
          if (field.field_type === 'reason_group') return acc

          const childs = Object.values(field.childs.fields).filter(
            field =>
              field.field_type !== 'hidden' &&
              !field.field_name.includes('reason')
          )

          return acc.concat(childs.length > 0 ? childs : [])
        }, [])

        return inputFields
      },

      levelHasChildren: level => level.children.length > 0,

      getFields(level) {
        const groupField = level.fields.find(
          field => field.field_type === 'group'
        )
        const mainFields = groupField.childs.fields

        const reasonGroupField = level.fields.find(
          field => field.field_type === 'reason_group'
        )

        if (reasonGroupField) return [...mainFields, reasonGroupField]

        return mainFields
      },

      isLastLevel: (index, levelsLength) => index === levelsLength - 1,

      calculateTotals: (levels, mask, fieldIndex, getValue) => {
        const totals = []

        levels.forEach(level => {
          const field = this.handles(view).getInputFields(level.fields)[
            fieldIndex
          ]

          const unmaskedValue = numbers.unmask(
            field.mask,
            getValue(field) || '0'
          )

          totals.push(Number(unmaskedValue))
        })

        let total = totals.reduce((partialSum, a) => partialSum + a, 0)

        total = numbers.mask(!isNaN(total) ? total : 0, mask)

        return total
      },

      openDeviationReasonModal: (parent, { level, formType }) => {
        const model = parent.form.model
        const reasonModel = {}
        const reasonGroupField = level.fields.find(
          field => field.field_type === 'reason_group'
        )
        const reasonFields = reasonGroupField.childs.fields

        Object.keys(model).forEach(key => {
          if (key.startsWith(reasonGroupField.hierarchyHash)) {
            reasonModel[key] = model[key]
          }
        })

        const isViewForm = formType === 'OVERVIEW_FORM'

        view.modal({
          component: 'DynamicForm',
          parent,
          canCancel: false,
          props: {
            model: reasonModel,
            params: {
              ...view.params[this.name],
              forms: [parent.form.slug],
              title: level.description
            },
            hasCard: 'true',
            handles: {
              ...this.handles(view),
              onCreate: _this => {
                _this.formBuilder({
                  dataset: {},
                  // formDependency: forms[0],
                  formDependency: parent.form.slug,
                  fields: reasonFields,
                  form: { form_type: 'REGULAR_FORM' }
                })
              }
            },
            elements: [
              {
                component: 'b-button',
                label: view.$t(
                  isViewForm === false ? 'button-update' : 'button-close'
                ),
                class: 'is-primary',
                loading: false,
                onClick: _this =>
                  _this.formValidation({
                    success: ({ payload }) => {
                      parent.form.model = {
                        ...parent.form.model,
                        ..._this.$recurse(payload)
                      }

                      reasonFields.forEach(reasonField => {
                        delete parent.form.errors[reasonField.field_name]
                      })

                      _this.$emit('close')
                    }
                  })
              },
              {
                component: 'b-button',
                label: view.$t('button-cancel'),
                class: 'is-secondary',
                loading: false,
                onClick: _this => _this.$emit('close')
              }
            ]
          }
        })
      },

      isReasonModelConfirmed: (fields, getValue) => {
        const isConfirmed = fields
          .filter(
            field =>
              field.field_type !== 'hidden' && Boolean(field.visible) === true
          )
          .every(field => getValue(field) !== undefined)

        return isConfirmed
      },

      hasReasonModelValidationError: (fields, errors) => {
        return fields.some(field => errors[field.field_name])
      }
    }
  },

  elements(view) {
    return []
  }
}

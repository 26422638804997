<template>
  <div class="field text-left">
    <validation-provider
      v-if="schema.inputType === 'checkbox'"
      :name="schema.flabel"
      :rules="checkboxValidatorHandler(schema.validatorCommon)"
      v-slot="{ errors }"
      :vid="schema.model"
    >
      <b-field>
        <b-field>
          <p
            v-if="schema.showPreviousValue"
            class="control previous-value-control"
          >
            <b-icon v-if="oldValue" icon="checkbox-marked" size="is-medium" />
            <b-icon v-else icon="checkbox-blank-outline" size="is-medium" />
          </p>
          <b-checkbox
            :data-cy="
              schema.hasOwnProperty('properties') &&
              schema.properties.hasOwnProperty('data-cy')
                ? schema.properties['data-cy']
                : ''
            "
            v-model="value"
            :disabled="disabled"
            >{{ labelHandler }}
            <span class="has-text-danger" v-if="checkRequired">*</span>
          </b-checkbox>
        </b-field>
      </b-field>
      <p :class="{ 'help is-danger custom-validation-message': errors[0] }">
        {{ errors[0] }}
      </p>
    </validation-provider>
    <div v-else>
      <validation-provider
        :name="schema.flabel"
        :rules="schema.validatorCommon"
        :vid="schema.model"
        v-slot="{ errors }"
      >
        <div v-for="item in schema.values" v-bind:key="item.id">
          <b-checkbox
            v-model="value"
            :native-value="item.value"
            :disabled="disabled"
            :type="{ 'is-danger': errors[0] }"
            ><label class="label">{{ item.text }}</label></b-checkbox
          >
        </div>
        <p :class="{ 'help is-danger is-danger': errors[0] }">
          {{ errors[0] }}
        </p>
      </validation-provider>
    </div>
  </div>
</template>
<script>
import { abstractField } from 'vue-form-generator'
import { ValidationProvider } from 'vee-validate'

export default {
  mixins: [abstractField],
  components: {
    ValidationProvider
  },
  data() {
    return {
      oldValue: ''
    }
  },
  created() {
    this.oldValue = this.schema.showPreviousValue ? this.value : ''
  },
  methods: {
    checkboxValidatorHandler(validator) {
      const validatorObject = {}
      if (validator && validator.includes('required')) {
        validator = validator.split('|')
        validator.forEach(validation => {
          if (validation !== 'required') {
            const validationName = validation.split(':')[0]
            validatorObject[validationName] = validation.toString()
          } else {
            validatorObject.required = { allowFalse: false }
          }
        })
        validator = validatorObject
      }
      return validator
    }
  },
  computed: {
    labelHandler() {
      return this.schema.showLabel ? this.schema.flabel : ''
    },
    checkRequired() {
      const validator = this.schema.validatorCommon
      if (validator) {
        const validatorSplit = validator.split('|')
        if (validatorSplit.includes('required')) {
          // contains required
          return true
        }
      }
      return false
    }
  }
}
</script>
